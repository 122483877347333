import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav, Col, Row } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';
import { orderSequenceChoices, screenTypeChoices } from '../selectfieldchoices/kitchenmanagerselect.mjs';
import KmNextDeviceSortCategoryEditTable from './kmNextDeviceSortCategoryEditTable';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTab, setActiveTab } from '../redux/dataSlices/formTabSlice';


export default function KmScreensForm(props) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);

  if (props.data.hasOwnProperty('location_id')) {
    if (props.location_id.hash) {
      props.location_id.selected = { 'value': props.data.location_id, 'label': props.location_id.hash[props.data.location_id]?.name }
    }
  }

  if (props.data.hasOwnProperty('admin_id')) {
    if (props.admin_id.hash) {
      props.admin_id.selected = { 'value': props.data.admin_id, 'label': props.admin_id.hash[props.data.admin_id]?.name }
    }
  }

  if (props.data.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type, 'label': screenTypeChoices.find(type => type.id === props?.data?.type)?.name }
  }

  if (props.data.settings.hasOwnProperty('next_device_ready')) {
    if (props.data.settings.next_device_ready) props.next_device_ready.selected = { 'value': props?.data?.settings?.next_device_ready, 'label': Object.values(props.hash_devices).find(device => device.id === props?.data?.settings?.next_device_ready)?.name };
    if (!props.data.settings.next_device_ready) props.next_device_ready.selected = null;
  }

  if (props.data.settings?.order_sequence?.hasOwnProperty('new_order')) {
    props.new_order.selected = { 'value': props.data.settings.order_sequence.new_order, 'label': orderSequenceChoices.find(sequence => sequence.id === props.data.settings.order_sequence.new_order)?.name }
  }

  if (props.data.settings?.order_sequence?.hasOwnProperty('next_course')) {
    props.next_course.selected = { 'value': props.data.settings.order_sequence.next_course, 'label': orderSequenceChoices.find(sequence => sequence.id === props.data.settings.order_sequence.next_course)?.name }
  }

  if (props.data.settings?.order_sequence?.hasOwnProperty('vip')) {
    props.vip.selected = { 'value': props.data.settings.order_sequence.vip, 'label': orderSequenceChoices.find(sequence => sequence.id === props.data.settings.order_sequence.vip)?.name }
  }

  if (props.data.settings?.order_sequence?.hasOwnProperty('pause')) {
    props.pause.selected = { 'value': props.data.settings.order_sequence.pause, 'label': orderSequenceChoices.find(sequence => sequence.id === props.data.settings.order_sequence.pause)?.name }
  }

  if (props.data.settings?.order_sequence?.hasOwnProperty('on_hold')) {
    props.on_hold.selected = { 'value': props.data.settings.order_sequence.on_hold, 'label': orderSequenceChoices.find(sequence => sequence.id === props.data.settings.order_sequence.on_hold)?.name }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id} onClick={() => dispatch(setActiveTab(field.id))}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        if (embeddedfield.type === 'object') {
          objectFields.push(
            <>
              <Row>
                <Col className='mt-5 mb-3'>
                  <h4>{translate(embeddedfield.name)}</h4>
                </Col>
              </Row>
            </>
          )
          embeddedfield.fields.forEach(subField => {
            subField.invalid = props.invalidFields.find(invalidField => invalidField.field === subField?.id) ? true : false;
            objectFields.push(CreateField(subField, props, embeddedfield.id))
          })
        } else {
          embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
          objectFields.push(CreateField(embeddedfield, props, field.id))
        }
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });

  // phonenumbers
  formTabs.push(
    <Nav.Item key='nextdevicesortgroups' onClick={() => dispatch(setActiveTab('nextdevicesortgroups'))}>
      <Nav.Link eventKey='nextdevicesortgroups'>
        {translate('Next device (sort sroups)')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='nextdevicesortgroups' key='nextdevicesortgroups'>
      <div className='tab-container'>
        <KmNextDeviceSortCategoryEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main" activeKey={activeTab}>
          <Nav variant="tabs">
            <Nav.Item onClick={() => dispatch(setActiveTab("main"))}>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}