import Button from 'react-bootstrap/Button';
import { Tab, Nav, Form } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';

import './css/productEdit.css'
import NutrientsEditTable from './nutrientsEditTable';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTab, setActiveTab } from '../redux/dataSlices/formTabSlice';
import SuppliersEditTable from './supplierEditTable';
import ProductSupplierModalForm from './productSupplierModalForm';
import AllergenicsEditTable from './allergenicsEditTable';
import IngredientsEditTable from './ingredientsEditTable';


function ProductForm(props) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);

  if (props?.data?.hasOwnProperty('categories')) {
    // eslint-disable-next-line
    props.data.categories.map((category) => {
      if (category.type === 'JOURNAL') {
        props.journalcategories.selected = { 'value': category.id, 'label': category.name ? category.name : category.title }
      }
      if (category.type === 'PRODUCTPAGE') {
        props.productcategories.selected.add({ 'value': category.id, 'label': category.name ? category.name : category.title })
      }
    })
  }

  if (props?.data?.hasOwnProperty('vat')) {
    props.vats.selected = { 'value': props.data.vat.id, 'label': props.data.vat.name ? props.data.vat.name : props.data.vat.title }
  }

  if (props?.data?.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type, 'label': props.data.type ? props.data.type : props.data.type }
  }

  if (props?.data?.hasOwnProperty('eans')) {
    props.data.eans.forEach(ean => {
      props.eans.selected.push({ 'value': ean.ean_code, 'label': ean.ean_code })
      props.eans.options.push({ 'id': ean.ean_code, 'name': ean.ean_code })
    });
  }

  if (props?.data?.hasOwnProperty('images')) {
    props.data.images.forEach(image => {
      props.images.selected.push({ 'value': image, 'label': image })
      props.images.options.push({ 'id': image, 'name': image })
    });
  }

  if (props?.data?.hasOwnProperty('attributes')) {
    props.data.attributes.forEach(attributegroup => {
      props.attributes.selected.push({ 'value': attributegroup.id, 'label': attributegroup.name })
    });
  }

  if (props?.hasOwnProperty('esl_template_id') && props?.hasOwnProperty('templates')) {
    props?.templates?.forEach(template => {
      if (props?.data?.esl?.esl_template_id === template.id) {
        props.esl_template_id.selected = { 'value': template.id, 'label': template.name }
      }
      if (template.esl_model !== 'other') {
        props.esl_template_id.options.push({ 'id': template.id, 'name': template.name })
      }
    });
  }

  if (props.hasOwnProperty('print_template_id') && props.hasOwnProperty('templates')) {
    props?.templates?.forEach(template => {
      if (props?.data?.esl?.print_template_id === template.id) {
        props.print_template_id.selected = { 'value': template.id, 'label': template.name }
      }
      props.print_template_id.options.push({ 'id': template.id, 'name': template.name })
    });
  }

  if (props.hasOwnProperty('code_type') && props?.data?.esl?.hasOwnProperty('code_type')) {
    props.code_type.selected = { 'value': props?.data?.esl?.code_type, 'label': props?.data?.esl?.code_type }
  }

  if (props.hasOwnProperty('printer') && props?.data?.kitchenmanager?.hasOwnProperty('printer')) {
    if (props?.data?.kitchenmanager?.printer) props.printer.selected = { 'value': props?.data?.kitchenmanager?.printer, 'label': props?.hash_printers[props?.data?.kitchenmanager?.printer]?.name }
    if (!props?.data?.kitchenmanager?.printer) props.printer.selected = null
  }

  if (props.hasOwnProperty('category') && props?.data?.kitchenmanager?.hasOwnProperty('category')) {
    if (props?.data?.kitchenmanager?.category) props.category.selected = { 'value': props?.data?.kitchenmanager?.category, 'label': props?.hash_sort_categories[props?.data?.kitchenmanager?.category]?.name }
    if (!props?.data?.kitchenmanager?.category) props.category.selected = null
  }

  if (props.hasOwnProperty('screens') && props?.data?.kitchenmanager?.hasOwnProperty('screens')) {
    props?.data?.kitchenmanager?.screens?.forEach(screen => {
      if (screen instanceof Object) {
        props.screens.selected.push({ 'value': screen?.id, 'label': props.hash_screens[screen?.id]?.name })
      } else {
        props.screens.selected.push({ 'value': screen, 'label': props.hash_screens[screen]?.name })
      }
    });
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id} onClick={() => dispatch(setActiveTab(field.id))}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });

  // allergenics
  formTabs.push(
    <Nav.Item key='allergenics' onClick={() => dispatch(setActiveTab('allergenics'))}>
      <Nav.Link eventKey='allergenics'>
        {translate('allergenics')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='allergenics' key='allergenics'>
      <div className='tab-container'>
        <AllergenicsEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  // ingredients
  formTabs.push(
    <Nav.Item key='ingredients' onClick={() => dispatch(setActiveTab('ingredients'))}>
      <Nav.Link eventKey='ingredients'>
        {translate('ingredients')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='ingredients' key='ingredients'>
      <div className='tab-container'>
        <IngredientsEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  // nutrients
  formTabs.push(
    <Nav.Item key='nutrients' onClick={() => dispatch(setActiveTab('nutrients'))}>
      <Nav.Link eventKey='nutrients'>
        {translate('nutrients')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='nutrients' key='nutrients'>
      <div className='tab-container'>
        <NutrientsEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  // product supplier
  formTabs.push(
    <Nav.Item key='supplier' onClick={() => dispatch(setActiveTab('supplier'))}>
      <Nav.Link eventKey='supplier'>
        {translate('supplier')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='supplier' key='supplier'>
      <div className='tab-container'>
        <SuppliersEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main" activeKey={activeTab}>
          <Nav variant="tabs">
            <Nav.Item onClick={() => dispatch(setActiveTab("main"))}>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick} disabled={props?.submit?.disabled}>{translate(props.submit.title)}</Button>
      <ProductSupplierModalForm {...props?.suppliers?.modal} />
    </>
  );
}

export default ProductForm;