import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTab, setActiveTab } from '../redux/dataSlices/formTabSlice';


function ProductCategoryForm(props) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  
  if (props.hasOwnProperty('parent')) {
    if (props.data?.hasOwnProperty('parent')) props.parent.selected = { 'value': props.data.parent.id, 'label': props.data.parent.title }
    if (!props.data?.hasOwnProperty('parent')) props.parent.selected = null;
  }

  if (props.hasOwnProperty('printer') && props?.data?.kitchenmanager?.hasOwnProperty('printer')) {
    if (props?.data?.kitchenmanager?.printer) props.printer.selected = { 'value': props?.data?.kitchenmanager?.printer, 'label': props?.hash_printers[props?.data?.kitchenmanager?.printer]?.name }
    if (!props?.data?.kitchenmanager?.printer) props.printer.selected = null
  }

  if (props.hasOwnProperty('category') && props?.data?.kitchenmanager?.hasOwnProperty('category')) {
    if (props?.data?.kitchenmanager?.category) props.category.selected = { 'value': props?.data?.kitchenmanager?.category, 'label': props?.hash_sort_categories[props?.data?.kitchenmanager?.category]?.name }
    if (!props?.data?.kitchenmanager?.category) props.category.selected = null
  }

  if (props.hasOwnProperty('screens') && props?.data?.kitchenmanager?.hasOwnProperty('screens')) {
    props?.data?.kitchenmanager?.screens?.forEach(screen => {
      if (screen instanceof Object) {
        props.screens.selected.push({ 'value': screen?.id, 'label': props.hash_screens[screen?.id]?.name })
      } else {
        props.screens.selected.push({ 'value': screen, 'label': props.hash_screens[screen]?.name })
      }
    });
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id} onClick={() => dispatch(setActiveTab(field.id))}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, translate(field.id)))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props, field?.prefix))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main" activeKey={activeTab}>
          <Nav variant="tabs">
            <Nav.Item onClick={() => dispatch(setActiveTab("main"))}>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick} disabled={props?.submit?.disabled}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default ProductCategoryForm;