import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

const DeviceAddFormFields = () => {
  const role = useSelector(getRole)

  let deviceAddFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'uid', id: 'uid', readonly: true },

    // single option select fields  
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'hidden', name: 'Administration', id: 'administration_id', readonly: true },
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: 'Actablue Device ID', id: 'serial_number' },
    { type: 'email', name: 'Email', id: 'email' },
    { type: 'checkbox', name: 'Active', id: 'active' }
  ]

  if (role === 'super' || role === 'admin') {
    deviceAddFormFields.splice(3, 1, // overwrite index 3 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
  }

  return deviceAddFormFields
}

export default DeviceAddFormFields