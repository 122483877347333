import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import NarrowcastingForm from "../../components/narrowcastingPlaylistForm";
import { NarrowcastingPlaylistFormFields } from "./narrowcastingPlaylistFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostPlaylist from "../../actablueAPI/narrowcasting/PostPlaylist";
import GetAllViews from "../../actablueAPI/narrowcasting/GetAllViews";
import { setViewsAll, getViewsAll, getHashViewsAll } from "../../redux/dataSlices/narrowcastingSlice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnDragEndMultiSelectFieldWithObjects from "../../helpers/handleOnDragEndMultiSelectFieldWithObjects";
import { layoutChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import useHandleError from "../../customhooks/useHandleError";
import MultiValueContainer from "../../components/narrowcastingPlaylistMultiContainer";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from '../../redux/dataSlices/loadingSlice';


const NarrowcastingPlaylistAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const views_all = useSelector(getViewsAll)
  const hash_views_all = useSelector(getHashViewsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "Playlist add"
  const [playlist, setPlaylist] = useState({ admin_id: admin_id, location_id: location_id })
  const [invalidFields, setInvalidFields] = useState([])

  const onChange = (event) => {
    if (event.target.id === 'name') {
      let tempInvalid = invalidFields
      if (tempInvalid.find(invalid => invalid.field === 'name')) {
        tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === 'name'), 1)
      }
    }
    let newPlaylist = HandleOnChange({ event: event, object: playlist })
    setPlaylist(newPlaylist)
  }

  const onHandleOnChangeMultiSelectFieldWithObjects = (event, viewScreen) => {
    let newPlaylist = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: playlist, selectfield: `views${viewScreen}`, hash: hash_views_all })
    newPlaylist[`views${viewScreen}`].forEach((view, index, array) => {
      if (view.position !== viewScreen-1) {
        view = {...view, position: viewScreen-1}
        array[index] = view
      }
    });
    setPlaylist(newPlaylist)
  }

  const onDragEndMultiSelectFieldWithObjects = (event, viewScreen) => {
    let newPlaylist = HandleOnDragEndMultiSelectFieldWithObjects({ event: event, object: playlist, selectfield: `views${viewScreen}`, hash: hash_views_all })
    setPlaylist(newPlaylist)
  }

  const durationUpdate = ({duration, viewId, viewsList}) => {
    let newPlaylist = {...playlist}
    if (viewId && viewsList) {
      newPlaylist[viewsList].forEach((view, index, array) => {
        if(view.id === viewId) {
          let newDuration = parseInt(duration)
          if (isNaN(newDuration)) {
            view = {...view, seconds: 0}
          } else {
            view = {...view, seconds: newDuration}
          }
          array[index] = view
        }
      })
      setPlaylist(newPlaylist)
    }
  }

  const onLayoutChange = (event) => {
    let newPlaylist = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: playlist, selectfield: 'layout' })
    let tempInvalid = invalidFields
    if (tempInvalid.find(invalid => invalid.field === 'layout')) {
      tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === 'layout'), 1)
    }
    setPlaylist(newPlaylist)
  } 

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
      const [invalid] = validateFormObject({ object: playlist, fields: formData?.fields, switchTab: true, dispatch: dispatch })
      if(invalid?.length > 0) {
        setInvalidFields(invalid)
        return;
      }
    let newPlaylist = {...playlist, views: []}
    if ((playlist.views4) && (playlist.layout === 'cross-split-screen')) {
      newPlaylist.views = newPlaylist.views.concat(playlist.views4)
    }
    if ((playlist.views3) && (playlist.layout !== 'fullscreen' && playlist.layout !== 'vertical-split-screen' && playlist.layout !== 'horizontal-split-screen')) {
      newPlaylist.views = newPlaylist.views.concat(playlist.views3)
    }
    if ((playlist.views2) && (playlist.layout !== 'fullscreen')) {
      newPlaylist.views = newPlaylist.views.concat(playlist.views2)
    }
    if (playlist.views1) {
      newPlaylist.views = newPlaylist.views.concat(playlist.views1)
      delete newPlaylist.views1
      delete newPlaylist.views2
      delete newPlaylist.views3
      delete newPlaylist.views4
    }

    dispatch(setLoading(true))

    PostPlaylist({ token: token, admin_id: admin_id, playlist: newPlaylist })
      .then(() => {
        navigate("/narrowcastingplaylists");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  
  };

  const formData = {
    invalidFields: invalidFields,
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingPlaylistFormFields(),
    field: {
      onChange: onChange
    },
    data: playlist,
    views1: {
      label: 'views1',
      options: views_all,
      selectedViews: playlist.views1,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,1),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,1),
      clearable: Boolean(true),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    views2: {
      label: 'views2',
      options: views_all,
      selectedViews: playlist.views2,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,2),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,2),
      clearable: Boolean(true),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    views3: {
      label: 'views3',
      options: views_all,
      selectedViews: playlist.views3,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,3),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,3),
      clearable: Boolean(true),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    views4: {
      label: 'views4',
      options: views_all,
      selectedViews: playlist.views4,
      onChange: (event) => onHandleOnChangeMultiSelectFieldWithObjects(event,4),
      onDragEnd: (event) => onDragEndMultiSelectFieldWithObjects(event,4),
      clearable: Boolean(true),
      searchable: Boolean(true),
      MultiContainer: MultiValueContainer,
      durationUpdate: durationUpdate
    },
    layout: {
      label: 'layout',
      options: layoutChoices,
      onChange: onLayoutChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    GetAllViews({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setViewsAll(response))
      })
      .catch(error => {
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingPlaylistAdd;

