import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';
import SingleSelectCreateField from './singleselectcreateField';

export default function KmNextDeviceSortCategoryEditTable(props) {
  const { t: translate } = useTranslation();

  let rows = []
  let selected = props?.next_device_sort_groups?.selected || []
  if (props?.data?.settings?.hasOwnProperty(props?.next_device_sort_groups?.add?.name)) {
    props?.data?.settings[props?.next_device_sort_groups?.add?.name]?.forEach(element => {
      rows.push(
        <tr key={element.id}>
          <td><Form.Check onChange={props?.next_device_sort_groups?.check?.onChange} id={element.id} elementid={element.id} /></td>
          <td>
            <SingleSelectCreateField name='device_id'
              options={props?.next_device_sort_groups?.device_id?.options}
              onChange={props?.next_device_sort_groups?.device_id?.onChange}
              clearable={false}
              searcheable={true}
              selected={{ 'value': element.device_id, 'label': Object.values(props?.hash_devices)?.find(object => object.id === element.device_id)?.name }}
              required={true}
              id='device_id'
              elementid={element.id}
              prefix='next_device_sort_groups'
            />
          </td>
          <td>
            <SingleSelectCreateField name='sort_category'
              options={props?.next_device_sort_groups?.sort_category?.options}
              onChange={props?.next_device_sort_groups?.sort_category?.onChange}
              clearable={false}
              searcheable={true}
              selected={{ 'value': element.sort_category, 'label': Object.values(props?.hash_sort_categories)?.find(object => object.id === element.sort_category)?.name }}
              required={true}
              id='sort_category'
              elementid={element.id}
              prefix='next_device_sort_groups'
            />
          </td>
        </tr>
      )
    });
  }

  return (
    <Table hover width='100%'>
      <thead>
        <tr>
          <th></th>
          <th width='50%'>
            {translate('Device')}
          </th>
          <th width='50%'>
            {translate('Sort category')}
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
      <Button variant="primary" className='mt-3' type={props?.next_device_sort_groups?.add?.type} onClick={props?.next_device_sort_groups?.add?.onClick} name={props?.next_device_sort_groups?.add?.name}>
        {translate(props.next_device_sort_groups.add.title)}
      </Button>
      <Button variant="primary" className='mt-3' type={props?.next_device_sort_groups?.remove?.type} onClick={props?.next_device_sort_groups?.remove?.onClick} name={props?.next_device_sort_groups?.remove?.name} disabled={selected.length === 0 ? true : false}>
        {translate(props?.next_device_sort_groups?.remove?.title)}
      </Button>
    </Table>
  );
}