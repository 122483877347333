import TableButton from './tableButton';
import InvoiceButtons from "../portal/invoices/invoicebuttons";
import InvoiceModal from "../components/invoiceModal";
import { useTranslation } from 'react-i18next';
import { Button, Table, Form, Navbar, Dropdown } from 'react-bootstrap';


export default function InvoiceTable(props) {
  const invoices = props.invoices
  const hashEmployees = props.employees
  const buttons = InvoiceButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const exportButton = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const selected = buttons?.select?.selected
  const { t: translate } = useTranslation();

  let sorted = []

  // convert object to array for sorting
  if (invoices) {
    sorted = Object.values(invoices).sort((a, b) => b?.number - a?.number)
  }

  let rows = []
  sorted.forEach(invoice => {
    const status = invoice.status;
    const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: invoice.id }) : false
    const accept = buttons.hasOwnProperty('accept') ? Object.assign({}, buttons.accept, { id: invoice.id }) : false
    const cancel = buttons.hasOwnProperty('cancel') ? Object.assign({}, buttons.cancel, { id: invoice.id }) : false
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: invoice.id }) : false
    const exportPdf = buttons?.modalview?.modal?.hasOwnProperty('exportPdf') ? Object.assign({}, buttons?.modalview?.modal?.exportPdf, { id: invoice.id }) : false

    rows.push(
      <tr key={invoice.id} className={selected?.hasOwnProperty(invoice.id) ? 'product-row selected' : 'product-row'}>
        <td>
          <Form>
            <Form.Check onChange={() => { buttons.select.onClick(invoice) }} checked={selected?.hasOwnProperty(invoice.id)} />
          </Form>
        </td>
        <td>{invoice.number}</td>
        <td><p>{invoice.date ? new Date(invoice.date).toLocaleString() : new Date(invoice.modification).toLocaleString()}</p></td>
          <td>{invoice.operator_id ? hashEmployees[invoice.operator_id]?.name : ''}</td>
          <td align="right">{Number(invoice.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(invoice) }} id={invoice.id}>{translate(modalview.tag)}</Button>
          {exportPdf && <Button size="sm" type='button' onClick={() => { exportPdf.onClick(invoice) }} id={invoice.id}>{translate(exportPdf.tag)}</Button>}
          {accept && status !== 'ACCEPTED' && <TableButton {...accept} />}
          {cancel && status !== 'CANCELED' && <TableButton {...cancel} />}
          {edit && <TableButton {...edit} />}
        </td>
      </tr>
    )
  });

  return (
    <>
      {Object.keys(selected).length > 0 &&
        <Navbar expand="xxl" className="bg-body-tertiary">
          {Object.keys(selected).length} {translate('invoice(s) selected.')}
          <Dropdown className='actions-button'>
            <Dropdown.Toggle size='sm' type='button'>
              {translate('Actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {exportButton && <Dropdown.Item onClick={() => { exportButton.onClick() }}>{exportButton.tag}</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      }
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {invoices && invoices?.length > 0 &&
                <Form>
                  <Form.Check onChange={() => { buttons.selectAll.onClick(invoices) }} checked={invoices?.every((invoice) => selected.hasOwnProperty(invoice?.id))} />
                </Form>
              }
            </th>
            <th width='30%'>
              {translate('Invoice number')}
            </th>
            <th>
              {translate('Date')}
            </th>
            <th>
              {translate('Employee')}
            </th>
            <th className="text-end">
              {translate('Amount')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <InvoiceModal modal={modalview.modal} />
      </Table>
    </>
  );
}