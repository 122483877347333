import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button, Col } from 'react-bootstrap';

import { getDevice } from '../redux/dataSlices/narrowcastingSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { screenRotationChoices } from '../selectfieldchoices/narrowcastingselect.mjs';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';


export default function DeviceModal(props) {
  const object = useSelector(getDevice);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    const device_url = `${process.env.REACT_APP_NARROWCASTING_BASE_URL}/view?deviceId=${object.id}&adminId=${object.admin_id}&locationId=${object.location_id}`
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Device: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="preview">{translate('Preview')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>{translate('Screen Rotation')}: {translate(screenRotationChoices.find(element => element.id === object.screen_rotation)?.name)}</p>
                      <p>{translate('Device URL')}:
                        <CopyToClipboard text={device_url} className='modal-copy-clipboard'>
                          <button onClick={handleCopy}>{copied ? translate('Copied!') : translate('Copy URL')}</button>
                        </CopyToClipboard>
                      </p>
                    </div>
                    <div className="col">
                      <p>{translate('Creation')}: {new Date(object.creation).toLocaleString()}</p>
                      <p>{translate('Modification')}: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="preview">
                <div className="container text-center">
                  <div className="row">
                    <p className='form-required-text-hint mt-1 mb-1'>{translate("* Preview may vary from actual result")}</p>
                    <Col sm='12' className='narrowcasting-preview-parent'>
                      <iframe src={device_url} title="Narrowcasting preview" width={'100%'} height={'100%'} allowfullscreen frameborder="0"></iframe>
                    </Col>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}