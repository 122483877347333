import { Button, Table, Form, Navbar, Dropdown } from 'react-bootstrap'
import TableButton from './tableButton';
import CategoryButtons from "../portal/productcategories/productcategorybuttons";
import CategoryModal from "./categoryModal";
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';

import './css/productcategoryTable.css';
import CreateCategoryTree from '../helpers/createCategoryTree';
import { getSelectedProductCategories } from '../redux/dataSlices/categorySlice';
import { useSelector } from 'react-redux';
import CategoryMultiEditModal from './productCategoryMultiEditModal';
import PaginationAndSizeComponent from './paginationAndSize';

export default function CategoryTable(props) {
  const refresh = props.refresh
  const buttons = CategoryButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const multiEdit = buttons.hasOwnProperty('multiEdit') ? Object.assign({}, buttons.multiEdit) : false
  const toggleIsVisible = buttons.hasOwnProperty('toggleIsVisible') ? Object.assign({}, buttons.toggleIsVisible) : false
  const selectedCategories = useSelector(getSelectedProductCategories)
  const { t: translate } = useTranslation();

  let categoryTree = CreateCategoryTree(props.categories)

  let pagination
  let pagesize
  let pages
  let page

  if (props.paginated) {
    console.log(props.categories)
    pagination = buttons.pagination
    pagesize = buttons.pagesize
    pages = props.categories?.total_pages
    page = (props.categories?.number + 1)
    categoryTree = CreateCategoryTree(props.categories?.content)
  }

  

  let categorieRows = []

  if (categoryTree) {
    categorieRows = TopLevel(categoryTree, buttons)
  }

  function TopLevel(categoryTree, buttons) {
    let categorieRows = []

    categoryTree.sort((a, b) => a.sort_index - b.sort_index).forEach((category, index) => {
      // find this levels childs
      let childRows = []
      if (category.children) {
        childRows.push(GetChildAccordion(childRows, category.children, buttons))
      }
      categorieRows.push(
        <Accordion flush className='category-accordion' key={category.id}>
          <Accordion.Item eventKey={category.id} >
            <Accordion.Header className={`journal-category-header ${(childRows.length === 0) && 'no-accordion-button'}`}>
              <Table width='100%' className='category-table'>
                {index === 0 ?
                  <thead>
                    <tr>
                      <th width='1%'></th>
                      <th width='20%'>{translate('Name')}</th>
                      <th width='10%'>{translate('Visible')}</th>
                      <th width='10%'>{translate('Sort index')}</th>
                      <th width='20%'>{translate('Created/modified')}</th>
                      <th width='20%'>{translate('Action')}</th>
                    </tr>
                  </thead>
                  :
                  <thead className='no-border'>
                    <tr><th width='1%'></th>
                      <th width='20%'></th>
                      <th width='10%'></th>
                      <th width='10%'></th>
                      <th width='20%'></th>
                      <th width='20%'></th>
                    </tr>
                  </thead>
                }
                <tbody>
                  <tr key={category.id} className='product-row'>
                    <td>
                      <Form.Check onClick={(event) => { buttons.select.onClick(event, category) }} defaultChecked={selectedCategories?.hasOwnProperty(category.id)} />
                    </td>
                    <td>{category.title}</td>
                    <td>
                      <div className='table-icons'>
                        {category?.is_visible ?
                          <i className="bi bi-check-circle true onclick-enabled" onClick={() => { toggleIsVisible.onClick(category, refresh) }}></i>
                          :
                          <i className="bi bi-x-square false onclick-enabled" onClick={() => { toggleIsVisible.onClick(category, refresh) }}></i>}
                      </div>
                    </td>
                    <td>{category.sort_index}</td>
                    <td>
                      <p>{translate('Created')}: {new Date(category.creation).toLocaleString()}</p>
                      <p>{translate('Modified')}: {new Date(category.modification).toLocaleString()}</p>
                    </td>
                    <td>
                      <Button size="sm" type='button' onClick={(event) => { modalview.onClick(event, category) }}>{translate(modalview.tag)}</Button>
                      {edit && <Button size="sm" type='button' onClick={(event) => { edit.onClick(event, category) }}>{translate(edit.tag)}</Button>}
                      {del && <Button size="sm" type='button' onClick={() => { del.onClick(category) }}>{translate(del.tag)}</Button>}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
            <Accordion.Body className='journal-category-body'>{childRows}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    })
    return categorieRows
  }

  function GetChildAccordion(childRows, children, buttons) {
    let childTable = []
    let childRow = []

    let newChildren = JSON.parse(JSON.stringify(children))
    for (const child of newChildren.sort((a, b) => a.sort_index - b.sort_index)) {
      let childSubRows = []
      if (child.children) {
        childSubRows.push(GetChildAccordion(childRows, child.children, buttons))
      }

      childRow.push(
        <Accordion flush className='category-accordion' key={child.id}>
          <Accordion.Item eventKey={child.id}>
            <Accordion.Header className={`journal-category-header ${childSubRows.length === 0 && 'no-accordion-button'}`}>
              <Table width='100%' className='category-table'>
                <thead className='no-border'>
                  <tr><th width='1%'></th>
                    <th width='20%'></th>
                    <th width='10%'></th>
                    <th width='10%'></th>
                    <th width='20%'></th>
                    <th width='20%'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={child.id} className='product-row'>
                    <td>
                      <Form.Check onClick={(event) => { buttons.select.onClick(event, child) }} defaultChecked={selectedCategories?.hasOwnProperty(child.id)} />
                    </td>
                    <td>{child.title}</td>
                    <td>
                      <div className='table-icons'>
                        {child?.is_visible ?
                          <i className="bi bi-check-circle true onclick-enabled" onClick={() => { toggleIsVisible.onClick(child, refresh) }}></i>
                          :
                          <i className="bi bi-x-square false onclick-enabled" onClick={() => { toggleIsVisible.onClick(child, refresh) }}></i>}
                      </div>
                    </td>
                    <td>{child.sort_index}</td>
                    <td>
                      <p>{translate('Created')}: {new Date(child.creation).toLocaleString()}</p>
                      <p>{translate('Modified')}: {new Date(child.modification).toLocaleString()}</p>
                    </td>
                    <td>
                      <Button size="sm" type='button' onClick={(event) => { modalview.onClick(event, child) }} id={child.id}>{translate(modalview.tag)}</Button>
                      {edit && <Button size="sm" type='button' onClick={(event) => { edit.onClick(event, child) }} id={child.id}>{translate(edit.tag)}</Button>}
                      {del && <Button size="sm" type='button' onClick={(event) => { del.onClick(event, child) }} id={child.id}>{translate(del.tag)}</Button>}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
            <Accordion.Body>{childSubRows}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    }
    if (childRow.length > 0) {
      childTable.push(childRow)
    }
    return childTable
  }

  return (
    <>
      {props.paginated && <PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      {Object.keys(selectedCategories).length > 0 &&
        <Navbar expand="xxl" className="bg-body-tertiary">
          {Object.keys(selectedCategories).length}
          {Object.keys(selectedCategories).length <= 1 ? ' category selected.' : ' categories selected.'}
          <Dropdown className='actions-button'>
            <Dropdown.Toggle size='sm' type='button'>
              {translate('Actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {multiEdit && <Dropdown.Item key={0} onClick={(event) => { props?.multiEdit?.modal?.toggleModal(event) }}>{multiEdit.tag}</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      }
      {categorieRows}
      <CategoryModal modal={modalview.modal} />
      <CategoryMultiEditModal {...props?.multiEdit?.modal} />
    </>
  );
}

