import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { getPurchaseAdviceTableBarcodeVisible, getWarehouseAdviceList, getWarehouseAdvicePageSize, getWarehouseAdvicePagination, getWarehouseAdviceReseller, setPurchaseAdviceTableBarcodeVisible, setWarehouseAdviceList, setWarehouseAdvicePageSize, setWarehouseAdvicePagination, setWarehouseAdviceReseller } from "../../redux/dataSlices/warehouseSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import useHandleError from "../../customhooks/useHandleError";
import GetWarehouseProductsPurchaseAdviceList from "../../actablueAPI/warehouse/GetWarehouseProductPurchaseAdviceList";
import WarehouseAdviceTable from "../../components/warehousePurchaseAdviceTable";
import GetWarehouseProductsCalculatePurchaseAdvice from "../../actablueAPI/warehouse/GetWarehouseProductCalculatePurchaseAdvice";
import GetRelationsByTypeAll from "../../actablueAPI/relations/GetRelationsByTypeAll";
import { getHashRelationsAll, getSupplierRelationsAll, setRelationsAll } from "../../redux/dataSlices/relationSlice";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import { Col, Row } from "react-bootstrap";
import { PurchaseAdvicePDF } from "../../pdfcreators/purchaseAdvicePDF";
import TableButton from "../../components/tableButton";
import { useTranslation } from "react-i18next";
import { getProgressModalShow, setProgressModalShow, setTaskData } from "../../redux/dataSlices/analyseSlice";
import ProgressModal from "../../components/progressModal";
import { PurchaseAdviceExport } from "../../exports/purchaseAdviceExport";
import GetWarehouseProductsPurchaseAdviceAll from "../../actablueAPI/warehouse/GetWarehouseProductPurchaseAdviceAll";
import { confirmAlert } from "react-confirm-alert";
import GetWarehouseProductsPackagingToMinStock from "../../actablueAPI/warehouse/GetWarehouseProductsPackagingToMinStock";

const PurchaseAdviceList = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const adviceList = useSelector(getWarehouseAdviceList);
  const page = useSelector(getWarehouseAdvicePagination);
  const size = useSelector(getWarehouseAdvicePageSize);
  const suppliers = useSelector(getSupplierRelationsAll);
  const hashRelationsAll = useSelector(getHashRelationsAll);
  const relation_id = useSelector(getWarehouseAdviceReseller);
  const modal_show = useSelector(getProgressModalShow);
  const barcodeVisible = useSelector(getPurchaseAdviceTableBarcodeVisible);
  const role = useSelector(getRole);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const [refresh, setRefresh] = useState(false);
  const navTitle = "Purchase advice";
  const { t: translate } = useTranslation();

  const toggleRefresh = () => {
    setRefresh(!refresh)
  }

  const onPackagingToMinStockClick = () => {
    confirmAlert({
      title: "Set Packaging to Min Stock",
      message: "Set Packaging to Min Stock",
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            GetWarehouseProductsPackagingToMinStock({ token: token, admin_id: admin_id, location_id: location_id })
              .then(() => {
                HandleError({ error: translate("started"), showPopup: true, variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
              })
              .catch(error => { HandleError({ error: error }) })
          }
        },
        {
          label: translate('No'),
        }
      ]
    })
  }

  const onRecalculateClick = () => {
    dispatch(setLoading(true))
    GetWarehouseProductsCalculatePurchaseAdvice({ token: token, admin_id: admin_id, location_id: location_id })
      .then(() => {
        setRefresh(!refresh)
        HandleError({ error: translate("calculate_purchase_advice_started"), showPopup: true, variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  }

  const onExportExcelClick = () => {
    try {
      let task_data = {
        current: 0,
        total: adviceList?.length ?? 0,
        progress: 0
      };

      dispatch(setTaskData(task_data))
      dispatch(setProgressModalShow(true))

      GetWarehouseProductsPurchaseAdviceAll({ token: token, admin_id: admin_id, location_id: location_id, relation_id: relation_id, dispatch: dispatch })
        .then(response => {
          PurchaseAdviceExport({ adviceList: response, relation_id: relation_id, hashRelationsAll: hashRelationsAll, dispatch: dispatch })
        })
    } catch (error) {
      dispatch(setProgressModalShow(false))
      HandleError({ error: error })
    }
  }

  const onExportPdfClick = async () => {
    try {
      await GetWarehouseProductsPurchaseAdviceList({ token: token, admin_id: admin_id, location_id: location_id, relation_id: relation_id, size: 1 })
        .then(response => {
          if (response?.data?.total_elements > 1000) {
            HandleError({ error: translate("export_pdf_product_limit"), showPopup: true, variant: "error", persist: true, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
          } else {
            let task_data = {
              title: 'Preparing export...',
              current: 0,
              total: adviceList?.length ?? 0,
              progress: 0
            };

            dispatch(setTaskData(task_data))
            dispatch(setProgressModalShow(true))

            GetWarehouseProductsPurchaseAdviceAll({ token: token, admin_id: admin_id, location_id: location_id, relation_id: relation_id, size: 10, dispatch: dispatch })
              .then(response => {
                PurchaseAdvicePDF(response, relation_id, hashRelationsAll, dispatch);
              })
          }
        })
    } catch (error) {
      dispatch(setProgressModalShow(false))
      HandleError({ error: error })
    }
  }

  const onSupplierChange = (event) => {
    dispatch(setWarehouseAdviceReseller(event?.value))
  }

  const recalculateButton = {
    tag: "Recalculate",
    value: 'Recalculate',
    onClick: onRecalculateClick
  }

  const packagingToMinStockButton = {
    tag: "Packaging to Min Stock",
    value: 'Packaging to Min Stock',
    onClick: onPackagingToMinStockClick
  }

  const exportExcelButton = {
    tag: "Export to Excel",
    value: 'Export to Excel',
    onClick: onExportExcelClick
  }

  const exportPdfButton = {
    tag: "Export to PDF",
    value: 'Export to PDF',
    onClick: onExportPdfClick
  }

  const refreshButton = {
    tag: "Refresh",
    value: 'refresh',
    onClick: toggleRefresh,
    className: 'add-button'
  }

  const onBarcodeVisibleClick = () => {
    dispatch(setPurchaseAdviceTableBarcodeVisible(!barcodeVisible));
  }

  const searchAndFilter = {
    style: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: '2rem',
      marginBottom: 0
    },
    filters: [
      {
        label: 'filter_label_supplier',
        options: suppliers,
        onChange: onSupplierChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ],
    settings: [
      {
        reverse: true,
        label: 'Barcodes Visible',
        onClick: onBarcodeVisibleClick,
        value: barcodeVisible || false
      },
    ]
  }

  const onViewHide = () => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Exporting...',
      onHide: onViewHide
    }
  }

  const card = {
    size: 12,
    title: navTitle,
    subtitle: <>
      <Row style={{ marginTop: 15 }}>
        <Col md={6} style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'flex-start' }}>
          {(role === 'super' || role === 'admin') && <TableButton {...recalculateButton} />}
          {(role === 'super' || role === 'admin') && <TableButton {...packagingToMinStockButton} />}
          <TableButton {...exportExcelButton} />
          <TableButton {...exportPdfButton} />
        </Col>
        <Col md={6} style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TableButton {...refreshButton} />
        </Col>
      </Row>
    </>,
    data: <WarehouseAdviceTable {...adviceList} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setWarehouseAdvicePagination(1))
    dispatch(setWarehouseAdvicePageSize(10))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      axios.all([
        GetWarehouseProductsPurchaseAdviceList({ token: token, admin_id: admin_id, location_id: location_id, relation_id: relation_id, page: page, size: size }),
        GetRelationsByTypeAll({ token: token, admin_id: admin_id, type: 'SUPPLIER' })
      ])
        .then(axios.spread((warehouseResponse, resellerResponse) => {
          dispatch(setWarehouseAdviceList(warehouseResponse.data))
          dispatch(setRelationsAll(resellerResponse))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, page, size, refresh, relation_id]);

  return (
    <>
      <SearchAndMultiFilterSingleSelect {...searchAndFilter} />
      <ProgressModal {...progressModal} />
      <BasicCard {...card} />
    </>
  );
};
export default PurchaseAdviceList;
