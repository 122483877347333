import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';
import CreateField from '../helpers/createField';

function AttributeGroupForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('type')) {
    props.type.selected = { 'value': props?.data?.type, 'label': props?.data?.type }
  }

  let basicFields = []
  props.fields.forEach(field => {
    basicFields.push(CreateField(field, props))
  });

  return (
    <>
      <Form>
        {basicFields}
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default AttributeGroupForm;