import { useSelector, useDispatch } from 'react-redux';

import { getToken } from "../redux/dataSlices/tokenSlice";

import HandleOnChange from "../helpers/handleOnChange";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import './css/reservationAssignModal.css'
import { getOrder, getOrderPlanModalShow, getOrders, setOrderPlanModalShow, setOrders } from '../redux/dataSlices/orderSlice';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';
import { useEffect, useState } from 'react';
import { planningAssignFormFields } from '../portal/planning/planningFormFields';
import PlanningForm from './planningForm';
import HandleOnChangeSingleSelectFieldWithValue from '../helpers/handleOnChangeSingleSelectFieldWithValue';
import { getHashPlanningObjectsAll, getHashPlanningsFromPlanningObjects, getPlanningAssignDevicesLoading, getPlanningLocation, getPlanningObjectsAll, setHashPlanningsFromPlanningObjects } from '../redux/dataSlices/planningSlice';
import { statusFilterChoices } from '../selectfieldchoices/planningselect.mjs';
import DeepCopy from '../helpers/DeepCopy';
import { validateFormObject } from '../helpers/validateFormObject';
import moment from 'moment';
import { PostPlanning } from '../actablueAPI/planning/Planning';
import axios from 'axios';
import { getRelation, setRelation } from '../redux/dataSlices/relationSlice';
import { Col, Spinner, Stack } from 'react-bootstrap';
import useHandleError from '../customhooks/useHandleError';
import PutOrderById from '../actablueAPI/invoicepayments/PutOrderByID';
// import HandleOnChangeMultiSelectFieldWithObjects from '../helpers/handleOnChangeMultiSelectFieldWithObjects';
import { getDevicesAll } from '../redux/dataSlices/deviceSlice';
import HandleOnChangeSingleSelectFieldWithObject from '../helpers/handleOnChangeSingleSelectFieldWithObject';


export default function UnpaidOrderPlanModal(props) {
  const token = useSelector(getToken);
  const order = useSelector(getOrder);
  const unpaidOrders = useSelector(getOrders);
  const location_id = useSelector(getSelectedLocation_id);
  const planning_objects = useSelector(getPlanningObjectsAll);
  const planning_location = useSelector(getPlanningLocation);
  const plannings = useSelector(getHashPlanningsFromPlanningObjects);
  const devices = useSelector(getDevicesAll);
  const devices_loading = useSelector(getPlanningAssignDevicesLoading);
  // const hash_devices_all = useSelector(getHashDevicesAll);
  const hash_planning_objects = useSelector(getHashPlanningObjectsAll);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const relation = useSelector(getRelation);
  const modalShow = useSelector(getOrderPlanModalShow);
  const [invalidFields, setInvalidFields] = useState([]);
  const [planning, setPlanning] = useState({ location_id: location_id, type: 'PLANNING', status: 'ACCEPTED', duration: 60 });
  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newPlanning = HandleOnChange({ event: event, object: planning });
    setPlanning(newPlanning);
  };

  const onDeviceChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'device_id' })
    setPlanning(newPlanning)
  };

  const onStatusChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'status' })
    setPlanning(newPlanning)
  };

  // const onPlanningObjectChange = (event) => {
  //   let newPlanning = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: planning, selectfield: 'planning_objects', hash: hash_planning_objects })
  //   setPlanning(newPlanning)
  // };

  const onPlanningObjectChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: planning, selectfield: 'planning_objects', hash: hash_planning_objects })
    newPlanning.device_id = newPlanning?.planning_objects?.device_id || null
    setPlanning(newPlanning)
  };

  const onCancel = () => {
    dispatch(setOrderPlanModalShow(false));
    setInvalidFields([]);
    setPlanning({ location_id: location_id, type: 'PLANNING', status: 'ACCEPTED', duration: 60 });
    dispatch(setRelation(null));
  }

  const onSubmit = (event) => {
    const [invalid] = validateFormObject({ object: planning, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newPlannings = DeepCopy(plannings);
    let newUnpaidOrders = DeepCopy(unpaidOrders);
    let newPlanning = DeepCopy(planning);
    let newOrder = DeepCopy(order);

    newPlanning.type = planning.type.toUpperCase();
    newPlanning.status = planning.status.toUpperCase();
    newPlanning.planning_timestamp = moment(planning.planning_timestamp).utc().format();

    newOrder.status = 'ACCEPTED';

    if (!newPlanning.planning_objects) newPlanning.planning_objects = [];

    if (newPlanning?.planning_objects) {
      newPlanning.planning_objects.planning_object_id = newPlanning?.planning_objects?.id;
      delete newPlanning.planning_objects.id
      newPlanning.planning_objects = [newPlanning?.planning_objects]
      // delete newPlanning?.planning_objects?.id
      // for (const planning_object of newPlanning?.planning_objects) {
      //   planning_object.planning_object_id = planning_object.id
      //   delete planning_object.id
      // }
    }

    axios.all([
      PutOrderById({ token: token, admin_id: newOrder?.administration_id, id: newOrder?.id, data: newOrder }),
      PostPlanning({ token: token, data: newPlanning })
    ])
      .then(axios.spread((orderResponse, planningResponse) => {
        const orderIndex = newUnpaidOrders.findIndex(order => order.id === orderResponse?.id)
        newUnpaidOrders.splice(orderIndex, 1);
        for (const planning_object of planningResponse?.data?.planning_objects) {
          if (!newPlannings[planning_object?.planning_object_id]) newPlannings[planning_object?.planning_object_id] = {}
          newPlannings[planning_object?.planning_object_id][props?.planning?.id] = planningResponse?.data
        }
        dispatch(setHashPlanningsFromPlanningObjects(newPlannings));
        dispatch(setOrders(newUnpaidOrders));
        dispatch(setOrderPlanModalShow(false));
        dispatch(setRelation(null));
        setInvalidFields([]);
        setPlanning({ location_id: location_id, type: 'PLANNING', status: 'ACCEPTED', duration: 60 });
      }))
      .catch(error => {
        HandleError({ error: error?.response?.data?.message || error, showPopup: true, })
      })
  };

  const formData = {
    cancel: {
      title: 'Cancel',
      type: 'submit',
      onClick: onCancel
    },
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    invalidFields: invalidFields,
    fields: planningAssignFormFields,
    field: {
      onChange: onChange
    },
    // hash_devices_all: hash_devices_all,
    data: planning,
    device_id: {
      name: 'device_id',
      options: devices,
      selected: '',
      onChange: onDeviceChange,
      clearable: Boolean(true),
      searchable: Boolean(true),
      loading: devices_loading
    },
    status: {
      name: 'status',
      options: statusFilterChoices,
      selected: '',
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    planning_objects: {
      name: 'planning_objects',
      options: planning_objects,
      selected: [],
      onChange: onPlanningObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    is_assign: true
  };

  useEffect(() => {
    if (!modalShow) return;
    let newPlanning = DeepCopy(planning);
    newPlanning.planning_name = `Planning Order ${order?.number}`;
    newPlanning.order_id = order?.id;
    newPlanning.relation_id = order?.relation_id || null;

    if (!relation) {
      setPlanning(newPlanning);
      return;
    }

    const relationEmail = relation?.emails?.find(email => email.type === 'reserveringen')?.email;
    const relationPhonenumber = relation?.phone_numbers?.find(number => number.type === 'reserveringen')?.number;

    newPlanning.planning_email = relationEmail || relation?.emails?.[0]?.email || null;
    newPlanning.planning_phonenumber = relationPhonenumber || relation?.phone_numbers?.[0]?.number || null;

    newPlanning.planning_timestamp = moment()
      .tz(planning_location?.timezone || 'Europe/Amsterdam')
      .startOf('minute') // Start at the current minute
      .add(5 - (moment().minute() % 5), 'minutes') // Round to nearest 5 minutes
      .format('YYYY-MM-DDTHH:mm:ss');

    setPlanning(newPlanning);
    // eslint-disable-next-line
  }, [order, relation])

  return (
    <Modal
      show={props.modal.show}
      size="xl"
      dialogClassName="modal-md modal-fullscreen-md-down"
      contentClassName='w-100 h-100'
      className='product-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered scrollable>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {translate('Plan Order')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.modal.loading ?
          <>
            <Stack direction="horizontal" className='col-md-2 mx-auto'>
              <Spinner animation="border" role="status" className="me-2">
                <span className="visually-hidden">{translate('Loading...')}</span>
              </Spinner>
              <Col>{translate('Loading...')}</Col>
            </Stack>
          </>
          :
          <>
            <PlanningForm {...formData} />
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type='submit' onClick={onCancel}>{translate('Cancel')}</Button>
        <Button variant="primary" type='submit' onClick={onSubmit}>{translate('Save')}</Button>
      </Modal.Footer>
    </Modal>
  );

}