import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

export default function useHandleError() {
  const { enqueueSnackbar } = useSnackbar();

  const HandleError = ({ error, showPopup = false, variant, persist, preventDuplicate, transitionDuration, anchorOrigin, style }) => {

    switch (variant) {
      case 'warning':
      case 'info':
        Sentry.captureMessage(error)
        break;
      case 'error':
      default:
        Sentry.captureException(error)
        break;
    }

    console.error('HandleError:', error);

    if (!showPopup) return;

    enqueueSnackbar(error?.message || error || 'An unexpected error occurred', {
      variant: variant || 'error',
      persist: persist || false,
      preventDuplicate: preventDuplicate || true,
      transitionDuration: transitionDuration || { enter: 225, exit: 195 },
      anchorOrigin: anchorOrigin || { horizontal: 'right', vertical: 'bottom' },
      style: style
    });
  };

  return HandleError;
}