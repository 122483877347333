const PlaylistElement = (name, width, color) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            height: '100%',
            width: width,
            backgroundColor: color,
            padding: '2px',
            overflow: 'hidden'
        }}>
            <p>
                {name}
            </p>
        </div>
    )
}

const ScheduleTimeline = (data) => {
    let spacers = []
    for(let i=0; i<23; i++){
        spacers.push(
            <div style={{
                height: '100%',
                width: '0px',
                borderRight: '2px solid rgba(150,150,150,0.5)',
                marginRight: '-1px'
            }}/>
        )
    }
    const playlistArray = [...data.schedule.playlists]
    let timelineElements = []
    let widthValue = []
    if (playlistArray) {
        if (playlistArray.length > 0) {
            playlistArray.forEach((playlist, index) => {
                let playlistDuration
                if (playlist) {
                    if(playlist.start_time !== '' && playlist.end_time !== ''){
                        let splitStartTime = playlist.start_time.split(':')
                        let splitEndTime = playlist.end_time.split(':')
                        playlistDuration = (parseInt(splitEndTime[0]) - parseInt(splitStartTime[0])) * 60
                        playlistDuration += (parseInt(splitEndTime[1]) - parseInt(splitStartTime[1])) + 1
                    }
                }
                if (playlist === null || playlist === undefined) {
                    if (data.schedule[`transition_time${index+1}`]) {
                        let splitStartTime
                        if (data.schedule[`transition_time${index}`]) {
                            splitStartTime = data.schedule[`transition_time${index}`].split(':')
                        } else {
                            splitStartTime = ['00', '00']
                        }
                        let splitEndTime = data.schedule[`transition_time${index+1}`].split(':')
                        playlistDuration = (parseInt(splitEndTime[0]) - parseInt(splitStartTime[0])) * 60
                        playlistDuration += (parseInt(splitEndTime[1]) - parseInt(splitStartTime[1]))
                    }
                }
                widthValue[index] = playlistDuration / 1440 * 100
                if (playlist) {
                    if(index === 0 || data.schedule[`transition_time${index}`]){
                        let color
                        if (index % 2) {
                            color = 'rgba(247,150,11,0.5)'
                        } else {
                            color = 'rgba(1,122,181,0.5)'
                        }
                        timelineElements.push(PlaylistElement(playlist.name, `${widthValue[index]}%`, color))
                    }
                } else {
                    timelineElements.push(<div style={{
                        width: `${widthValue[index]}%`
                    }}></div>)
                }
            })

        }
    }
    return (
        <>
            <div style={{
                width: 'auto',
                height: '13px',
                display: 'flex',
                margin: '10px',
                justifyContent: 'space-between',
            }}>
                <p>00:00</p>
                <p>12:00</p>
                <p>24:00</p>
            </div>
            <div style={{
                width: 'auto',
                height: '30px',
                margin: '10px',
                display: 'grid',
            }}>
                <div style={{
                    gridRow: '1',
                    gridColumn: '1',
                    display: 'flex',
                    width: '100%',
                    height: '30px',
                    borderRadius: '6px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'black',
                    zIndex: '1',
                    overflow: 'hidden'
                }}>
                    {timelineElements}
                </div>
                <div style={{
                    gridRow: '1',
                    gridColumn: '1',
                    display: 'flex',
                    width: '100%',
                    height: '28px',
                    alignSelf: 'center',
                    justifyContent: 'space-evenly'
                }}>
                    {spacers}
                </div>
                
            </div>
        </>
        )
}
// PlaylistElement('', `${widthValue[index]}%`)
export default ScheduleTimeline;