import * as React from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Search from "../static/icons/search.svg";
import "./css/searchAndMultiFilter.css"
import SingleSelectField from "./singleselectField";
import { Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const SearchAndMultiFilterSingleSelect = (props) => {
  const { t: translate } = useTranslation();

  let search = []
  let list = []

  if (props.hasOwnProperty('search')) {
    search.push(
      <li key={'search'}>
        <InputGroup className="searchAndMultiFilter-search">
          <Form.Control placeholder={translate('Search...')} className={`search-input${!props.search.showButton ? '-no-button' : ''}`} onChange={props.search.onChange} value={props.search.value} onKeyDown={props.search.onKeyDown} />
          {props.search.showButton &&
            <Button className='btn-no-shadow' onClick={props.search.onClick}>
              <img className="search-button" src={Search} alt=''></img>
              {translate('Search')}
            </Button>
          }
        </InputGroup>
      </li>
    )
  }

  if (props.hasOwnProperty('settings')) {
    if (Array.isArray(props?.settings)) {
      props?.settings?.forEach((setting, index) => (
        list.push(
          <li key={`searchAndFilterSetting-${index}`}>
            <Form>
              <Form.Check reverse={setting?.reverse} type="switch" label={translate(setting?.label)} onClick={(event) => { setting.onClick(event) }} defaultChecked={setting?.value || false} style={{ paddingRight: 5, ...setting?.style }} />
            </Form>
          </li>
        )
      ))
    } else {
      list.push(
        <li key={'settings'}>
          <Form>
            <Form.Check reverse type="switch" label={translate(props.settings.minimal.label)} onClick={(event) => { props.settings.minimal.onClick(event) }} defaultChecked={props.settings.minimal.value} style={{ paddingRight: 5 }} />
          </Form>
        </li>
      )
    }
  }

  if (props.hasOwnProperty('filters')) {
    for (const filter of props.filters) {
      list.push(
        <li key={filter.label}>
          <div className="searchAndMultiFilter-filter">
            <label htmlFor='navbar-select' className="filter-label">
              {translate(filter.label)}
            </label>
            <SingleSelectField {...filter} name={filter.label} />
          </div>
        </li>
      )
    }

  }

  return (
    <Col md={props.md || 12} style={props.style}>
      <ul className="search-multi-filter">
        {search}
        <ul>
          {list}
        </ul>
      </ul>
    </Col>
  );
};
export default SearchAndMultiFilterSingleSelect;
