import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getWarehouseAdvicePageSize, setWarehouseAdvicePageSize, setWarehouseAdvicePagination, setWarehouseAdviceModalShow, setWarehouseAdvice, getWarehouseAdviceModalShow, getWarehouseAdviceList, setWarehouseAdviceList } from "../../redux/dataSlices/warehouseSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import HandleOnChange from "../../helpers/handleOnChange";
import PutWarehouseProductById from "../../actablueAPI/warehouse/PutWarehouseProductById";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import useHandleError from "../../customhooks/useHandleError";
import { useRef, useState } from "react";

export default function AdviceButtons() {
  const modalShow = useSelector(getWarehouseAdviceModalShow)
  const pageSize = useSelector(getWarehouseAdvicePageSize);
  const scopes = useSelector(getScopes)
  const adviceList = useSelector(getWarehouseAdviceList);
  const token = useSelector(getToken);
  const [controller, setController] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const signal = useRef();

  function onViewClick(product) {
    dispatch(setWarehouseAdvice(product));
    dispatch(setWarehouseAdviceModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setWarehouseAdviceModalShow(false));
  };

  const onPaginationClick = (event) => {
    dispatch(setWarehouseAdvicePagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setWarehouseAdvicePageSize(event.value))
  };

  function onclickEdit(product) {
    dispatch(setWarehouseAdvice(product));
    navigate("/purchaseadvice/edit");
  };

  const stopAxios = async () => {
    if (controller) {
      controller.abort();
    }
  };

  const recalculateAdviceList = (event, object) => {
    let newAdviceList = JSON.parse(JSON.stringify(adviceList));
    let newProduct = adviceList?.content?.find(product => product?.id === object?.id);
    let newProductIndex = adviceList?.content?.findIndex(product => product?.id === object?.id);
    newProduct = HandleOnChange({ event: event, object: newProduct });
    if (newProduct?.minimum_stock < 0) return
    if (newProduct?.total_quantity < 0) return
    newProduct.purchase_advice = newProduct?.minimum_stock - newProduct?.total_quantity
    newAdviceList?.content?.splice(newProductIndex, 1, newProduct);
    dispatch(setWarehouseAdviceList(newAdviceList))
    return newProduct
  }

  const onChangeTable = (event, object) => {
    stopAxios();
    const newProduct = recalculateAdviceList(event, object);
    let putProduct = {}
    putProduct.id = newProduct.id
    putProduct.administration_id = newProduct.administration_id
    putProduct.location_id = newProduct.location_id
    putProduct.minimum_stock = newProduct.minimum_stock ?? 0
    putProduct.total_quantity = newProduct.total_quantity ?? 0

    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    PutWarehouseProductById({ token: token, id: putProduct.id, data: putProduct, signal: signal?.current })
      .then(() => { })
      .catch(error => {
        if (error?.code !== "ERR_CANCELED") HandleError({ error: error })
      })
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },
    onChange: onChangeTable
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.purchaseadvice >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  return buttons
}

