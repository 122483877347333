import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

export default function TransactionTotalsTable(props) {
  const { t: translation } = useTranslation();
  if (props.transaction) {
    return (
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='20%'></th>
            <th width='60%'></th>
            <th width='20%'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{translation("Amount open")}:</td>
            <td>{Number(props.transaction.amount_open).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td></td>
            <td>{translation("Amount paid")}:</td>
            <td>{Number(props.transaction.amount_paid).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td></td>
            <td>{translation("Exchange")}:</td>
            <td>{Number(props.transaction.amount_exchange).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}