import { Button, Table, Form, Dropdown, Navbar, Col, Container, Row, InputGroup } from 'react-bootstrap'
import ProductButtons from "../portal/products/productbuttons";
import ProductModal from "../components/productModal";
import PaginationAndSizeComponent from './paginationAndSize';
import './css/productTable.css';
import { getProductTablePriceEditable, getSelectedProducts } from "../redux/dataSlices/productSlice";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import DownloadShelflabelsModal from './downloadShelflabelsModal';
import ProductMultiEditModal from './productMultiEditModal';
import { getHashPrintersAll } from '../redux/dataSlices/printerSlice';

export default function ProductModifiedTable(props) {
  const products = props.products
  const templates = props.templates
  const settings = props.settings
  const refresh = props.refresh
  const buttons = ProductButtons.call()
  const hash_printers_all = useSelector(getHashPrintersAll);
  const printer_type = hash_printers_all[settings?.default_printer]?.type
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const multiEdit = buttons.hasOwnProperty('multiEdit') ? Object.assign({}, buttons.multiEdit) : false
  const print = buttons.hasOwnProperty('print') && settings?.default_printer ? Object.assign({}, buttons.print) : false
  const download = buttons.hasOwnProperty('download') ? Object.assign({}, buttons.download) : false
  const bxlPrint = buttons.hasOwnProperty('bxlPrint') ? Object.assign({}, buttons.bxlPrint) : false
  const del = buttons.hasOwnProperty('delete') ? Object.assign({}, buttons.delete) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const toggleActive = buttons.hasOwnProperty('toggleActive') ? Object.assign({}, buttons.toggleActive) : false
  const selectedProducts = useSelector(getSelectedProducts)
  const priceEditable = useSelector(getProductTablePriceEditable);
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize
  const pages = products?.total_pages
  const page = (products?.number + 1)

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  pagesize['number_of_elements'] = products?.number_of_elements

  const generateDropdownTemplates = (templates, product) => {
    const dropdownTemplates = [];

    if (templates) {
      templates.forEach((template) => {
        if (print) {
          dropdownTemplates.push(
            <Dropdown.Item key={template.id} id={template.id} onClick={() => { print.onClick(template, product) }}>
              {template.type === 'bar' ? <i className="bi bi-upc-scan"></i> : <i className="bi bi-qr-code-scan"></i>}
              {template.name}
            </Dropdown.Item>
          );
        }
      });
    }

    return dropdownTemplates;
  };

  let productRows = []
  if (products && products.content && products.content.length > 0) {
    // sort products modification date 
    let sorted = Object.values(products.content).sort((a, b) => new Date(b?.modification) - new Date(a?.modification))
    // eslint-disable-next-line
    // products.content.map((product) => {
    // eslint-disable-next-line array-callback-return
    sorted.map((product) => {
      const importccvshop = buttons.hasOwnProperty('importccvshop') && product.ccv?.ccv_id ? Object.assign({}, buttons.importccvshop, { id: product.id }) : false
      const link = buttons.hasOwnProperty('link') ? Object.assign({}, buttons.link, { id: product.id }) : false
      const dropdownTemplates = generateDropdownTemplates(templates, product)
      productRows.push(
        <tr className={selectedProducts.hasOwnProperty(product.id) ? 'product-row selected' : 'product-row'} key={product.id}>
          <td>
            <Form>
              <Form.Check onChange={() => { buttons.select.onClick(product) }} checked={selectedProducts.hasOwnProperty(product.id)} />
            </Form>
          </td>
          <td>{product.sku_code}</td>
          <td>
            <img className="product-thumbnail" src={product.thumbnail} alt='' width='128'></img>
          </td>
          <td>{product.name}</td>
          {priceEditable ?
            <td align="right">
              <Col sm="10">
                <InputGroup>
                  <InputGroup.Text>€</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="price_incl_vat"
                    id="price_incl_vat"
                    step={0.01}
                    value={product?.price_incl_vat === null ? "" : product?.price_incl_vat}
                    onChange={(event) => { buttons?.onChange(event, product) }}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                </InputGroup>
              </Col>
            </td>
            :
            <td align="right">{Number(product.price_incl_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          }
          <td>{product.type}</td>
          <td>
            <div className='table-icons'>
              {product?.active ?
                <i className="bi bi-check-circle true onclick-enabled" onClick={() => { toggleActive.onClick(product, refresh) }}></i>
                :
                <i className="bi bi-x-square false onclick-enabled" onClick={() => { toggleActive.onClick(product, refresh) }}></i>}
            </div>
          </td>
          <td>
            <p>
              {translate('Created')}: {new Date(product.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(product.modification).toLocaleString()}
            </p>
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(product) }} id={product.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(product) }}>{edit.tag}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(product) }}>{del.tag}</Button>}
            {importccvshop && <Button size="sm" type='button' onClick={() => { importccvshop.onClick(product) }} id={product.id}>{importccvshop.tag}</Button>}
            {link && <Button size="sm" type='button' onClick={(event) => { link.onClick(event, product) }} id={product.id}>{link.tag}</Button>}
            {print &&
              <Dropdown>
                <Dropdown.Toggle size='sm' type='button'>
                  Print
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownTemplates}
                </Dropdown.Menu>
              </Dropdown>
            }
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {Object.keys(selectedProducts).length > 0 ?
        <Navbar expand="xxl" className="bg-body-tertiary">
          {Object.keys(selectedProducts).length} {translate('product(s) selected.')}
          <Dropdown className='actions-button'>
            <Dropdown.Toggle size='sm' type='button'>
              {translate('Actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {multiEdit && <Dropdown.Item key={0} onClick={(event) => { props?.multiEdit?.modal?.toggleModal(event) }}>{multiEdit.tag}</Dropdown.Item>}
              {download && <Dropdown.Item key={1} onClick={() => { download.onClick() }}>{download.tag}</Dropdown.Item>}
              {(bxlPrint && printer_type === 'Bixolon') && <Dropdown.Item key={1} onClick={() => { bxlPrint.onClick() }}>{bxlPrint.tag}</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
        :
        ''
      }
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              <Form>
                <Form.Check onChange={() => { buttons.selectAll.onClick(products?.content) }} checked={products?.content?.every((product) => selectedProducts.hasOwnProperty(product?.id))} />
              </Form>
            </th>
            <th>{translate('SKU code')}</th>
            <th>{translate('Image')}</th>
            <th>
              {translate('Name')}
            </th>
            <th className="text-end" width={`${priceEditable ? '15%' : 'auto'}`}>{translate('Price')}</th>
            <th>
              {translate('Type')}
            </th>
            <th>
              {translate('Active')}
            </th>
            <th>
              {translate('Date')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {
            productRows.length > 0 ? productRows :
              <Container>
                <Row className='justify-content-md-center'>
                  <Col>{translate('No products found')}.</Col>
                </Row>
              </Container>
          }
        </tbody>
        <ProductModal modal={modalview.modal} />
        <DownloadShelflabelsModal modal={download.modal} />
        <DownloadShelflabelsModal modal={bxlPrint.modal} />
        <ProductMultiEditModal {...props?.multiEdit?.modal} />
      </Table>
    </>
  );
}