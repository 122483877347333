import { React, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRememberMe, getToken, setRememberMe, setToken } from '../redux/dataSlices/tokenSlice';
import { Container, Col, Form, Button, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import './login.css'
import ErrorToast from "../components/errorToast";
import MessageToast from "../components/messageToast";
import { useTranslation } from 'react-i18next';
import PostUserForgot from "../actablueAPI/registrations/PostUserForgot";
import PostKeycloakUserLogIn from "../actablueAPI/keycloak/PostKeycloakUserLogin";
import IsTokenExpiredV2 from "../helpers/tokenExpiredCheckV2";
import useHandleError from "../customhooks/useHandleError";

export default function Login() {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const rememberMe = useSelector(getRememberMe);
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  const onUsernameChange = (event) => {
    setUser(event.target.value)
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  };

  const onForgotPassword = (event) => {
    event.preventDefault();
    if (user && !password) {
      PostUserForgot(user)
        .then(() => { })
        .catch(() => { });
      HandleError({
        error: "Ga naar je email en maak via de link in de ontvangen email een nieuw wachtwoord aan. Daarna kun je hier weer inloggen.",
        showPopup: true,
        variant: 'info',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      })
    }

    if (user && password) {
      HandleError({
        error: "Verkeerde knop? Indien wachtwoord vergeten: wis het wachtwoord.",
        showPopup: true,
        variant: 'warning',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      })
    }

    if (!user) {
      HandleError({
        error: "Vul je emailadres in en druk op wachtwoord vergeten.",
        showPopup: true,
        variant: 'warning',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      })
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    PostKeycloakUserLogIn({ user: user, password: password })
      .then(response => {
        dispatch(setToken(response.data));
        navigate("/dashboards");
        return;
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          HandleError({
            error: "Invalid user credentials",
            showPopup: true,
            variant: 'info',
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
          })
          return
        }
        HandleError({ error: error })
      });
  };

  useEffect(() => {
    if (!rememberMe) {
      dispatch(setToken());
      return;
    }

    if (token) {
      IsTokenExpiredV2({ token: token, dispatch: dispatch })
        .then(newToken => {
          if (!newToken) {
            dispatch(setToken());
            HandleError({
              error: "Refresh token expired, please login again.",
              showPopup: true,
              variant: 'info',
              anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
            })
            return;
          }

          dispatch(setToken(newToken));
          navigate("/dashboards");
          return;
        })
        .catch(error => {
          HandleError({ error: error })
        })
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid="lg" className="login-container">
      <div className="toast-container">
        <ErrorToast />
        <MessageToast />
      </div>
      <Col md={6} className="text-center">
        <div id="LoginLogo" className="mb-3" />
        <h4>
          {translate('Log in to your account')}
        </h4>
        <h6 className="text-muted">
          {translate('Welcome back! Please enter your details')}
        </h6>

        <Form className="mt-4" onSubmit={onSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Control type="email" placeholder={translate('Enter your email')} className="mb-3" onChange={onUsernameChange} />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <InputGroup>
              <Form.Control type={showPassword ? 'text' : 'password'}
                placeholder={translate('Enter your password')}
                className="mb-3" onChange={onPasswordChange} />
              <Button variant="show-hide" className="mb-3 btn-no-shadow btn-show-hide" onClick={togglePasswordVisibility}>
                <i className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}></i>
              </Button>
            </InputGroup>
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            {translate("Login")}
          </Button>
          <Row className="mt-3">
            <Col>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <Form.Group controlId="formRememberMe">
                    <Form.Check type="checkbox" label={translate('Remember me')} checked={rememberMe} onChange={() => { dispatch(setRememberMe(!rememberMe)) }} />
                  </Form.Group>
                </div>
              </div>
            </Col>
            <Col onClick={onForgotPassword}>
              <Button variant="link" className="mb-3 btn-forgot-password" onClick={onForgotPassword}>
                {translate("Forgot password?")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Container>
  );

};
