import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id, getSelectedLocation } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import WeatherWidget from "../../components/WeatherWidget";
import HighlightRevenueTable from "../../components/highlightRevenueTable";
import { statusReportHighlightsSingleDay } from "../../helpers/statusReportSingleDayHighlight";
import { statusReportGraphSingleDay } from "../../helpers/statusReportSingleDayGraph";
import { statusReportAverageSingleDay } from "../../helpers/statusReportSingleDayAverage";
import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import GetStatusReportRealtime from "../../actablueAPI/reporting/getStatusReportRealtime";
import useHandleError from "../../customhooks/useHandleError";

const Dashboard = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const location = useSelector(getSelectedLocation)
  const dispatch = useDispatch();
  const { t: translate } = useTranslation(); 
  const [highlightsdata, setHighlightsdata] = useState();
  const [averagedata, setAveragedata] = useState();
  const [graphCardData, setGraphCardData] = useState();
  const HandleError = useHandleError();

  let weatherWidgetCard = {
    size: 4
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const paymentsCard = {
    size: 4,
    title: translate('Payments'),
    data: <HighlightRevenueTable {...highlightsdata} />,
  };

  const averageCard = {
    size: 4,
    title: translate('Average'),
    data: <HighlightRevenueTable {...averagedata} />,
  };

  const graphCard = {
    size: 12,
    title: location_id ? translate("Today's revenue") : translate('Choose administration and location.'),
    data: graphCardData,
  };


  useEffect(() => {
    dispatch(setNavBarTitle(translate("Today's Overview")))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id && location_id) {
      GetStatusReportRealtime({ token: token, admin_id: admin_id, location_id: location_id, location: location })
        .then(realtimeResponse => {
          setHighlightsdata(statusReportHighlightsSingleDay(realtimeResponse.data))
          setAveragedata(statusReportAverageSingleDay(realtimeResponse.data))
          setGraphCardData(statusReportGraphSingleDay(realtimeResponse.data, translate))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  return (
    <>
      <WeatherWidget {...weatherWidgetCard} />
      <BasicCard {...paymentsCard} />
      <BasicCard {...averageCard} />
      <BasicCard {...graphCard} />
    </>
  )
};
export default Dashboard;

