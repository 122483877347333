import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getSelectedEan, setSelectedEan, getTemplateId, setTemplateImages } from "../../redux/dataSlices/templateSlice";
import { getProduct, setProduct } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setSettingsSchapkaartjes, getSettingsPrinter, setSettingsPrinter, getSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import ProductPrintForm from "../../components/productPrintForm";
import { productPrintFormFields, templateFormFields } from "./productPrintFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import '../../static/includes/js/bxlcommon';
import { clearBuffer, drawBitmap, getLabelData, printBuffer, setLabelId, setLength, setWidth } from '../../static/includes/js/bxllabel';
import { requestPrint } from "../../static/includes/js/bxlcommon";
import printJS from 'print-js'
import PostImageBase64 from "../../actablueAPI/shelflabels/PostImageBase64";
import GetShelflabelsSettings from "../../actablueAPI/shelflabels/GetShelflabelsSettings";
import GetShelflabelsTemplate from "../../actablueAPI/shelflabels/GetShelflabelsTemplate";
import GetShelflabelsPrinter from "../../actablueAPI/shelflabels/GetShelfLabelsPrinter";
import useHandleError from "../../customhooks/useHandleError";

const ProductPrint = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const template_id = useSelector(getTemplateId);
  const settings = useSelector(getSettingsSchapkaartjes);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const [template, setTemplate] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const product = useSelector(getProduct);
  const selectedEan = useSelector(getSelectedEan);
  const printer = useSelector(getSettingsPrinter);

  useEffect(() => {
    dispatch(setNavBarTitle("Product print"))
    dispatch(setTemplateImages([]))
    dispatch(setSelectedEan([]))
    if (product?.hasOwnProperty('eans')) {
      dispatch(setSelectedEan({ 'value': product?.eans[0]?.ean_code, 'label': product?.eans[0]?.ean_code }))
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      axios.all([
        GetShelflabelsSettings({ token: token, admin_id: admin_id, location_id: location_id }),
        GetShelflabelsTemplate({ token: token, id: template_id, admin_id: admin_id, location_id: location_id })
      ])
        .then(axios.spread((settingsResponse, templateResponse) => {
          dispatch(setSettingsSchapkaartjes(settingsResponse.data))
          setTemplate(templateResponse.data)
          dispatch(setLoading(false))
          GetShelflabelsPrinter({ token: token, admin_id: admin_id, location_id: location_id, id: settingsResponse.data.default_printer })
            .then(response => {
              dispatch(setSettingsPrinter(response.data))
            })
        }))
        .catch(error => {
          HandleError({ error: error })
          navigate("/products");
        });
    }
    // eslint-disable-next-line
  }, []);

  const onChange = (event) => {
    let newProduct = HandleOnChange({ event: event, object: product, admin_id: admin_id })
    let newTemplate = HandleOnChange({ event: event, object: template })
    dispatch(setProduct(newProduct))
    setTemplate(newTemplate)
  }

  const onEanChange = (event) => {
    dispatch(setSelectedEan(event))
  };

  function viewResult(result) {
    return;
  }

  const onPrintClick = async (event) => {
    const productData = {
      'product_id': product?.id,
      'admin_id': product?.administration_id,
      'location_id': product?.location_id,
      'name': product?.name ?? 'Unknown product',
      'contents': product?.description ?? '',
      'price': `${Number(product?.price_incl_vat).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'sku_code': product?.sku_code ?? '0',
      'ean_code': selectedEan.value ?? '0',
      'url': '',
      'discount': `${Number(product?.discount).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'emballage': `${Number(product?.emballage).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'price_incl_vat': `${Number(product?.price_incl_vat).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'price_excl_vat': `${Number(product?.price_excl_vat).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'price_incl_discount': `${(Number(product?.price_incl_vat || 0) - Number(product?.discount || 0)).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'brand': product?.product_specifications?.brand ?? '',
      'weight': product?.product_specifications?.weight ?? '',
      'height': product?.product_specifications?.height ?? '',
      'length': product?.product_specifications?.length ?? '',
      'width': product?.product_specifications?.width ?? ''
    }

    switch (printer.type) {
      case 'Bixolon':
        let issueID = 1;
        let pixelRatio = printer.dpi / 300;
        setLabelId(issueID);
        clearBuffer();
        PostImageBase64({ token: token, template_id: template?.id, code_type: settings.code_to_print, data: productData })
          .then(response => {
            const imgData = `data:image/png;base64,${response?.data}`
            let bitmapWidth = Math.round(template.width_px * pixelRatio)
            setWidth(bitmapWidth)
            if (printer?.paper_type === 'C') setLength(Math.round(template.height_px * pixelRatio), printer.gap_length, printer.paper_type, printer.gap_offset)
            drawBitmap(imgData, 0, 0, bitmapWidth, 0);

            printBuffer();

            let strSubmit = getLabelData()

            issueID++;

            requestPrint(printer.name, strSubmit, viewResult)
          })
        break;
      case 'Windows':
      default:
        printJS('templateCanvas', 'html');

    }
  };

  const onDownloadClick = async () => {
    setIsDownloading(true);
    const productData = {
      'product_id': product?.id,
      'admin_id': product?.administration_id,
      'location_id': product?.location_id,
      'name': product?.name ?? 'Unknown product',
      'contents': product?.description ?? '',
      'price': `${Number(product?.price_incl_vat).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'sku_code': product?.sku_code ?? '0',
      'ean_code': selectedEan.value ?? '0',
      'url': '',
      'discount': `${Number(product?.discount).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'emballage': `${Number(product?.emballage).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'price_incl_vat': `${Number(product?.price_incl_vat).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'price_excl_vat': `${Number(product?.price_excl_vat).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'price_incl_discount': `${(Number(product?.price_incl_vat || 0) - Number(product?.discount || 0)).toLocaleString('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
      'brand': product?.product_specifications?.brand ?? '',
      'weight': product?.product_specifications?.weight ?? '',
      'height': product?.product_specifications?.height ?? '',
      'length': product?.product_specifications?.length ?? '',
      'width': product?.product_specifications?.width ?? ''
    }
    PostImageBase64({ token: token, template_id: template?.id, code_type: settings.code_to_print, data: productData })
      .then(response => {
        const dataURL = `data:image/png;base64,${response?.data}`
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = `${product?.name ?? 'Unknown Product'}.png`;
        link.click();
      })
      .finally(() => {
        setIsDownloading(false);
      })
  };

  const formData = {
    print: {
      title: 'Print',
      type: 'button',
      onClick: onPrintClick
    },
    download: {
      title: 'Download',
      type: 'button',
      onClick: onDownloadClick,
      isDownloading: isDownloading
    },
    template_data: {
      setTemplate: setTemplate
    },
    isPrint: true,
    draggable: false,
    fields: [...productPrintFormFields, ...templateFormFields],
    field: {
      onChange: onChange
    },
    eans: {
      label: 'eans',
      options: [],
      selected: selectedEan,
      onChange: onEanChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    data: template,
    printer: printer
  };

  const printCard = {
    size: 12,
    title: "Product print ",
    data: <ProductPrintForm {...formData} />
  };

  return (
    <>
      <BasicCard {...printCard} />
    </>
  )

};
export default ProductPrint;