import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import NarrowcastingForm from "../../components/narrowcastingViewForm";
import { NarrowcastingViewFormFields } from "./narrowcastingViewFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostView from "../../actablueAPI/narrowcasting/PostView";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { contentTypeChoices, objectFitChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import useHandleError from "../../customhooks/useHandleError";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from '../../redux/dataSlices/loadingSlice';

const NarrowcastingViewAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "View add"
  const [view, setView] = useState({ admin_id: admin_id, location_id: location_id })
  const [invalidFields, setInvalidFields] = useState([])

  const onChange = (event) => {
    let newView = HandleOnChange({ event: event, object: view })
    if (event.target.id === 'name' || event.target.id === 'seconds') {
      let tempInvalid = invalidFields
      if (tempInvalid.find(invalid => invalid.field === event.target.id)) {
        tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === event.target.id), 1)
      }
    }
    setView(newView)
  }

  const onContentTypeChange = (event) => {
    let newView = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: view, selectfield: 'content_type' })
    let tempInvalid = invalidFields
    if (tempInvalid.find(invalid => invalid.field === 'content_type')) {
      tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === 'content_type'), 1)
    }
    newView = {name: newView?.name, admin_id: newView.admin_id, location_id: newView.location_id, content_type: newView.content_type}
    if (view.object_fit) {
      newView['object_fit'] = view.object_fit
    }
    setView(newView)
  }

  const onObjectFitChange = (event) => {
    let newView = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: view, selectfield: 'object_fit' })
    let tempInvalid = invalidFields
    if (tempInvalid.find(invalid => invalid.field === 'object_fit')) {
      tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === 'object_fit'), 1)
    }
    setView(newView)
  }

  const manualUrlInvalid = (field, bool) => {
    let tempInvalid = [...invalidFields]
    if (bool){
      if (!tempInvalid.find(invalid => invalid.field === field.id)) {
        tempInvalid.push({field: field.id, name: field.name, tab: 'main'})
      }
    } else if (tempInvalid.find(invalid => invalid.field === field.id)) {
      tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === field.id), 1)
    }
    setInvalidFields(tempInvalid)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let [invalid] = validateFormObject({ object: view, fields: formData?.fields, switchTab: true, dispatch: dispatch })
    switch (view.content_type) {
      case 'Video':
        if (invalidFields.find(invalid => invalid.field === 'video')) {
          invalid.push(invalidFields.find(invalid => invalid.field === 'video'))
        }
        break;
      case 'Image':
        if (invalidFields.find(invalid => invalid.field === 'image')) {
          invalid.push(invalidFields.find(invalid => invalid.field === 'image'))
        }
        break;
      default: //do nothing
        break;
    }
    if(invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    PostView({ token: token, admin_id: admin_id, view: view })
      .then(() => {
        navigate("/narrowcastingviews");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    manualUrlInvalid: manualUrlInvalid,
    invalidFields: invalidFields,
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingViewFormFields(),
    field: {
      onChange: onChange
    },
    data: view,
    content_type: {
      label: 'content_type',
      options: contentTypeChoices,
      onChange: onContentTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    object_fit: {
      label: 'object_fit',
      options: objectFitChoices,
      onChange: onObjectFitChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingViewAdd;

