import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

export default function NutrientsEditTable(props) {
  const { t: translate } = useTranslation();
  let rows = []
  let selected = props.nutrients?.selected ?? []

  if (props.data.hasOwnProperty('nutrients')) {
    props.data.nutrients.forEach(element => {
      rows.push(
        <tr key={element.id}>
          <td><Form.Check onChange={props?.nutrients?.check?.onChange} id={element.id} /></td>
          <td>
            <Form.Control prefix={element.id}
              type='text'
              name='name'
              placeholder='name'
              value={element.name}
              onChange={props?.nutrients?.onChange}
            />
          </td>
          <td>
            <Form.Control prefix={element.id}
              type='number'
              name='value'
              placeholder='value'
              value={element.value}
              onChange={props?.nutrients?.onChange}
              step='.01'
            />
          </td>
          <td>
            <Form.Control prefix={element.id}
              type='number'
              name='value_per_serving'
              placeholder='value_per_serving'
              value={element.value_per_serving}
              onChange={props?.nutrients?.onChange}
              step='.01'
            />
          </td>
          <td>
            <Form.Control prefix={element.id}
              type='number'
              name='guide_line_daily_amount'
              placeholder='guide_line_daily_amount'
              value={element.guide_line_daily_amount}
              onChange={props?.nutrients?.onChange}
              step='.01'
            />
          </td>
          <td>
            <Form.Control prefix={element.id}
              type='text'
              name='typmeasurement_precisione'
              placeholder='measurement_precision'
              value={element.measurement_precision}
              onChange={props?.nutrients?.onChange}
            />
          </td>
          <td>
            <Form.Control prefix={element.id}
              type='text'
              name='measurement_unit'
              placeholder='measurement_unit'
              value={element.measurement_unit}
              onChange={props?.nutrients?.onChange}
            />
          </td>
        </tr>
      )
    });
  }

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th></th>
            <th>{translate('Name')}</th>
            <th>{translate('Value')}</th>
            <th>{translate('Value per serving')}</th>
            <th>{translate('Guideline daily amount')}</th>
            <th>{translate('Measurement precision')}</th>
            <th>{translate('Measurement unit')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>

        <Button variant="primary" className='mt-3' type={props.nutrients?.add?.type} onClick={props.nutrients?.add?.onClick} name={props.nutrients?.add?.name}>
          {translate(props.nutrients?.add?.title)}
        </Button>
        <Button variant="primary" className='mt-3' type={props.nutrients?.remove?.type} onClick={props.nutrients?.remove?.onClick} name={props.nutrients?.remove?.name} disabled={selected.length === 0 ? true : false}>
          {translate(props.nutrients?.remove?.title)}
        </Button>
      </Table>
    </>
  );
}