import { useRef } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment"
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation, getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getAllowedCurrency, getDrawersAll, getHashDrawersAll, setAllowedCurrency, setDrawersAll } from "../../redux/dataSlices/cashcountSlice";
import { getEmployeesAll, getHashEmployeesUidAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import BasicCard from "../../components/card"
import CountForm from "../../components/countForm";
import HandleOnChangeCurrencyCount from "../../helpers/handleOnChangeCurrencyCount";
import GetAllDrawers from "../../helpers/getAllDrawers";

import { CountFormFields, CountFormFields2 } from "./countFormFields";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChange from "../../helpers/handleOnChange";
import PostCashCountCount from "../../actablueAPI/cashcount/PostCashCountCount";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import GetCashCountCurrency from "../../actablueAPI/cashcount/GetCashCountCurrency";
import useHandleError from "../../customhooks/useHandleError";
import { getDevicesAll, getHashDevicesAll, getHashDevicesUidAll, setDevicesAll } from "../../redux/dataSlices/deviceSlice";
import GetDailyStatusOperatorReportRange from "../../actablueAPI/reporting/GetDailyStatusOperatorReportRange";
import GetDailyStatusUserReportRange from "../../actablueAPI/reporting/GetDailyStatusUserReportRange";
import { CalculateReportingTotals } from "../../helpers/cashCountUtils";
import GetDevicesSmartpos from "../../actablueAPI/devices/GetDevicesSmartpos";

const CountAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const location = useSelector(getSelectedLocation);
  const allowedCurrency = useSelector(getAllowedCurrency);
  const drawers = useSelector(getDrawersAll);
  const hash_drawers_all = useSelector(getHashDrawersAll);
  const employees = useSelector(getEmployeesAll);
  const hashEmployeesUidAll = useSelector(getHashEmployeesUidAll);
  const devicesAll = useSelector(getDevicesAll);
  const hashDevicesAll = useSelector(getHashDevicesAll);
  const hashDevicesUidAll = useSelector(getHashDevicesUidAll);
  const [controller, setController] = useState();
  const abortController = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState({ admin_id: admin_id, location_id: location_id })
  const [reportingData, setReportingData] = useState({});
  const [selectedDrawer, setSelectedDrawer] = useState(null);
  const [reportingLoading, setReportingLoading] = useState(false);
  const [isFirstCount, setIsFirstCount] = useState(false);
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newCount = HandleOnChangeCurrencyCount({ event: event, object: count, hash_drawers_all: hash_drawers_all })
    newCount.skimming = ((Number(newCount?.counted?.total || newCount?.counted_total || 0) - Number(newCount?.next_day_amount || 0)).toFixed(2)) || "0";
    newCount.counted_total = null;
    setCount(newCount)
  }

  const onChangeSkimming = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.next_day_amount = ((Number(newCount?.counted?.total || newCount?.counted_total || 0) - Number(newCount?.skimming || 0)).toFixed(2)) || "0";
    setCount(newCount)
  }

  const onChangeNextAmount = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    if (!isFirstCount) newCount.skimming = ((Number(newCount?.counted?.total || newCount?.counted_total || 0) - Number(newCount?.next_day_amount || 0)).toFixed(2)) || "0";
    setCount(newCount)
  }

  const onChangeCountedTotal = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.counted = null;
    newCount.skimming = ((Number(newCount?.counted_total || 0) - Number(newCount?.next_day_amount || 0)).toFixed(2)) || "0";
    if (!isFirstCount) newCount.discrepancy = Math.abs(Number(reportingData?.netto_cash || 0) + Number(hash_drawers_all?.[newCount.drawer_id]?.previous_count?.next_day_amount || 0) - newCount?.counted_total).toFixed(2) || "0";
    setCount(newCount)
  }

  const onChangeEmployee = (event) => {
    stopAxios();
    let newCount = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: count, selectfield: 'employee_id' })
    let newSelectedDrawer = JSON.parse(JSON.stringify(selectedDrawer));
    newSelectedDrawer.device_id = newCount?.employee_id || null;
    setCount(newCount);
    setSelectedDrawer(newSelectedDrawer);
    getData(newSelectedDrawer, newCount);
  }

  const onChangeDevice = (event) => {
    stopAxios();
    let newCount = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: count, selectfield: 'device_id' })
    let newSelectedDrawer = JSON.parse(JSON.stringify(selectedDrawer));
    newCount.device_id = hashDevicesAll?.[newCount.device_id]?.uid || null;
    newSelectedDrawer.device_id = hashDevicesUidAll?.[newCount?.device_id]?.uid || null;
    setCount(newCount);
    setSelectedDrawer(newSelectedDrawer);
    getData(newSelectedDrawer, newCount);
  }

  const onChangeEftTotal = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.eft_discrepancy = (Math.abs(Number(reportingData?.eft || 0) - Number(newCount?.eft_total || 0)).toFixed(2)) || "0";
    setCount(newCount)
  }

  const onChangeOnAccTotal = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.on_account_discrepancy = (Math.abs(Number(reportingData?.on_account || 0) - Number(newCount?.on_account_total || 0)).toFixed(2)) || "0";
    setCount(newCount)
  }

  const onChangeDrawer = (event) => {
    stopAxios();
    let newCount = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: count, selectfield: 'drawer_id' })
    const drawer = hash_drawers_all?.[newCount?.drawer_id] || null;
    if (drawer) {
      newCount.employee_id = drawer?.employee_id || null
      newCount.device_id = drawer?.device_id || null
    }
    setSelectedDrawer(drawer);
    setCount(newCount);
    getData(drawer, newCount);
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    dispatch(setLoading(true));

    if (!count.reports) count.reports = []

    PostCashCountCount({ token: token, data: count })
      .then(() => {
        navigate("/cashcount");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: CountFormFields(),
    fields2: CountFormFields2(),
    field: {
      onChange: onChange
    },
    next_day_amount: {
      onChange: onChangeNextAmount
    },
    skimming: {
      onChange: onChangeSkimming
    },
    eft_total: {
      onChange: onChangeEftTotal
    },
    on_account_total: {
      onChange: onChangeOnAccTotal
    },
    counted_total: {
      onChange: onChangeCountedTotal
    },
    data: count,
    reportingData: reportingData,
    selectedDrawer: selectedDrawer,
    reportingLoading: reportingLoading,
    employees: employees,
    drawers: drawers,
    allowedCurrency: allowedCurrency,
    employee_id: {
      name: 'employee_id',
      options: employees,
      selected: [],
      onChange: onChangeEmployee,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    device_id: {
      name: 'device_id',
      options: devicesAll,
      selected: [],
      onChange: onChangeDevice,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    drawer_id: {
      name: 'drawer_id',
      options: drawers,
      selected: [],
      onChange: onChangeDrawer,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Count add",
    data: <CountForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Count add"))
    // eslint-disable-next-line
  }, []);

  const stopAxios = async () => {
    if (controller) {
      controller.abort();
    }
  };

  const getData = async (drawer, count) => {
    if (!drawer) return;
    setReportingLoading(true);
    setReportingData(null);

    let newCount = JSON.parse(JSON.stringify(count));

    newCount.reports = [];

    if (!drawer.previous_count) {
      setReportingLoading(false);
      setIsFirstCount(true);
      recalculateCount(drawer, newCount);
      return;
    }

    let startDate = moment();

    if (drawer.previous_count && moment(drawer.previous_count.creation).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      startDate = moment(drawer.previous_count.creation).add(1, 'day');
    }

    if (drawer.previous_count && startDate.format('YYYY-MM-DD') !== moment().add(1, 'day').format('YYYY-MM-DD')) {
      let newController = new AbortController();
      setController(newController);
      abortController.current = newController?.signal;
      switch (true) {
        case drawer?.count_as.includes("Employee"):
          await GetDailyStatusOperatorReportRange({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
            location: location,
            signal: abortController?.current
          })
            .then(async response => {
              const totals = await CalculateReportingTotals(response.data, startDate.format('YYYY-MM-DD'), moment().add(1, 'day').format('YYYY-MM-DD'));
              setReportingData(totals?.[hashEmployeesUidAll?.[newCount?.employee_id]?.operator_id]);
              if (totals?.[hashEmployeesUidAll?.[newCount?.employee_id]?.operator_id]) {
                newCount.reports = [totals?.[hashEmployeesUidAll?.[newCount?.employee_id]?.operator_id]];
              } else {
                newCount.reports = null;
              }
              recalculateCount(drawer, newCount);
            })
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                setReportingLoading(true);
                setReportingData(null);
                HandleError({ error: error?.response?.data, showPopup: true, persist: true });
                setReportingLoading(false);
              }
            })
          break;
        case drawer?.count_as.includes("Device"):
          await GetDailyStatusUserReportRange({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
            location: location,
            signal: abortController?.current
          })
            .then(async response => {
              const totals = await CalculateReportingTotals(response.data, startDate.format('YYYY-MM-DD'), moment().add(1, 'day').format('YYYY-MM-DD'));
              setReportingData(totals?.[newCount?.device_id]);
              if (totals?.[newCount?.device_id]) {
                newCount.reports = [totals?.[newCount?.device_id]];
              } else {
                newCount.reports = null;
              }
              recalculateCount(drawer, newCount);
            })
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                setReportingLoading(true);
                setReportingData(null);
                HandleError({ error: error?.response?.data, showPopup: true, persist: true });
                setReportingLoading(false);
              }
            })
          break;
        default:
          break;

      }
    }
  };

  const recalculateCount = (drawer, newCount) => {
    let previous_day_amount = 0;

    if (hash_drawers_all?.[newCount?.drawer_id]?.previous_count) {
      previous_day_amount = Number(hash_drawers_all[newCount.drawer_id].previous_count.next_day_amount || 0);
    }

    let reporting_total = 0.00;
    let reporting_eft_total = 0.00;
    let reporting_on_account_total = 0.00;

    let count_total = Number(newCount?.counted?.total || newCount?.counted_total || 0.00);
    let eft_total = Number(newCount?.eft_total || 0.00);
    let on_account_total = Number(newCount?.on_account_total || 0.00);

    for (let report in newCount?.reports) {
      const cash = Number(newCount?.reports?.[report]?.cash || 0);
      const exchange = Number(newCount?.reports?.[report]?.exchange || 0);
      const eft = Number(newCount?.reports?.[report]?.eft || 0);
      const on_account = Number(newCount?.reports?.[report]?.on_account || 0);

      reporting_total += cash - exchange;
      reporting_eft_total += eft;
      reporting_on_account_total += on_account;
    }
    reporting_total = reporting_total.toFixed(2);
    reporting_eft_total = reporting_eft_total.toFixed(2);
    reporting_on_account_total = reporting_on_account_total.toFixed(2);

    newCount.discrepancy = Math.abs(Number(reporting_total) + Number(previous_day_amount) - count_total).toFixed(2) || "0";
    newCount.eft_discrepancy = Math.abs(Number(reporting_eft_total) - eft_total).toFixed(2) || "0";
    newCount.on_account_discrepancy = Math.abs(Number(reporting_on_account_total) - on_account_total).toFixed(2) || "0";
    newCount.next_day_amount = Number(drawer?.default_amount || 0) || "0";

    if (newCount?.counted?.total || newCount?.counted_total) {
      newCount.skimming = (Number(newCount?.counted.total || newCount?.counted_total || 0) - Number(drawer?.default_amount || 0)).toFixed(2) || "0";
    }

    setReportingLoading(false)
    setCount(newCount)
  }

  const getApiData = async () => {
    await axios.all([
      GetCashCountCurrency({ token: token, admin_id: admin_id, location_id: location_id }),
      GetEmployees({ token: token, admin_id: admin_id }),
      GetAllDrawers({ token: token, admin_id: admin_id, location_id: location_id, page: 1, size: 25 }),
      GetDevicesSmartpos({ token: token, admin_id: admin_id })
    ])
      .then(axios.spread((currencyData, employeeData, drawerData, devicesData) => {
        dispatch(setAllowedCurrency(currencyData.data))
        dispatch(setEmployeesAll(employeeData.data))
        dispatch(setDrawersAll(drawerData))
        dispatch(setDevicesAll(devicesData.data))
        dispatch(setLoading(false))
      }))
      .catch(error => {
        HandleError({ error: error })
        navigate("/cashcount");
      })
  }

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(setDrawersAll(null));

    getApiData();

    // eslint-disable-next-line
  }, []);

  if (!hash_drawers_all || Object.keys(hash_drawers_all).length === 0) navigate('/cashcount')

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default CountAdd;

