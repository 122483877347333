import { useSelector } from 'react-redux';
import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getInvoice } from '../redux/dataSlices/invoiceSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import InvoicelineTable from '../components/invoicelineTable';
import './css/modal.css'

import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';


import VatlineTable from './vatlineTable';

export default function InvoiceModal(props) {
  const object = useSelector(getInvoice);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="lg"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='order-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className='order-modal-head'>
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Invoice: {object.number}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <h5>STATUS: </h5>
                      <p>{translate(object.status)}</p>
                    </div>
                  </div>
                </div>
                <br></br>
                <h4>{translate("Invoice total")}</h4>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <h6>{Number(object.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>Incl. BTW</p>
                    </div>
                    <div className="col">
                      <h6>{Number(object.payment_amount_exclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>Excl. BTW</p>
                    </div>
                    <div className="col">
                      <h6>{Number(object.payment_amount_inclusive_vat - object.payment_amount_exclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>BTW</p>
                    </div>
                  </div>
                </div>
                <br></br>
                <h4>{translate("Invoice details")}</h4>
                <InvoicelineTable invoice={object} />
                {object.invoice_lines && <h5>{translate('VAT')}:</h5>}
                {object.invoice_lines && <VatlineTable {...object} />}
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>

                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.modal.exportPdf.onClick(object)}>{translate(props?.modal?.exportPdf?.tag)}</Button>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}