import { Button, Col, Form, Table } from 'react-bootstrap'
import AdviceButtons from '../portal/stock/advicebuttons';
import WarehousePurchaseAdviceModal from './warehousePurchaseAdviceModal';
import { useTranslation } from 'react-i18next';
import PaginationAndSizeComponent from './paginationAndSize';
import { getPurchaseAdviceTableBarcodeVisible } from '../redux/dataSlices/warehouseSlice';
import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';
import { getSupplierRelationsAll } from '../redux/dataSlices/relationSlice';

export default function WarehouseAdviceTable(adviceList) {
  const list = adviceList.content
  const buttons = AdviceButtons.call()
  const suppliers = useSelector(getSupplierRelationsAll);
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const barcodeVisible = useSelector(getPurchaseAdviceTableBarcodeVisible);
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let pages = adviceList?.total_pages
  let page = adviceList?.number + 1

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  let adviceRows = []
  if (list) {
    for (let product of Object.values(list)) {
      const perUnitAdvice = Math.ceil((product.purchase_advice / (product.packaging ?? 1)))
      adviceRows.push(
        <tr className='category-row' key={product.id}>
          {
            barcodeVisible
            &&
            <td align={barcodeVisible ? 'center' : 'left'}>
              {barcodeVisible &&
                <Barcode
                  value={product?.sku_code}
                  height={75}
                />
              }
            </td>
          }
          {
            barcodeVisible
            &&
            <td align={barcodeVisible ? 'center' : 'left'}>
              <div className='ean-container'>
                <ul>
                  {product?.ean_code?.map((ean) => (
                    <li>
                      <Barcode
                        value={ean?.ean_code}
                        height={75}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </td>
          }
          <td>{product.name ?? "N/A"}</td>
          <td>
            <Col sm="10">
              <Form.Control
                type={'number'}
                name={'minimum_stock'}
                id={'minimum_stock'}
                value={product?.minimum_stock === null ? "" : product?.minimum_stock}
                onChange={(event) => { buttons?.onChange(event, product) }}
                onWheel={numberInputOnWheelPreventChange}
              />
            </Col>
          </td>
          <td>{product.total_quantity ?? "N/A"}</td>
          <td>{product.purchase_advice ?? "N/A"}</td>
          <td>{product.packaging ?? "1"}</td>
          <td>{perUnitAdvice ?? "N/A"}</td>
          {!barcodeVisible &&
            <td>
              {(product.suppliers && product.suppliers.length > 0) ?
                <Table width="100%" key={`${product?.id}-suppliers-table`}>
                  <thead>
                    <tr>
                      <th width="10%">{translate('Supplier')}</th>
                      <th width="10%">{translate('Article Code')}</th>
                      <th className="text-end" width="10%">{translate('Price')}</th>
                      <th className="text-end" width="10%">{translate('Total')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.suppliers.map((supplier, index) => (
                      <tr className='category-row' key={`${supplier?.id}-${index}`}>
                        <td>
                          {suppliers?.find(suppl => suppl?.id === supplier?.relation_id)?.name}
                        </td>
                        <td>
                          {supplier?.supplier_artikel_code}
                        </td>
                        <td align="right">{Number(supplier.supplier_price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                        <td align="right">
                          {Number(supplier.supplier_price * (perUnitAdvice * product?.packaging)).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
                :
                translate("No suppliers found.")
              }
            </td>
          }
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(product) }}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(product) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead key='header'>
          <tr>
            {barcodeVisible &&
              <>
                <th>{translate('SKU Code')}</th>
                <th>{translate('EAN codes')}</th>
              </>
            }
            <th>{translate('Product')}</th>
            <th>{translate('Minimum stock')}</th>
            {/* <th>{translate('Maximum stock')}</th> */}
            <th>{translate('Stock')}</th>
            <th>{translate('Purchase advice')}</th>
            <th>{translate('Order unit')}</th>
            <th width="5%">{translate('Purchase advice per unit')}</th>
            {!barcodeVisible && <th>{translate('Suppliers')}</th>}
            <th width="15%">{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {adviceRows}
        </tbody>
        <WarehousePurchaseAdviceModal modal={modalview.modal} />
      </Table>
    </>
  );
}