const NarrowcastingScheduleDayValidator = (object, field) => {
  if (object.hasOwnProperty('day') && object.day.length > 0) return true
  return false
}


export const NarrowcastingScheduleFormFields = () => {

  const narrowcastingScheduleFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'text', name: `Description`, id: 'description', required: false },
    { type: 'multiselect', name: `Day`, id: 'day', formdatafield: 'day', required: true, validator: NarrowcastingScheduleDayValidator },
    { type: 'select', name: `Playlist`, id: 0, formdatafield: 'playlists' }
  ]

  return narrowcastingScheduleFormFields
}