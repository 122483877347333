import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getNarrowcastingSchedulesModalShow, getSchedulePageSize, setNarrowcastingSchedulesModalShow, setSchedule, setSchedulePageSize, setSchedulePagination, setSchedules } from "../../redux/dataSlices/narrowcastingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { confirmAlert } from 'react-confirm-alert';
import DeleteSchedule from "../../actablueAPI/narrowcasting/DeleteSchedule";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import GetSchedules from "../../actablueAPI/narrowcasting/GetSchedules";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from "react-i18next";

export default function ScheduleButtons() {
  const token = useSelector(getToken)
  const { t: translate } = useTranslation();
  const modalShow = useSelector(getNarrowcastingSchedulesModalShow)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const pageSize = useSelector(getSchedulePageSize)
  const HandleError = useHandleError();

  function onViewClick(schedule) {
    dispatch(setSchedule(schedule));
    dispatch(setNarrowcastingSchedulesModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setNarrowcastingSchedulesModalShow(false));
  };

  const onclickEdit = (schedule) => {
    dispatch(setSchedule(schedule));
    navigate("/narrowcasting/schedule/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setSchedulePagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setSchedulePageSize(event.value))
  }

  const onclickAdd = () => {
    navigate("/narrowcasting/schedule/add");
  };

  function onclickDelete(schedule) {
    confirmAlert({
      title: translate('Confirm to remove'),
      message: translate("remove_confirm", { object: `"${schedule.name}"` }),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            dispatch(setLoading(true))
            DeleteSchedule({ token: token, scheduleId: schedule.id, admin_id: admin_id, location_id: location_id })
              .then(() => {
                GetSchedules({ token: token, admin_id: admin_id, location_id: location_id, page: 1, size: pageSize })
                  .then(response => {
                    dispatch(setSchedules(response.data))
                  })
                  .catch(error => {
                    HandleError({ error: error })
                  })
              })
              .catch(error => {
                HandleError({ error: error })
              })
              .finally(() => {
                dispatch(setLoading(false))
              })
          }
        },
        {
          label: translate('No'),
        }
      ]
    });
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },
    add: {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    },
    edit: {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    },
    del: {
      tag: 'Delete',
      value: 'del',
      onClick: onclickDelete
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.schedules >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
    buttons.delete = {
      tag: 'Delete',
      value: 'delete',
      onClick: onclickDelete
    }
  }
  if (scopes.schedules >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

