import React from "react";
import { Page, Document, Text, View } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesPurchaseAdvicePDF"
import { setProgressModalShow, setTaskData } from "../redux/dataSlices/analyseSlice";

const PurchaseAdvice = ({ adviceList, relation_id, hashRelationsAll, dispatch }) => {
  const dateNow = new Date();

  const headers = [
    {
      text: 'Name',
      style: styles.tableHeader
    },
    {
      text: 'Minimum stock',
      style: styles.tableHeaderCentered
    },
    // {
    //   text: 'Maximum stock',
    //   style: styles.tableHeaderCentered
    // },
    {
      text: 'Stock',
      style: styles.tableHeaderCentered
    },
    {
      text: 'Purchase advice',
      style: styles.tableHeaderCentered
    },
    {
      text: 'Order unit',
      style: styles.tableHeaderCentered
    },
    {
      text: 'Purchase Advice Per Unit',
      style: styles.tableHeaderCentered
    }
  ];

  const supplierHeaders = [
    {
      text: 'Name',
      style: styles.tableHeader
    },
    {
      text: 'Article Code',
      style: styles.tableHeader
    },
    {
      text: 'Price',
      style: styles.tableHeaderPrice
    },
    {
      text: 'Total',
      style: styles.tableHeaderPrice
    },
    {
      text: 'Minimum stock',
      style: styles.tableHeaderCentered
    },
    // {
    //   text: 'Maximum stock',
    //   style: styles.tableHeaderCentered
    // },
    {
      text: 'Stock',
      style: styles.tableHeaderCentered
    },
    {
      text: 'Purchase advice',
      style: styles.tableHeaderCentered
    },
    {
      text: 'Order unit',
      style: styles.tableHeaderCentered
    },
    {
      text: 'Purchase Advice Per Unit',
      style: styles.tableHeaderCentered
    }
  ];

  let data = {};
  let current = 1;

  if (!relation_id) {
    const supplierIds = [];
    data["None"] = { headers: headers, rows: [] };

    adviceList?.forEach(product => {
      const perUnitAdvice = Math.ceil(((!isNaN(product?.purchase_advice ?? 1) ? (product?.purchase_advice ?? 1) : 1) / (!isNaN(product?.packaging ?? 1) ? (product?.packaging ?? 1) : 1)));
      let task_data = {
        current: current,
        total: adviceList?.length ?? 0,
        progress: current === 0 ? 0 : current / adviceList?.length
      };

      dispatch(setTaskData(task_data))
      if (product?.suppliers?.length > 0) {
        product.suppliers?.forEach((supplier) => {
          if (!supplierIds.includes(supplier.relation_id)) {
            supplierIds.push(supplier.relation_id);
            data[supplier?.relation_id] = { headers: supplierHeaders, rows: [] };
          }
          data[supplier?.relation_id]["rows"].push(
            [
              {
                value: product.name,
                style: styles.tableCell
              },
              {
                value: supplier?.supplier_artikel_code,
                style: styles.tableCell
              },
              {
                value: Number(supplier?.supplier_price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                style: styles.tableCellPrice
              },
              {
                value: Number((supplier?.supplier_price ?? 0) * (perUnitAdvice * (!isNaN(product?.packaging) ? product?.packaging : 1))).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                style: styles.tableCellPrice
              },
              {
                value: product.minimum_stock,
                style: styles.tableCellCentered
              },
              // {
              //   value: product.maximum_stock,
              //   style: styles.tableCellCentered
              // },
              {
                value: product.total_quantity,
                style: styles.tableCellCentered
              },
              {
                value: product.purchase_advice,
                style: styles.tableCellCentered
              },
              {
                value: product?.packaging ?? "1",
                style: styles.tableCellCentered
              },
              {
                value: perUnitAdvice,
                style: styles.tableCellCentered
              }
            ]
          )
        });
      } else {
        data["None"]["rows"].push(
          [
            {
              value: product.name,
              style: styles.tableCell
            },
            {
              value: product.minimum_stock,
              style: styles.tableCellCentered
            },
            // {
            //   value: product.maximum_stock,
            //   style: styles.tableCellCentered
            // },
            {
              value: product.total_quantity,
              style: styles.tableCellCentered
            },
            {
              value: product.purchase_advice,
              style: styles.tableCellCentered
            },
            {
              value: product?.packaging ?? "1",
              style: styles.tableCellCentered
            },
            {
              value: perUnitAdvice,
              style: styles.tableCellCentered
            }
          ]
        )
      }

      current++
    });
  } else {
    headers.splice(1, 0, { text: `${hashRelationsAll[relation_id]?.name} Article Code`, style: styles.tableHeader });
    headers.splice(1, 0, { text: `${hashRelationsAll[relation_id]?.name} Price`, style: styles.tableHeaderPrice });
    data[relation_id] = { headers: supplierHeaders, rows: [] };
    adviceList?.forEach(product => {
      const perUnitAdvice = Math.ceil(((!isNaN(product?.purchase_advice ?? 1) ? (product?.purchase_advice ?? 1) : 1) / (!isNaN(product?.packaging ?? 1) ? (product?.packaging ?? 1) : 1)));
      let task_data = {
        current: current,
        total: adviceList?.length ?? 0,
        progress: current === 0 ? 0 : current / adviceList?.length
      };

      dispatch(setTaskData(task_data))
      const supplierIndex = product?.suppliers?.findIndex(supplier => supplier?.relation_id === relation_id)
      const supplier = product.suppliers?.[supplierIndex];
      data[relation_id]["rows"].push(
        [
          {
            value: product.name,
            style: styles.tableCell
          },
          {
            value: supplier?.supplier_artikel_code,
            style: styles.tableCell
          },
          {
            value: Number(supplier?.supplier_price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            style: styles.tableCellPrice
          },
          {
            value: Number((supplier?.supplier_price ?? 0) * (perUnitAdvice * (!isNaN(product?.packaging) ? product?.packaging : 1))).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            style: styles.tableCellPrice
          },
          {
            value: product.minimum_stock,
            style: styles.tableCellCentered
          },
          // {
          //   value: product.maximum_stock,
          //   style: styles.tableCellCentered
          // },
          {
            value: product.total_quantity,
            style: styles.tableCellCentered
          },
          {
            value: product.purchase_advice,
            style: styles.tableCellCentered
          },
          {
            value: product?.packaging ?? "1",
            style: styles.tableCellCentered
          },
          {
            value: perUnitAdvice,
            style: styles.tableCellCentered
          }
        ]
      )
      current++
    })
  }

  dispatch(setProgressModalShow(false));

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape" key="purchase-advice-pdf">
        <View style={styles.header} key="header">
          {relation_id &&
            <View style={styles.locationNaw} key='relation-naw'>
              <Text key="name">{hashRelationsAll[relation_id]?.name}</Text>
              <Text key="street">{`${hashRelationsAll[relation_id]?.addresses?.[0]?.street ?? ""} ${hashRelationsAll[relation_id]?.addresses?.[0]?.street_number ?? ""}${hashRelationsAll[relation_id]?.addresses?.[0]?.street_number_additional ?? ""}`}</Text>
              <Text key="zip_code">{`${hashRelationsAll[relation_id]?.addresses?.[0]?.zip_code ?? ""} ${hashRelationsAll[relation_id]?.addresses?.[0]?.place ?? ""}`}</Text>
              <Text key="country">{hashRelationsAll[relation_id]?.addresses?.[0]?.country ?? ""}</Text>
              <Text key="phonenumber">{hashRelationsAll[relation_id]?.phone_numbers?.[0]?.number ?? ""}</Text>
              <Text key="email">{hashRelationsAll[relation_id]?.emails?.[0]?.email ?? ""}</Text>
            </View>
          }
          <View style={styles.relationNaw} key='date-generated'>
            <Text key="generated_on">Generated on: {dateNow.toLocaleString()}</Text>
          </View>
        </View>

        {Object.entries(data).map(([key, object]) => {
          return (
            <>
              <Text style={styles.invoiceTitle} key={`invoice-title-${key}`}>Purchase Advice {key !== "None" && hashRelationsAll[key]?.name}</Text>

              <View style={styles.table} key={`advice-table-${key}`}>
                <View style={styles.tableRow} key={`advice-table-row-${key}`}>
                  {object?.headers?.map((header) => (
                    <Text style={header.style} key={`${key}-${header.text}`}>{header.text}</Text>
                  ))}
                </View>

                {object?.rows?.map((row, index) => (
                  <View style={styles.tableRow} key={`table-row-${key}-${index}`}>
                    {row?.map((data) => (
                      <Text style={data.style} key={`${key}-${data.value || index}`}>{data.value}</Text>
                    ))}
                  </View>
                ))}
              </View>
            </>
          );
        })
        }

      </Page>
    </Document>
  );
}

export default PurchaseAdvice;