import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import PlanningCalendar from "../../components/planningCalendar";
import BasicCard from "../../components/card";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PlanningTable from "../../components/planningTable";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { GetAllPlanningObjects, GetAllPlannings, GetPlanningLocationAdminByID, GetPlanningObjects, GetPlannings } from "../../actablueAPI/planning/Planning";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import {
  getCalendarEnd,
  getCalendarLoading,
  getCalendarStart,
  getPlanningObjectPageSize,
  getPlanningObjectPagination,
  getPlanningObjects,
  getPlanningPageSize,
  getPlanningPagination,
  getPlannings,
  getRefresh,
  setPlanningLocation,
  setPlanningObjects,
  setPlannings, setCalendarLoading,
  setHashPlanningsFromPlanningObjects,
  setPlanningObjectsAll
} from "../../redux/dataSlices/planningSlice";
import PlanningButtons from "./planningbuttons";
import TableButton from "../../components/tableButton";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import { directionFilterChoices, orderByFilterChoices, orderByFilterChoicesObject, statusFilterChoices } from "../../selectfieldchoices/planningselect.mjs";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import PlanningObjectTable from "../../components/planningObjectTable";
import useHandleError from "../../customhooks/useHandleError";
import UnpaidOrderTable from "../../components/unpaidOrderTable";
import PostOrderSyncUnpaid from "../../actablueAPI/invoicepayments/PostOrderSyncUnpaid";
import { getOrders, setOrders } from "../../redux/dataSlices/orderSlice";
import axios from "axios";

const Planning = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const plannings = useSelector(getPlannings);
  const planning_objects = useSelector(getPlanningObjects);
  const refresh = useSelector(getRefresh);
  const [status, setStatus] = useState(null);
  const [orderBy, setOrderBy] = useState("planningTimestamp");
  const [planningObjectsOrderBy, setPlanningObjectsOrderBy] = useState("name");
  const [direction, setDirection] = useState("ascending");
  const [planningObjectsDirection, setPlanningObjectsDirection] = useState("ascending");
  const planningPagination = useSelector(getPlanningPagination);
  const planningPageSize = useSelector(getPlanningPageSize);
  const planningObjectPagination = useSelector(getPlanningObjectPagination);
  const planningObjectPageSize = useSelector(getPlanningObjectPageSize);
  const calendarStart = useSelector(getCalendarStart);
  const calendarEnd = useSelector(getCalendarEnd);
  const calendarLoading = useSelector(getCalendarLoading);
  const HandleError = useHandleError();
  const orders = useSelector(getOrders);
  const [cardTitle, setCardTitle] = useState("Calendar");
  const { t: translate } = useTranslation();

  const buttons = PlanningButtons.call();
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const planning_object_add = buttons.hasOwnProperty('planning_object_add') ? Object.assign({}, buttons.planning_object_add) : false
  const refreshButton = buttons.hasOwnProperty('refresh') ? Object.assign({}, buttons.refresh) : false

  useEffect(() => {
    dispatch(setNavBarTitle("Planning"))
    // eslint-disable-next-line
  }, [])

  useEffect(() => { // This one is for the "events" tab
    if (!location_id) return
    dispatch(setLoading(true))
    GetPlannings({ token: token, location_id: location_id, page: planningPagination, size: planningPageSize, orderBy: orderBy, direction: direction, status: status })
      .then(response => {
        dispatch(setPlannings(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, status, orderBy, direction, planningPagination, planningPageSize])

  useEffect(() => { // This one is for the "calendar" tab
    if (!location_id) return
    dispatch(setCalendarLoading(true))
    axios.all([
      GetAllPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id }),
      GetAllPlannings({ token: token, location_id: location_id, page: 0, size: 10, start: calendarStart, end: calendarEnd })
    ])
      .then(axios.spread((planningObjectsResponse, planningsResponse) => {
        dispatch(setPlanningObjectsAll(planningObjectsResponse))
        let hashPlanningObjectsPlannings = {}
        planningsResponse.forEach(planning => {
          planning?.planning_objects.forEach(planningObject => {
            if (!hashPlanningObjectsPlannings[planningObject.planning_object_id]) hashPlanningObjectsPlannings[planningObject.planning_object_id] = {}
            hashPlanningObjectsPlannings[planningObject.planning_object_id][planning?.id] = planning
          })
        })
        dispatch(setHashPlanningsFromPlanningObjects(hashPlanningObjectsPlannings));
      }))
      .catch(error => {
        HandleError({ error: error })
      })
      .finally(() => {
        dispatch(setCalendarLoading(false));
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id, calendarStart, calendarEnd])

  useEffect(() => {
    if (!location_id) return
    PostOrderSyncUnpaid({ token: token, admin_id: admin_id, location_id: location_id, date: 0 })
      .then(response => {
        const filteredOrders = response?.data?.filter(order => order.status === 'OPEN');
        dispatch(setOrders(filteredOrders));
      })
      .catch(error => {
        HandleError({ error: error });
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id])

  useEffect(() => {
    if (!location_id) return
    dispatch(setLoading(true))
    GetPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id, page: planningObjectPagination, size: planningObjectPageSize, orderBy: planningObjectsOrderBy, direction: planningObjectsDirection })
      .then(response => {
        dispatch(setPlanningObjects(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, status, planningObjectsOrderBy, planningObjectsDirection, planningObjectPageSize, planningObjectPagination])

  useEffect(() => {
    if (!location_id) return
    GetPlanningLocationAdminByID({ token: token, id: location_id })
      .then(response => {
        dispatch(setPlanningLocation(response.data))
      })
      .catch(error => {
        dispatch(setPlanningLocation(null))
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id])

  useEffect(() => {
    if (calendarLoading) setCardTitle("Calendar (Loading data...)")
    if (!calendarLoading) setCardTitle("Calendar")
  }, [calendarLoading])

  const onStatusChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setStatus(value)
  }

  const onOrderByChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setOrderBy(value)
  }

  const onDirectionChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setDirection(value)
  }

  const onOrderByChangeObject = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setPlanningObjectsOrderBy(value)
  }

  const onDirectionChangeObject = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setPlanningObjectsDirection(value)
  }

  const calendarCard = {
    size: 12,
    title: cardTitle,
    data: location_id ? <PlanningCalendar orderBy={orderBy} direction={direction} status={status} /> : translate('Please select a location or administration'),
  };

  const unpaidOrderCard = {
    size: 12,
    title: 'Unpaid Orders',
    data: location_id ? <UnpaidOrderTable orders={orders} /> : translate('Please select a location or administration'),
  };

  const eventsCard = {
    size: 12,
    title: 'Planning Events',
    data: location_id ? <PlanningTable plannings={plannings} /> : translate('Please select a location or administration'),
  };

  const planningObjectsCard = {
    size: 12,
    title: 'Planning Objects',
    data: location_id ? <PlanningObjectTable planning_objects={planning_objects} /> : translate('Please select a location or administration'),
  };

  const searchAndFilter = {
    md: 6,
    filters: [
      {
        label: 'Status',
        options: statusFilterChoices,
        onChange: onStatusChange,
        selected: status ? { 'value': statusFilterChoices.find(element => element.id === status)?.id, 'label': statusFilterChoices.find(element => element.id === status)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Order By',
        options: orderByFilterChoices,
        onChange: onOrderByChange,
        selected: orderBy ? { 'value': orderByFilterChoices.find(element => element.id === orderBy)?.id, 'label': orderByFilterChoices.find(element => element.id === orderBy)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Direction',
        options: directionFilterChoices,
        onChange: onDirectionChange,
        selected: direction ? { 'value': directionFilterChoices.find(element => element.id === direction)?.id, 'label': directionFilterChoices.find(element => element.id === direction)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  const searchAndFilterObject = {
    md: 6,
    filters: [
      {
        label: 'Order By',
        options: orderByFilterChoicesObject,
        onChange: onOrderByChangeObject,
        selected: planningObjectsOrderBy ? { 'value': orderByFilterChoicesObject.find(element => element.id === planningObjectsOrderBy)?.id, 'label': orderByFilterChoicesObject.find(element => element.id === planningObjectsOrderBy)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Direction',
        options: directionFilterChoices,
        onChange: onDirectionChangeObject,
        selected: planningObjectsDirection ? { 'value': directionFilterChoices.find(element => element.id === planningObjectsDirection)?.id, 'label': directionFilterChoices.find(element => element.id === planningObjectsDirection)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  return (
    <>
      <Col md={12}>
        <Tab.Container defaultActiveKey="calendar">
          <Row>
            <Col sm={6} className="mb-3">
              <Nav variant="pills">
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="calendar">{translate('Timeline')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="plannings">{translate('Plannings')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link eventKey="planningobjects">{translate('Planning Objects')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={6} className="mb-3">
              <Tab.Content>
                <Tab.Pane eventKey="calendar" style={{ float: 'right' }}>
                  {refreshButton && <TableButton {...refreshButton} />}
                </Tab.Pane>
                <Tab.Pane eventKey="plannings" style={{ float: 'right' }}>
                  <Row className="align-items-center">
                    <SearchAndMultiFilterSingleSelect {...searchAndFilter} />
                    <Col md={6}>
                      <TableButton {...add} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="planningobjects" style={{ float: 'right' }}>
                  <Row className="align-items-center">
                    <SearchAndMultiFilterSingleSelect {...searchAndFilterObject} />
                    <Col md={6}>
                      <TableButton {...planning_object_add} />
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="calendar">
                  <BasicCard {...calendarCard} />
                  <BasicCard {...unpaidOrderCard} />
                </Tab.Pane>
                <Tab.Pane eventKey="plannings">
                  <BasicCard {...eventsCard} />
                </Tab.Pane>
                <Tab.Pane eventKey="planningobjects">
                  <BasicCard {...planningObjectsCard} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </>
  )
};

export default Planning;