import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button, Table } from 'react-bootstrap';

import { getDevice } from '../redux/dataSlices/deviceSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { getHashAdministrationsAll } from "../redux/dataSlices/administrationSlice";
import { getHashLocationsAll } from "../redux/dataSlices/locationSlice";
import { useTranslation } from 'react-i18next';
import SmartPOSFormFields from '../portal/devices/smartposDeviceFormFields';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import './css/productTable.css';
import PricecheckerFormFields from '../portal/devices/pricecheckerDeviceFormFIelds';
import VectronFormFields from '../portal/devices/vectronDeviceFormFields';


export default function DeviceModal(props) {
  const object = useSelector(getDevice);
  const role = useSelector(getRole)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const smartpos_settings = SmartPOSFormFields().find(field => field.id === 'settings').fields;
  const vectron_settings = VectronFormFields().find(field => field.id === 'settings').fields;
  const pricechecker_settings = PricecheckerFormFields().find(field => field.id === 'settings').fields;
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  let settings = []

  if (object) {
    switch (true) {
      case object?.type === "VECTRON":
        for (const setting of vectron_settings) {
          if (setting?.type !== 'file') {
            settings.push(
              <tr className='product-row'>
                <td width="75%">{translate(setting?.name)}</td>
                <td width="25%">{object.settings[setting?.id] ?? "Not set"}</td>
              </tr>
            )
          }
        }
        break;
      case object?.type === "PRICECHECKER":
      case object?.settings?.hasOwnProperty("scannerType"):
        for (const setting of pricechecker_settings) {
          if (setting?.type !== 'file') {
            settings.push(
              <tr className='product-row'>
                <td width="75%">{translate(setting?.name)}</td>
                <td width="25%">{object.settings[setting?.id] ?? "Not set"}</td>
              </tr>
            )
          }
        }
        break;
      case object?.type === "SMARTPOS":
      default:
        for (const setting of smartpos_settings) {
          if (setting?.type !== 'file') {
            settings.push(
              <tr className='product-row'>
                <td width="75%">{translate(setting?.name)}</td>
                <td width="25%">{object.settings[setting?.id] ?? "Not set"}</td>
              </tr>
            )
          }
        }
        break;
    }

    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            {translate('Device ID')}: {object.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="settings">{translate('Settings')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>{translate("Device ID")}: {object.id}</p>
                      <p>{translate("Active")}: {translate(JSON.stringify(object.active))}</p>
                      <p>Type: {object.type}</p>
                    </div>
                    <div className="col">
                      <p>{translate("Administration")}: {hash_admins_all[object.administration_id]?.name}</p>
                      <p>{translate("Location")}: {hash_locations_all[object.location_id]?.name}</p>
                      <p>{translate("Serial Number")}: {object?.serial_number}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="settings">
                <div className="container">
                  <div className="row">
                    <Table hover width='100%'>
                      <thead>
                        <tr className='product-row'>
                          <th>
                            {translate('Setting')}
                          </th>
                          <th>
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {settings}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate("Administration")}: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>{translate("Location")}: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate("Created")}: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>{translate("Modified")}: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}