import { useSelector, useDispatch } from "react-redux";
import { getStockModalShow, getStockPagesize, getStockRefresh, getStocks, setStock, setStockModalShow, setStockPageSize, setStockPagination, setStockRefresh, setStocks, setWarehouseAdvice } from "../../redux/dataSlices/warehouseSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { StockExport } from "../../helpers/stockExport";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import GetWarehouseStockReset from "../../actablueAPI/warehouse/GetWarehouseStockReset";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import useHandleError from "../../customhooks/useHandleError";
import { useNavigate } from "react-router-dom";
import PutWarehouseProductById from "../../actablueAPI/warehouse/PutWarehouseProductById";
import { useRef, useState } from "react";
import HandleOnChange from "../../helpers/handleOnChange";
import PostWarehouseStockMutation from "../../actablueAPI/warehouse/PostWarehouseStockMutation";
import { getHashSupplierRelationsAll } from "../../redux/dataSlices/relationSlice";

export default function StockButtons() {
  const modalShow = useSelector(getStockModalShow);
  const pageSize = useSelector(getStockPagesize);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hashSuppliersAll = useSelector(getHashSupplierRelationsAll);
  const stocks = useSelector(getStocks);
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const refresh = useSelector(getStockRefresh);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [controller, setController] = useState();
  const signal = useRef();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();

  const toggleRefresh = () => {
    dispatch(setStockRefresh(!refresh))
  }

  function onViewClick(stockMutation) {
    dispatch(setStock(stockMutation));
    dispatch(setStockModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setStockModalShow(false));
  };

  const onPaginationClick = (event) => {
    dispatch(setStockPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setStockPageSize(event.value))
  };

  const onExportClick = () => {
    StockExport({ token: token, admin_id: admin_id, location_id: location_id, hashSupplierRelations: hashSuppliersAll, dispatch: dispatch })
  }

  function onclickEdit(product) {
    dispatch(setWarehouseAdvice(product));
    navigate("/purchaseadvice/edit");
  };

  const stopAxios = async () => {
    if (controller) {
      controller.abort();
    }
  };

  const getDifference = (oldValue, newValue) => {
    return newValue - oldValue
  };

  const recalculateStocks = (event, object) => {
    let newStocks = JSON.parse(JSON.stringify(stocks));
    let newProduct = stocks?.content?.find(product => product?.id === object?.id);
    let newProductIndex = stocks?.content?.findIndex(product => product?.id === object?.id);
    newProduct = HandleOnChange({ event: event, object: newProduct });
    if (newProduct?.minimum_stock < 0) return
    newProduct.purchase_advice = newProduct?.minimum_stock - newProduct?.total_quantity
    newStocks?.content?.splice(newProductIndex, 1, newProduct);
    dispatch(setStocks(newStocks))
    return newProduct
  }

  const onChangeTable = (event, object) => {
    stopAxios();
    const newProduct = recalculateStocks(event, object)

    let putProduct = {
      "id": newProduct?.id,
      "administration_id": newProduct?.administration_id,
      "location_id": newProduct?.location_id,
      "minimum_stock": newProduct?.minimum_stock ?? 0
    }

    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;

    PutWarehouseProductById({ token: token, id: putProduct.id, data: putProduct, signal: signal?.current })
      .then(() => { })
      .catch(error => {
        if (error?.code !== "ERR_CANCELED") HandleError({ error: error })
      })
  }

  const onChangeStockMutation = (event, object, rack) => {
    stopAxios();
    const rackIndex = object?.product_locations?.findIndex((location) => location?.id === rack?.id);
    const oldRack = object?.product_locations?.find((location) => location?.id === rack?.id);
    const oldQuantity = oldRack?.quantity
    const newQuantity = getDifference(oldQuantity, (isNaN(event?.target?.value) ? 0 : Number(event?.target?.value)));
    const newRack = { ...oldRack, quantity: (isNaN(event?.target?.value) ? 0 : Number(event?.target?.value)) }
    let newStocks = JSON.parse(JSON.stringify(stocks));
    let newProduct = stocks?.content?.find(product => product?.id === object?.id);
    let newProductIndex = stocks?.content?.findIndex(product => product?.id === object?.id);
    const newTotalQuantity = newProduct?.product_locations?.reduce((sum, item) => sum + item.quantity, 0);
    newProduct = {
      ...object,
      total_quantity: newTotalQuantity,
      purchase_advice: (newProduct?.minimum_stock - newTotalQuantity),
      product_locations: object.product_locations.map((item, index) =>
        index === rackIndex ? newRack : item
      ),
    }
    newStocks?.content?.splice(newProductIndex, 1, newProduct);
    dispatch(setStocks(newStocks))
    const newStockMutation = {
      "administration_id": newProduct?.administration_id,
      "location_id": newProduct?.location_id,
      "product_id": newProduct?.id,
      "quantity": newQuantity,
      "rack_id": rack?.rack_id,
      "date": new Date().toISOString().split('T')[0]
    }

    PostWarehouseStockMutation({ token: token, data: newStockMutation })
      .then(() => { HandleError({ error: "Stock mutation saved.", showPopup: true, variant: 'info', preventDuplicate: false }) })
      .catch(error => {
        if (error?.code !== "ERR_CANCELED") HandleError({ error: "Something went wrong when sending stock mutation", showPopup: true })
      })
  }

  const onResetToZeroClick = () => {
    confirmAlert({
      title: translate('confirm_alert_title_reset_to_zero'),
      message: translate(`confirm_alert_message_reset_to_zero`),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            GetWarehouseStockReset({ token: token, admin_id: admin_id, location_id: location_id })
              .then(() => {
                HandleError({ error: translate("reset_to_zero_started"), showPopup: true, variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
              })
              .catch(error => { HandleError({ error: error }) })
          }
        },
        {
          label: translate('No'),
        }
      ]
    })
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },
    onChange: onChangeTable,
    onChangeStock: onChangeStockMutation
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.stock >= 0) {
    buttons.refresh = {
      tag: "Refresh",
      value: 'refresh',
      onClick: toggleRefresh,
      className: 'add-button'
    }
  }
  if (scopes.stock >= 2) {
    buttons.resettozero = {
      tag: "reset_to_zero",
      value: 'resettozero',
      onClick: onResetToZeroClick,
      className: 'add-button'
    }
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.export = {
      tag: "Export",
      onClick: onExportClick,
      className: 'add-button'
    }
  }

  return buttons
}

