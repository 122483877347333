import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

const PricecheckerFormFields = () => {
  const role = useSelector(getRole)

  const settings = [
    { type: 'text', name: `Advertising URL`, id: 'advertisingUrl', prefix: 'settings' },
    { type: 'text', name: `Screen saver URL`, id: 'screensaverUrl', prefix: 'settings' },
    { type: 'number', name: `Page timeout duration`, id: 'pageTimeoutDuration', prefix: 'settings' },
    { type: 'select', name: `Scanner type`, id: 'scannerType', formdatafield: 'scannerType', prefix: 'settings' },
  ]

  let pricecheckerFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'uid', id: 'uid', readonly: true },

    // single option select fields  
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'hidden', name: 'Administration', id: 'administration_id', readonly: true },
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: 'Actablue device id', id: 'serial_number' },
    { type: 'checkbox', name: 'Active', id: 'active' },

    // single option object fields
    { type: 'object', name: 'Settings', id: 'settings', fields: settings }


    // multiple options select field
    // terminals
  ]

  if (role === 'super' || role === 'admin') {
    pricecheckerFormFields.splice(3, 1, // overwrite index 3 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
  }

  return pricecheckerFormFields
}

export default PricecheckerFormFields