import React, { useRef, useState } from "react";
import { useEffect } from "react";
import DatePickerSelectFieldRefreshButton from "../../components/datepicker-selectfield-refreshbutton";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id, getSelectedLocation } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import {
  setStartDate, setEndDate,
  getStartDate, getEndDate,
} from "../../redux/dataSlices/reportingDateSlice";
import { setCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { setProgressModalShow, getProgressModalShow, getProgressData } from "../../redux/dataSlices/reportingSlice";
import BasicCard from "../../components/card"
import HighlightsCard from "../../components/HighlightsCard";
import moment from "moment"
import HighlightTableReporting from "../../components/highlightTableReporting";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import GetUserList from "../../helpers/getUserList";
import { ReportSelectChoices } from "../../selectfieldchoices/reportselect";
import GetProductsPerMonth from "../../actablueAPI/reporting/getProductsPerMonth";
import StatusReportSingleDay from '../../helpers/statusReportSingleDay';
import StatusReportMulitpleDays from "../../helpers/statusReportMulitpleDays";
import ProductsReportMonth from "../../helpers/productsPerMonthReport";
import UsersSalesReportMultipleDays from "../../helpers/usersSalesReport";
import TransactionAnalyse from "../../helpers/transactionAnalyse";
import { usersSalesReportGraph } from "../../helpers/usersSalesReportGraph";
import { usersSalesReportMulitpleDaysHighlights } from "../../helpers/usersSalesReportMultipleDaysHighlights";
import AnalyseTransactionDescription from "../../helpers/analyseTransactionDescription";
import { transactionAnalyseHighlights } from "../../helpers/transactionAnalyseHighlights";
import { transactionAnalyseGraph } from "../../helpers/transactionAnalyseGraph";
import { productPerMonthReportHighlights } from "../../helpers/productPerMonthReportHighlight";
import { productPerMonthReportGraph } from "../../helpers/productPerMonthReportGraph";
import { reportAddPeriod } from "../../helpers/addPeriodToReport";
import GetStatusReportRange from "../../actablueAPI/reporting/GetStatusReportRange";
import { statusReportMultipleDaysHighlights } from "../../helpers/statusReportMultipleDaysHighlights";
import { statusReportMultipleDaysGraph } from "../../helpers/statusReportMultipleDaysGraph";
import GetSalesReportRange from "../../actablueAPI/reporting/GetSalesReportRange";
import SalesReportAccordionStyle from "../../helpers/salesReportAccordionStyle";
import { salesReportHighlights } from "../../helpers/salesReportHighlights";
import { salesReportGraph } from "../../helpers/salesReportGraph";
import GetUserReportRange from "../../actablueAPI/reporting/GetUserReportRange";
import GetOperatorReportRange from "../../actablueAPI/reporting/GetOperatorReportRange";
import { operatorsSalesReportHighlights } from "../../helpers/operatorsSalesReportHighlights";
import { operatorsSalesReportGraph } from "../../helpers/operatorsSalesReportGraph";
import OperatorsSalesReport from "../../helpers/operatorsSalesReport";
import GetStatusReportDay from "../../actablueAPI/reporting/getStatusReportDay";
import ProgressModal from "../../components/progressModal";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { statusReportGraphSingleDay } from "../../helpers/statusReportSingleDayGraph";
import { statusReportHighlightsSingleDay } from "../../helpers/statusReportSingleDayHighlight";
import GetMetaDataReportRange from "../../actablueAPI/reporting/GetMetaDataReportRange";
import MetaDataReport from "../../helpers/metaDataReport";
import { metaDataHighlights } from "../../helpers/metaDataHighlights";
import { metaDataGraph } from "../../helpers/metaDataGraph";
import useHandleError from "../../customhooks/useHandleError";
import GetAllTransactionsSearchV2Minimal from "../../actablueAPI/invoicepayments/GetAllTransactionsSearchV2Minimal";
import { useTranslation } from 'react-i18next';
import GetDailyStatusUserReportRange from "../../actablueAPI/reporting/GetDailyStatusUserReportRange";
import UsersStatusReport from "../../helpers/usersStatusReport";
import { usersStatusReportGraph } from "../../helpers/usersStatusReportGraph";
import { usersStatusReportHighlights } from "../../helpers/usersStatusReportHighlights";
import GetDailyStatusOperatorReportRange from "../../actablueAPI/reporting/GetDailyStatusOperatorReportRange";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import { setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import OperatorsStatusReport from "../../helpers/operatorStatusReport";
import { operatorsStatusReportGraph } from "../../helpers/operatorsStatusReportGraph";
import { operatorsStatusReportHighlights } from "../../helpers/operatorsStatusReportHighlights";

const Reporting = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const location = useSelector(getSelectedLocation);
  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);
  const progressModalShow = useSelector(getProgressModalShow);
  const progressData = useSelector(getProgressData);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation(); 

  const [refresh, setRefresh] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [highlightsdata, setHighlightsdata] = useState();
  const [graphCardData, setGraphCardData] = useState();
  const [reportCardData, setReportCardData] = useState();
  const [graphCardTitle, setGraphCardTitle] = useState('');
  const [singleDatePicker, setSingleDatePicker] = useState(false);
  const [controller, setController] = useState();
  const [progressEnabled, setProgressEnabled] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);
  const signal = useRef();

  const SwitchReport = async (reportType) => {
    dispatch(setLoading(true));
    let start = moment.unix(startDate).format('YYYY-MM-DD');
    let end = moment.unix(endDate).format('YYYY-MM-DD');
    let newController = new AbortController();
    let weeksDifference = moment.unix(endDate).diff(moment.unix(startDate), 'weeks');
    setController(newController);
    signal.current = newController?.signal;
    setProgressEnabled(false);
    setTotal(weeksDifference);

    switch (reportType) {
      case 'transactions':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        if (moment.unix(startDate).isSame(moment.unix(endDate), 'day')) {
          setGraphCardTitle('Revenue per hour')
          GetStatusReportDay({ token: token, admin_id: admin_id, location_id: location_id, startDate: startDate, location: location, signal: signal.current })
            .then(response => {
              setHighlightsdata(statusReportHighlightsSingleDay(response.data))
              setGraphCardData(statusReportGraphSingleDay(response.data, translate))
              setReportCardData(<StatusReportSingleDay {...response.data} />)
              dispatch(setLoading(false))
            })
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
            })
          break;
        }

        setGraphCardTitle('Revenue')
        let combinedTransactionResponse = { transactions: 0 }
        let transactionCancelled = false
        let transactionProgressCounter = 0
        start = moment(start)
        end = moment(end)
        setProgressEnabled(true)
        while (!transactionCancelled && start.isSameOrBefore(end)) {
          setCurrent(transactionProgressCounter);
          // Calculate the end date for this range
          let endDateRange = start.clone().add(6, 'days');
          if (endDateRange.isAfter(end)) {
            endDateRange = end.clone();
          }

          // Output the range
          await GetStatusReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: start.format('YYYY-MM-DD'), endDate: endDateRange.format('YYYY-MM-DD'), location: location, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(response => {
              let combinedObject = JSON.parse(JSON.stringify(combinedTransactionResponse));
              combinedObject.days = Object.assign({}, combinedTransactionResponse.days, response.data.days)
              combinedObject.transactions = combinedTransactionResponse.transactions + response.data.transactions
              combinedTransactionResponse = combinedObject;
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              transactionCancelled = true
            })

          // Move the start date to the beginning of the next range
          start = endDateRange.clone().add(1, 'day');
          transactionProgressCounter++
        }
        if (!transactionCancelled) {
          setHighlightsdata(statusReportMultipleDaysHighlights(combinedTransactionResponse, startDate, moment.unix(startDate).daysInMonth()))
          setGraphCardData(statusReportMultipleDaysGraph(combinedTransactionResponse, startDate, endDate))
          setReportCardData(<StatusReportMulitpleDays {...reportAddPeriod(combinedTransactionResponse, 'status', startDate, endDate)} />)
          dispatch(setLoading(false))
        }
        break;
      case 'sales':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Journal categories')

        let combinedSalesResponse = {}
        let salesCancelled = false
        let salesProgressCounter = 0
        start = moment(start)
        end = moment(end)
        setProgressEnabled(true)
        while (!salesCancelled && start.isSameOrBefore(end)) {
          setCurrent(salesProgressCounter);
          // Calculate the end date for this range
          let endDateRange = start.clone().add(6, 'days');
          if (endDateRange.isAfter(end)) {
            endDateRange = end.clone();
          }

          await GetSalesReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: start.format('YYYY-MM-DD'), endDate: endDateRange.format('YYYY-MM-DD'), location: location, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(response => {
              let combinedObject = JSON.parse(JSON.stringify(combinedSalesResponse));
              combinedObject.days = Object.assign({}, combinedSalesResponse.days, response.data.days)
              combinedSalesResponse = combinedObject
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              salesCancelled = true
            })

          // Move the start date to the beginning of the next range
          start = endDateRange.clone().add(1, 'day');
          salesProgressCounter++
        }
        if (!salesCancelled) {
          await GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
            .then(response => {
              dispatch(setCategoriesAll(response))
              setHighlightsdata(salesReportHighlights(combinedSalesResponse, response))
              setGraphCardData(salesReportGraph(combinedSalesResponse, response))
              setReportCardData(<SalesReportAccordionStyle {...reportAddPeriod(combinedSalesResponse, 'sales', startDate)} />)
              dispatch(setLoading(false))
            });
        }
        break;
      case 'productpermonth':
        dispatch(setLoading(true))
        setSingleDatePicker(true)
        setGraphCardTitle('Products per month')

        GetProductsPerMonth({ token: token, admin_id: admin_id, location_id: location_id, startDate: startDate, location: location, signal: signal.current })
          .then(responseReport => {
            setHighlightsdata(productPerMonthReportHighlights(responseReport.data))
            setGraphCardData(productPerMonthReportGraph(responseReport.data))
            setReportCardData(<ProductsReportMonth {...responseReport.data} />)
            dispatch(setLoading(false))
          })
          .catch(error => {
            if (error.code !== "ERR_CANCELED") {
              dispatch(setLoading(false))
              HandleError({ error: error })
            }
          })
        break;
      case 'transactionsperdevice':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Transactions per devices')

        let combinedTransactionPerDeviceResponse = { users: {} }
        let transactionPerDeviceCancelled = false
        let transactionPerDeviceProgressCounter = 0
        start = moment(start)
        end = moment(end)
        setProgressEnabled(true)
        while (!transactionPerDeviceCancelled && start.isSameOrBefore(end)) {
          setCurrent(transactionPerDeviceProgressCounter);
          // Calculate the end date for this range
          let endDateRange = start.clone().add(6, 'days');
          if (endDateRange.isAfter(end)) {
            endDateRange = end.clone();
          }

          await GetDailyStatusUserReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: start.format('YYYY-MM-DD'), endDate: endDateRange.format('YYYY-MM-DD'), location: location, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(response => {
              console.clear()
              let combinedObject = JSON.parse(JSON.stringify(combinedTransactionPerDeviceResponse));
              for (const [day, dayData] of Object.entries(response.data.days)) {
                for (const [user, userData] of Object.entries(dayData)) {
                  if (user === '46c1141e-27fd-4cc0-b66d-652ef8ab5a08') {
                    console.log('combinedObject', JSON.parse(JSON.stringify(combinedObject)))
                    console.log('day', day)
                    console.log('daydata', dayData)
                    console.log('user', user)
                    console.log('userdata', userData)
                  }
                  if (!(user in combinedObject.users)) {
                    combinedObject.users[user] = { days: {}, total: {}}
                  }
                  if (user in combinedObject.users) {
                    combinedObject.users[user].days[day]= userData
                    Object.keys(userData).forEach((key) => {
                      combinedObject.users[user].total[key] = (combinedObject.users[user].total[key] || 0) + userData[key] 
                    })
                  }

                }
              };
              combinedTransactionPerDeviceResponse = combinedObject
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              transactionPerDeviceCancelled = true
            })

          // Move the start date to the beginning of the next range
          start = endDateRange.clone().add(1, 'day');
          transactionPerDeviceProgressCounter++
        }
        if (!transactionPerDeviceCancelled) {
          GetUserList({ token: token })
            .then(response => {
              console.log('combinedTransactionPerDeviceResponse', combinedTransactionPerDeviceResponse)
              let hash_users_all = {}
              response.data.forEach(user => {
                hash_users_all[user.id] = user
              });
              setHighlightsdata(usersStatusReportHighlights(combinedTransactionPerDeviceResponse, hash_users_all))
              setGraphCardData(usersStatusReportGraph(combinedTransactionPerDeviceResponse, hash_users_all))
              setReportCardData(<UsersStatusReport usersStatusReport={combinedTransactionPerDeviceResponse} hashUserList={hash_users_all} />)
              dispatch(setLoading(false))
            });
        }
        break
      case 'salesperdevice':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Sales per devices')

        let combinedDeviceResponse = { users: {} }
        let deviceCancelled = false
        let deviceProgressCounter = 0
        start = moment(start)
        end = moment(end)
        setProgressEnabled(true)
        while (!deviceCancelled && start.isSameOrBefore(end)) {
          setCurrent(deviceProgressCounter);
          // Calculate the end date for this range
          let endDateRange = start.clone().add(6, 'days');
          if (endDateRange.isAfter(end)) {
            endDateRange = end.clone();
          }

          await GetUserReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: start.format('YYYY-MM-DD'), endDate: endDateRange.format('YYYY-MM-DD'), location: location, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(response => {
              let combinedObject = JSON.parse(JSON.stringify(combinedDeviceResponse));
              Object.keys(response.data.users).forEach((user) => {
                if (!(user in combinedObject.users)) {
                  combinedObject.users[user] = { days: response.data.users[user].days }
                  return
                }
                if (user in combinedObject.users) {
                  Object.keys(response.data.users[user].days).forEach((key) => {
                    if (key === 'totals') {
                      combinedObject.users[user].days.totals.total = combinedObject.users[user].days.totals.total + response.data.users[user].days.totals.total
                      combinedObject.users[user].days.totals.amount = combinedObject.users[user].days.totals.amount + response.data.users[user].days.totals.amount
                      combinedObject.users[user].days.totals.percentage = (parseFloat(combinedObject.users[user].days.totals.amount) + parseFloat(response.data.users[user].days.totals.amount)).toString()
                    } else {
                      combinedObject.users[user].days[key] = response.data.users[user].days[key]
                    }
                  })
                }
              });
              combinedDeviceResponse = combinedObject
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              deviceCancelled = true
            })

          // Move the start date to the beginning of the next range
          start = endDateRange.clone().add(1, 'day');
          deviceProgressCounter++
        }
        if (!deviceCancelled) {
          GetUserList({ token: token })
            .then(response => {
              let hash_users_all = {}
              response.data.forEach(user => {
                hash_users_all[user.id] = user
              });
              setHighlightsdata(usersSalesReportMulitpleDaysHighlights(combinedDeviceResponse, hash_users_all))
              setGraphCardData(usersSalesReportGraph(combinedDeviceResponse, hash_users_all))
              setReportCardData(<UsersSalesReportMultipleDays usersSalesReport={combinedDeviceResponse} hashUserList={hash_users_all} />)
              dispatch(setLoading(false))
            });
        }
      break;
      case 'transactionsperoperator':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Transactions per devices')

        let combinedTransactionPerOperatorResponse = { users: {} }
        let transactionPerOperatorCancelled = false
        let transactionPerOperatorProgressCounter = 0
        start = moment(start)
        end = moment(end)
        setProgressEnabled(true)
        while (!transactionPerOperatorCancelled && start.isSameOrBefore(end)) {
          setCurrent(transactionPerOperatorProgressCounter);
          // Calculate the end date for this range
          let endDateRange = start.clone().add(6, 'days');
          if (endDateRange.isAfter(end)) {
            endDateRange = end.clone();
          }

          await GetDailyStatusOperatorReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: start.format('YYYY-MM-DD'), endDate: endDateRange.format('YYYY-MM-DD'), location: location, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(response => {
              console.clear()
              let combinedObject = JSON.parse(JSON.stringify(combinedTransactionPerOperatorResponse));
              for (const [day, dayData] of Object.entries(response.data.days)) {
                for (const [user, userData] of Object.entries(dayData)) {
                  if (user === '46c1141e-27fd-4cc0-b66d-652ef8ab5a08') {
                    console.log('combinedObject', JSON.parse(JSON.stringify(combinedObject)))
                    console.log('day', day)
                    console.log('daydata', dayData)
                    console.log('user', user)
                    console.log('userdata', userData)
                  }
                  if (!(user in combinedObject.users)) {
                    combinedObject.users[user] = { days: {}, total: {}}
                  }
                  if (user in combinedObject.users) {
                    combinedObject.users[user].days[day]= userData
                    Object.keys(userData).forEach((key) => {
                      combinedObject.users[user].total[key] = (combinedObject.users[user].total[key] || 0) + userData[key] 
                    })
                  }

                }
              };
              combinedTransactionPerOperatorResponse = combinedObject
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              transactionPerOperatorCancelled = true
            })

          // Move the start date to the beginning of the next range
          start = endDateRange.clone().add(1, 'day');
          transactionPerOperatorProgressCounter++
        }
        if (!transactionPerOperatorCancelled) {
          GetEmployees({ token: token, admin_id: admin_id})
            .then(response => {
              dispatch(setEmployeesAll(response.data))
              console.log('combinedTransactionPerOperatorResponse', combinedTransactionPerOperatorResponse)
              let hash_employees_all = {}
              response.data.forEach(employee => {
                hash_employees_all[employee.operator_id] = employee
              });
              setHighlightsdata(operatorsStatusReportHighlights(combinedTransactionPerOperatorResponse, hash_employees_all))
              setGraphCardData(operatorsStatusReportGraph(combinedTransactionPerOperatorResponse, hash_employees_all))
              setReportCardData(<OperatorsStatusReport operatorsStatusReport={combinedTransactionPerOperatorResponse} hashOperatorList={hash_employees_all} />)
              dispatch(setLoading(false))
            });
        }
        break
      case 'salesperoperator':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Operators')

        let combinedOperatorResponse = { operators: {} }
        let operatorCancelled = false
        let operatorProgressCounter = 0
        start = moment(start)
        end = moment(end)
        setProgressEnabled(true)
        while (!operatorCancelled && start.isSameOrBefore(end)) {
          setCurrent(operatorProgressCounter);
          // Calculate the end date for this range
          let endDateRange = start.clone().add(6, 'days');
          if (endDateRange.isAfter(end)) {
            endDateRange = end.clone();
          }

          await GetOperatorReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: start.format('YYYY-MM-DD'), endDate: endDateRange.format('YYYY-MM-DD'), location: location, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(response => {
              let combinedObject = JSON.parse(JSON.stringify(combinedOperatorResponse));
              Object.keys(response.data.operators).forEach((operator) => {
                if (!(operator in combinedObject.operators)) {
                  combinedObject.operators[operator] = { days: response.data.operators[operator].days }
                  return
                }
                if (operator in combinedObject.operators) {
                  Object.keys(response.data.operators[operator].days).forEach((key) => {
                    if (key === 'totals') {
                      combinedObject.operators[operator].days.totals.total = combinedObject.operators[operator].days.totals.total + response.data.operators[operator].days.totals.total
                      combinedObject.operators[operator].days.totals.amount = combinedObject.operators[operator].days.totals.amount + response.data.operators[operator].days.totals.amount
                    } else {
                      combinedObject.operators[operator].days[key] = response.data.operators[operator].days[key]
                    }
                  })
                }
              });
              combinedOperatorResponse = combinedObject
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              operatorCancelled = true
            })

          // Move the start date to the beginning of the next range
          start = endDateRange.clone().add(1, 'day');
          operatorProgressCounter++
        }
        if (!operatorCancelled) {
          setHighlightsdata(operatorsSalesReportHighlights(combinedOperatorResponse))
          setGraphCardData(operatorsSalesReportGraph(combinedOperatorResponse))
          setReportCardData(<OperatorsSalesReport operatorsSalesReport={combinedOperatorResponse} />)
          dispatch(setLoading(false))
        }
        break;
      case 'analysetransactiondescript':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Transaction analyse')
        let combinedTransactionAnalyseResponse = []
        let transactionAnalyseCancelled = false
        let transactionAnalysProgressCounter = 0
        let differenceBetweenStartAndEndDate = Math.ceil(Math.abs(endDate-startDate)/(60*60*24))
        setTotal(differenceBetweenStartAndEndDate)
        start = new Date(startDate*1000)
        end = new Date(endDate*1000)
        setProgressEnabled(true)

        while (!transactionAnalyseCancelled && (start < end)) {
          setCurrent(transactionAnalysProgressCounter);
          // Calculate the end date for this range
          let endDateRange = new Date(start.valueOf())
          endDateRange.setUTCDate(endDateRange.getUTCDate() + 1)
          if (endDateRange > end) {
            endDateRange = end
          }

          // GetAllTransactionsSearch({ token: token, admin_id: admin_id, location_id: location_id, startDate: startDate, signal: signal.current })
          await GetAllTransactionsSearchV2Minimal({ token: token, admin_id: admin_id, location_id: location_id, startDate: start, endDate: endDateRange, signal: signal.current })
            // eslint-disable-next-line no-loop-func
            .then(responseTransactionMinimal => {
              let combined = JSON.parse(JSON.stringify(combinedTransactionAnalyseResponse))
              combinedTransactionAnalyseResponse = [...combined, ...responseTransactionMinimal]
            })
            // eslint-disable-next-line no-loop-func
            .catch(error => {
              if (error.code !== "ERR_CANCELED") {
                dispatch(setLoading(false))
                HandleError({ error: error })
              }
              transactionAnalyseCancelled = true
              
            })
          // Move the start date to the beginning of the next range
          start = new Date(endDateRange.valueOf())
          transactionAnalysProgressCounter++
        }
        if (!transactionAnalyseCancelled) {
          let analyse = AnalyseTransactionDescription(combinedTransactionAnalyseResponse)
          setHighlightsdata(transactionAnalyseHighlights(analyse))
          setGraphCardData(transactionAnalyseGraph(analyse))
          setReportCardData(<TransactionAnalyse {...analyse} />)
          dispatch(setLoading(false))
        }
        break;
      case 'metadata':
        dispatch(setLoading(true))
        setSingleDatePicker(false)
        setGraphCardTitle('Transaction analyse')

        start = moment(start)
        end = moment(end)

        axios.all([
          GetMetaDataReportRange({ token: token, admin_id: admin_id, location_id: location_id, metadata: 'Country', location: location, startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD'), signal: signal.current}),
          GetMetaDataReportRange({ token: token, admin_id: admin_id, location_id: location_id, metadata: 'Gender', location: location, startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD'), signal: signal.current})
        ])
          .then(axios.spread((responseCountry, responseGender) => {
            setHighlightsdata(metaDataHighlights(responseCountry.data, responseGender.data))
            setGraphCardData(metaDataGraph(responseCountry.data, responseGender.data))
            setReportCardData(<MetaDataReport country={responseCountry.data} gender={responseGender.data} />)
            dispatch(setLoading(false))
          }))
          .catch(error => {
            if (error.code !== "ERR_CANCELED") {
              dispatch(setLoading(false))
              HandleError({ error: error })
            }
          })
        break;
      default:
        setGraphCardTitle('Graphic')
        dispatch(setLoading(false))
        break;
    }
  }

  const onRefreshClick = () => {
    setRefresh(!refresh)
  }

  const onReportChange = (event) => {
    setSelectedReport({ id: event.value, name: event.label });
    setHighlightsdata("")
    setGraphCardData("")
    setReportCardData("")
    stopAxios();
    SwitchReport(event.value);
  }

  const onDatePickerChange = (start, end) => {
    dispatch(setStartDate(start.unix()))
    dispatch(setEndDate(end.unix()))
  }

  const onViewHide = () => {
    dispatch(setProgressModalShow(false))
  };

  const stopAxios = async () => {
    if (controller) {
      controller.abort();
    }
  };


  const datePicker = {
    startDate: moment.unix(startDate),
    endDate: moment.unix(endDate),
    locale: {
      format: "DD/MM/YYYY",
    },
    alwaysShowCalendars: true,
    showDropdowns: true,
    opens: 'right',
    linkedCalendars: false,
    autoApply: true,
    singleDatePicker: singleDatePicker,
    onChange: onDatePickerChange,
    className: 'reporting-date-picker'
  }

  // chart stuff
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  const datePickerSelectFieldRefreshButtonData = {
    datePicker: datePicker,
    reportSelect: {
      name: 'reportSelect',
      selected: selectedReport,
      options: ReportSelectChoices(),
      className: 'reporting-select',
      onChange: onReportChange,
      clearable: Boolean(false)
    },
    refreshButton: {
      tag: 'Refresh',
      onClick: onRefreshClick
    }
  }

  const reportCard = {
    size: 12,
    title: location_id ? 'Reporting' : 'Choose administration and location.',
    subtitle: <DatePickerSelectFieldRefreshButton {...datePickerSelectFieldRefreshButtonData} />,
    data: reportCardData,
    loadingData: {
      total: total,
      current: current,
      enabled: progressEnabled
    }
  }

  const highlightCard = {
    size: 6,
    title: location_id ? 'Highlights' : 'Choose administration and location.',
    data: <HighlightTableReporting {...highlightsdata} />,
    overflow: true
  };

  const graphCard = {
    size: 6,
    title: location_id ? graphCardTitle : 'Choose administration and location.',
    data: graphCardData,
  };

  const progressModal = {
    modal: {
      show: progressModalShow,
      onHide: onViewHide
    },
    task_data: progressData
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Reporting'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    stopAxios();
    SwitchReport(selectedReport.id)
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    setHighlightsdata()
    setGraphCardData()
    setReportCardData()
    // eslint-disable-next-line
  }, [admin_id]);

  useEffect(() => {
    setHighlightsdata()
    setGraphCardData()
    setReportCardData()

    if (location_id) {
      stopAxios();
      SwitchReport(selectedReport.id)
    }

    // eslint-disable-next-line
  }, [location_id, refresh]);


  return (
    <>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
      <ProgressModal {...progressModal} />
      <BasicCard {...graphCard} />
      <HighlightsCard {...highlightCard} />
      <BasicCard {...reportCard} />
    </>
  )
};
export default Reporting;
