import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TransactionButtons from "../portal/transactions/transactionbuttons";
import TransactionModal from "../components/transactionModal";
import TransactionSnelstartModal from './transactionSnelstartResultModal';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from "react-svg";
import questionmark from "../static/icons/questionmark.svg";
import { Dropdown, Form, Navbar } from 'react-bootstrap';

export default function TransactionTable(props) {
  const transactions = props.transactions
  const snelstart = props.snelstart
  const hashEmployees = props.employees
  const settings = props.settings
  const buttons = TransactionButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const accountingmodalview = buttons.hasOwnProperty('accountingmodalview') ? Object.assign({}, buttons.accountingmodalview) : false
  const exportButton = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const accountingInfo = buttons.hasOwnProperty('accountingInfo') ? Object.assign({}, buttons.accountingInfo) : false
  const trigger = buttons.hasOwnProperty('trigger') ? Object.assign({}, buttons.trigger) : false
  const exportPdf = buttons.modalview?.modal?.hasOwnProperty('exportPdf') ? Object.assign({}, buttons?.modalview?.modal?.exportPdf) : false
  // const accept = buttons.hasOwnProperty('accept') ? Object.assign({}, buttons.accept) : false
  // const cancel = buttons.hasOwnProperty('cancel') ? Object.assign({}, buttons.cancel) : false
  // const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const selected = buttons?.select?.selected
  const { t: translate } = useTranslation();

  let sorted = []

  // convert object to array for sorting
  if (transactions) {
    sorted = Object.values(transactions).sort((a, b) => b?.invoice?.number - a?.invoice?.number)
  }

  let rows = []
  sorted.forEach(transaction => {
    let snelstartIcon = <ReactSVG src={questionmark} />
    if (transaction.is_processed_by_accounting) {
      snelstartIcon = <>
        <div className='snelstart-icons'>
          <i className="bi bi-check-circle true mx-3"></i>
          <Button size="sm" type='button' onClick={() => { accountingInfo.onClick(transaction) }}>{translate(accountingInfo.tag)}</Button>
        </div>
      </>
    }
    if (!transaction.is_processed_by_accounting) {
      snelstartIcon = <>
        <div className='snelstart-icons'>
          <i className="bi bi-x-square false mx-3"></i>
          <Button size="sm" type='button' onClick={() => { trigger.onClick(transaction) }} id={transaction.id}>{trigger.tag}</Button>
          <Button size="sm" type='button' onClick={() => { accountingInfo.onClick(transaction) }}>{translate(accountingInfo.tag)}</Button>
        </div>
      </>
    }

    let operator = transaction.operator_id ? hashEmployees[transaction.operator_id]?.name : ''
    if (!settings.minimal) {
      operator = transaction.invoice?.operator_id ? hashEmployees[transaction.invoice?.operator_id]?.name : ''
    }

    const eftOverride = JSON.parse(transaction?.descript)?.EFT?.includes('EFT Overridden') ?? false;
    rows.push(
      <tr key={transaction.id} className={selected?.hasOwnProperty(transaction.id) ? 'product-row selected' : 'product-row'}>
        <td>
          <Form>
            <Form.Check onChange={() => { buttons.select.onClick(transaction) }} checked={selected?.hasOwnProperty(transaction.id)} />
          </Form>
        </td>
        <td>{transaction.invoice?.number} </td>
        <td>{transaction.date ? new Date(transaction.date).toLocaleString() : new Date(transaction.modification).toLocaleString()}</td>
        {!settings.minimal && <td>{operator}</td>}
        <td align="right">{Number(transaction.amount_paid).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td>
          {transaction.payments?.map((payment, index) => (
            <div key={index}>{payment.name} {(payment?.name === 'EFT' && eftOverride) && '(Override)'}</div>
          ))}
        </td>
        {snelstart && <td><div className='table-icons'>{snelstartIcon}</div></td>}
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(transaction) }}>{translate(modalview.tag)}</Button>}
          {exportPdf && <Button size="sm" type='button' onClick={() => { exportPdf.onClick(transaction) }}>{translate(exportPdf.tag)}</Button>}
          {/* {accept && status !== 'ACCEPTED' && <Button size="sm" type='button' onClick={() => { accept.onClick(transaction) }}>{translate(accept.tag)}</Button>} */}
          {/* {cancel && status !== 'CANCELED' && <Button size="sm" type='button' onClick={() => { cancel.onClick(transaction) }}>{translate(cancel.tag)}</Button>} */}
          {/* {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(transaction) }}>{translate(edit.tag)}</Button>} */}
        </td>
      </tr>
    )
  });

  return (
    <>
      {Object.keys(selected).length > 0 &&
        <Navbar expand="xxl" className="bg-body-tertiary">
          {Object.keys(selected).length} {translate('transaction(s) selected.')}
          <Dropdown className='actions-button'>
            <Dropdown.Toggle size='sm' type='button'>
              {translate('Actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {exportButton && <Dropdown.Item onClick={() => { exportButton.onClick() }}>{exportButton.tag}</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      }
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>
              {transactions && transactions?.length > 0 &&
                <Form>
                  <Form.Check onChange={() => { buttons.selectAll.onClick(transactions) }} checked={transactions?.every((transaction) => selected.hasOwnProperty(transaction?.id))} />
                </Form>
              }
            </th>
            <th>{translate('Invoice number')}</th>
            <th>{translate('Date')}</th>
            {!settings.minimal && <th>{translate('Employee')}</th>}
            <th className="text-end">{translate('Amount')}</th>
            <th>{translate('Payment')}</th>
            {snelstart && <th>{translate('Snelstart')}</th>}
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table >
      <TransactionModal modal={modalview.modal} />
      <TransactionSnelstartModal modal={accountingmodalview.modal} />
    </>
  )
}