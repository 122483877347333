import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRegistrationModalShow, setRegistrationModalShow, setRegistration } from "../../redux/dataSlices/registrationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { confirmAlert } from "react-confirm-alert";
import DelRegistration from "../../actablueAPI/registrations/DelRegistrationById";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from "react-i18next";

export default function RegistrationsButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getRegistrationModalShow)
  // const pageSize = useSelector(getDevicePagesize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();

  function onViewClick(registration) {
    dispatch(setRegistration(registration));
    dispatch(setRegistrationModalShow(true));
  };

  function onclickEdit (registration) {
    dispatch(setRegistration(registration));
    navigate("/registration/edit");
  };

  const onViewHide = () => {
    dispatch(setRegistrationModalShow(false));
  };

  const onclickAdd = () => {
    navigate("/registration/add");
  };

  function onclickDelete(registration, refresh) {
    dispatch(setLoading(true))
    confirmAlert({
      title: translate('Confirm to remove'),
      message: translate('remove_confirm', { object: `"${registration.name}"` }),
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            DelRegistration({token:token, id:registration.id})
            .then(response => {
              refresh()
            })
          .catch(error => {
            HandleError({ error: error })
            dispatch(setLoading(false))
          })
          }
        },
        {
          label: 'No',
        }
      ]
    });
    dispatch(setLoading(false))
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.registrations >= 1) {
    buttons.add = {
      tag: "Add",
      value:'add',
      onClick:onclickAdd,
      className:'add-button'
    } 
  }
  if (scopes.registrations >= 2) {
    buttons.edit = {
      tag:'Edit',
      value:'edit',
      onClick:onclickEdit
    }
  }
  if (scopes.registrations >= 3) {
    buttons.del = {
      tag: "Delete",
      value: 'del',
      onClick: onclickDelete
    }
  }

  return buttons
}

