import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';
import PlaylistSelector from './narrowcastingSchedulePlaylistSelector';
import ScheduleTimeline from './narrowcastingScheduleTimeline';

function ScheduleForm(props) {
  const { t: translate } = useTranslation();
  const [numberAddedPlaylists, setNumberAddedPlaylists] = useState(null)

  if (props.data.hasOwnProperty('playlists')) {
    if (props.data.playlists){
      if (props?.data?.playlists?.[0]) {
        props.playlists.selected = { 'value': props.data.playlists[0].id, 'label': props.data.playlists[0].name }
      } else if (props.playlists.selected){
        delete props.playlists.selected
      }

      if(props.data.playlists.length > 1) {
        if (numberAddedPlaylists === null && numberAddedPlaylists !== props.data.playlists.length - 1){
          setNumberAddedPlaylists(props.data.playlists.length - 1)
        }
      }
    }
  }
  
  if (props.data?.hasOwnProperty('day')) {
    if (!props.day.selected) {props.day.selected = []}
    props.data.day.forEach(day => {
      props.day.selected.push({ 'value': day, 'label': day })
    })
  }
  
  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });
  const handleOnPlusClick = () => {
    props.onRemoveAdd(numberAddedPlaylists+1)
    setNumberAddedPlaylists(numberAddedPlaylists+1)
  }

  const handleOnMinusClick = () => {
    if (numberAddedPlaylists >= 1) {
      props.onRemoveAdd(numberAddedPlaylists-1)
      setNumberAddedPlaylists(numberAddedPlaylists-1)
    }
    
  }

  if (numberAddedPlaylists > 0){
    for (let playlistIndex=1; playlistIndex<=numberAddedPlaylists; playlistIndex++) {
      basicFields.push(
        <PlaylistSelector {...props} playlistNumber={playlistIndex}/>
      )
    }
  }

  return (
    <>
      <ScheduleTimeline schedule={props.data}/>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
                <div display='inline-flex'>
                <Button 
                  variant='dark' 
                  type='button' 
                  size='sm' 
                  onClick={handleOnPlusClick}
                >
                  + Playlist
                </Button>
                {numberAddedPlaylists === null || numberAddedPlaylists === 0 ? null : 
                  <Button 
                    variant='dark' 
                    type='button' 
                    size='sm' 
                    onClick={handleOnMinusClick}
                  >
                    - Playlist
                  </Button>
                }
                </div>
                
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default ScheduleForm;