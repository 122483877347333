import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setPrinter, setPrinterId, getPrinterModalShow, setPrinterModalShow } from "../../redux/dataSlices/printerSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import DeletePrinterById from "../../actablueAPI/shelflabels/DeletePrinterByID";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import { setSettingsPrinters } from "../../redux/dataSlices/settingsSlice";
import { confirmAlert } from "react-confirm-alert";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { useTranslation } from "react-i18next";

export default function PrinterButtons() {
  const modalShow = useSelector(getPrinterModalShow)
  // const pageSize = useSelector(getProductPagesize)
  const scopes = useSelector(getScopes)
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  function onViewClick(printer) {
    dispatch(setPrinter(printer));
    dispatch(setPrinterModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setPrinterModalShow(false));
  };

  const onclickEdit = (event) => {
    dispatch(setPrinterId(event.target.id));
    navigate("/printer/edit");
  };

  const onclickAdd = () => {
    navigate("/printer/add");
  };

  const onClickDelete = (event) => {
    confirmAlert({
      title: translate('Confirm to remove'),
      message: translate('remove_confirm', { object: `"${event.target.name}"` }),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            dispatch(setLoading(true))
            DeletePrinterById({ token: token, admin_id: admin_id, location_id: location_id, id: event.target.id })
            .then(() => {
              GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id, page: 1, size: 100 })
                .then(data => {
                  dispatch(setSettingsPrinters(data))
                  dispatch(setLoading(false))
                })
            })
          }
        },
        {
          label: translate('No'),
        }
      ]
    });
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
    // pagination: {
    //   maxbuttons: 5, //uneven number
    //   onClick:onPaginationClick
    // },
    // pagesize: {
    //   className: 'navbar-select',
    //   label:"page size selector",
    //   placeholder:"No selection",
    //   pagesize:pageSize,
    //   selected:{'id':pageSize, 'name':pageSize},
    //   number_of_elements: 0,
    //   options: pagesize,
    //   onChange:onProductPageSizeChange
    // },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.products >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.products >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  if (scopes.products >= 3) {
    buttons.delete = {
      tag: "Delete",
      value: 'delete',
      onClick: onClickDelete
    }
  }

  return buttons
}

