export const NarrowcastingPlaylistFormFields = () => {

  const NarrowcastingPlaylistFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'hidden', name: 'admin_id', id: 'admin_id' },
    { type: 'hidden', name: 'location_id', id: 'location_id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'text', name: `Description`, id: 'description', required: false },
    { type: 'select', name: `Layout`, id: 'layout', formdatafield: 'layout', required: true },
    { type: 'hidden', name: `Screen 1 views`, id: 'views1', formdatafield: 'views1' },
    { type: 'hidden', name: `Screen 2 views`, id: 'views2', formdatafield: 'views2' },
    { type: 'hidden', name: `Screen 3 views`, id: 'views3', formdatafield: 'views3' },
    { type: 'hidden', name: `Screen 4 views`, id: 'views4', formdatafield: 'views4' },
  ]

  return NarrowcastingPlaylistFormFields
}