import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

export default function IngredientsEditTable(props) {
  const { t: translate } = useTranslation();
  let rows = []
  let selected = props.ingredients?.selected ?? []

  if (props.data.hasOwnProperty('ingredients')) {
    props.data.ingredients.forEach(element => {
      rows.push(
        <tr key={element}>
          <td><Form.Check onChange={props?.ingredients?.check?.onChange} id={element} /></td>
          <td>
            <Form.Control
              index={props.data.ingredients.indexOf(element)}
              type='text'
              name='ingredient'
              placeholder='ingredient'
              value={element}
              onChange={props?.ingredients?.onChange}
              autoFocus='true'
            />
          </td>
        </tr>
      )
    });
  }

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th></th>
            <th>{translate('Ingredient')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>

        <Button variant="primary" className='mt-3' type={props.ingredients?.add?.type} onClick={props.ingredients?.add?.onClick} name={props.ingredients?.add?.name}>
          {translate(props.ingredients?.add?.title)}
        </Button>
        <Button variant="primary" className='mt-3' type={props.ingredients?.remove?.type} onClick={props.ingredients?.remove?.onClick} name={props.ingredients?.remove?.name} disabled={selected.length === 0 ? true : false}>
          {translate(props.ingredients?.remove?.title)}
        </Button>
      </Table>
    </>
  );
}