import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setTaskId, setMatchColumnsForm, getMatchColumnsForm, setImportSaveModalShow } from "../../redux/dataSlices/importSlice";
import { ProductImportFormFields } from "./importFormFields.jsx";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { importDynamicChoices } from "../../selectfieldchoices/importselect";
import PostImportProducts from "../../actablueAPI/import/PostImportProducts.jsx";
import PostImportProductsAdminWide from "../../actablueAPI/import/PostImportProductsAdminWide.jsx";
import { getLocationsAll } from "../../redux/dataSlices/locationSlice.js";
import useHandleError from "../../customhooks/useHandleError.jsx";

const ImportingProduct = (importForm, headers, data) => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const match_columns_form = useSelector(getMatchColumnsForm)
  const locationsAll = useSelector(getLocationsAll)
  const dispatch = useDispatch();
  const HandleError = useHandleError();

  const onMatchColumsFormChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  }

  const onChange = (event, selectFieldId) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: selectFieldId })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onDynamicChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: event.prefix.concat('_').concat(event.name) })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };


  const onMatchColumnsSave = (event) => {
    dispatch(setImportSaveModalShow(true))
  }

  const onStartImport = () => {
    let ab_data = {
      token: token,
      access_token: token?.access_token,
      admin_id: admin_id,
      location_id: location_id,
      ab_selection: {
        admin_id: admin_id,
        location_id: location_id
      },
      read_dict: data,
      matchingForm: Object.assign({ administration_id: admin_id, location_id: location_id }, match_columns_form),
      supplier_id: importForm?.supplier_id,
      unique_id: importForm?.unique_id,
      double_sku_ean: importForm?.double_sku_ean,
      only_new: importForm?.only_new,
      journalcategories: importForm?.journalcategories,
      import_stock: importForm?.import_stock
    }

    switch (importForm.importtype) {
      case 'products':
        PostImportProducts({ data: ab_data })
          .then(response => {
            dispatch(setTaskId(response.data.task_id))
          })
          .catch(error => {
            HandleError({ error: error })
          })
        break;
      case 'products+locations':
        // add list of location id's
        ab_data.locations = locationsAll.map((location) => location.id)
        PostImportProductsAdminWide({ data: ab_data })
          .then(response => {
            dispatch(setTaskId(response.data.task_id))
          })
          .catch(error => {
            HandleError({ error: error })
          })
        break;
      default:
        break;
    }
  }

  const importMatchColumnsFormData = {
    submit: {
      title: 'Start Import',
      type: 'submit',
      onClick: onStartImport
    },
    save: {
      title: 'Save Import Settings',
      type: 'submit',
      onClick: onMatchColumnsSave
    },
    fields: ProductImportFormFields(headers, importForm),
    // field: {
    //   onChange: onMatchColumsFormChange
    // },
    data: importForm,
    headerSelection: {
      options: headers,
      onChange: onMatchColumsFormChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    sku_code: {
      name: 'sku_code',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    plu_code: {
      name: 'plu_code',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    name: {
      name: 'name',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    description: {
      name: 'description',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    thumbnail: {
      name: 'thumbnail',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    active: {
      name: 'active',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    discount: {
      name: 'discount',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    price_excl_vat: {
      name: 'price_excl_vat',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    price_incl_vat: {
      name: 'price_incl_vat',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    supplier_artikel_code: {
      name: 'supplier_artikel_code',
      options: headers,
      // selected: {},
      onChange: onMatchColumsFormChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    supplier_price: {
      name: 'supplier_price',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    ask_sn: {
      name: 'ask_sn',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    marge: {
      name: 'marge',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    emballage: {
      name: 'emballage',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    vat: {
      name: 'vat',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    ean: {
      name: 'ean',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    maingroup: {
      name: 'maingroup',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    subgroup1: {
      name: 'subgroup1',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    subgroup2: {
      name: 'subgroup2',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    subgroup3: {
      name: 'subgroup3',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    is_weighted: {
      name: 'is_weighted',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    input_required: {
      name: 'input_required',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    size: {
      name: 'size',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    color: {
      name: 'color',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    brand: {
      name: 'brand',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    model: {
      name: 'model',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    packaging: {
      name: 'packaging',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    contents: {
      name: 'contents',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    weigth: {
      name: 'weigth',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    heigth: {
      name: 'heigth',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    length: {
      name: 'length',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    width: {
      name: 'width',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    dimension: {
      name: 'dimension',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    minimum_stock: {
      name: 'minimum_stock',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    maximum_stock: {
      name: 'maximum_stock',
      options: headers,
      // selected: {},
      onChange: onChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    dynamic: {
      name: 'dynamic',
      options: importDynamicChoices,
      // selected: {},
      onChange: onDynamicChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  }

  return importMatchColumnsFormData
};
export default ImportingProduct;