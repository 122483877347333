import CreateField from "../helpers/createField";

const PlaylistSelector = (props) => {
    let playlistFields = [];
    const additionalFieldsInfo = [
        { type: 'time', name: `Transition Time`, id: `transition_time${props.playlistNumber}`, invalid: props.transTimeInvalid[props.playlistNumber], invalidFeedback: 'Invalid time'},
        { type: 'select', name: `Playlist ${props.playlistNumber+1}`, id: props.playlistNumber, formdatafield: 'playlists' }
    ];
    additionalFieldsInfo.forEach(field => {
        field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
        let newProps = {...props}
        if (props.data.hasOwnProperty('playlists')) {
            if (props.data.playlists[props.playlistNumber]){
            newProps.playlists.selected = { 'value': props.data.playlists[props.playlistNumber].id, 'label': props.data.playlists[props.playlistNumber].name }
            } else {
            delete newProps.playlists.selected;
            }
        }
        playlistFields.push(CreateField(field, newProps));
    })
    return (
        <>
            {playlistFields}
        </>
    )

}

export default PlaylistSelector;