import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getVat } from '../redux/dataSlices/vatSlice';

import './css/modal.css'
import './css/vatModal.css'
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import JsonHighlighter from './serviceJsonHighlight';


export default function VatModal(props) {
  const vat = useSelector(getVat);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();
  const hash_admins_all = useSelector(getHashAdministrationsAll)

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (vat) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Vat: {vat.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="info">{translate('Info')}</Nav.Link>
              </Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {vat.name}</p>
                      <p>{translate('Active')}: {JSON.stringify(vat.active)}</p>
                      <p>{translate('Ledger')}: {vat.ledger}</p>
                      <p>{translate('External id')}: {vat.external_id}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[vat.administration_id]?.name}</p>
                      <p>{translate('Created')}: {new Date(vat.creation).toLocaleString()}</p>
                      <p>{translate('Modified')}: {new Date(vat.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {vat.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate('Administration')}: {vat.administration_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...vat} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate('Created')}: {new Date(vat.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Modified')}: {new Date(vat.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}