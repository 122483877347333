import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setReservationObjectModalShow, getReservationObjectModalShow, setReservationObjectId, setReservationObject, setReservationObjects } from "../../redux/dataSlices/reservationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import GetReservationObjects from "../../actablueAPI/reservations/GetReservationObjects";
import DeleteReservationObjectById from "../../actablueAPI/reservations/DeleteReservationObject";
import useHandleError from "../../customhooks/useHandleError";
import { useTranslation } from "react-i18next";

export default function ReservationObjectButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getReservationObjectModalShow)
  const scopes = useSelector(getScopes)
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();

  function onViewClick(reservationobject) {
    dispatch(setReservationObject(reservationobject));
    dispatch(setReservationObjectModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setReservationObjectModalShow(false));
  };

  const onclickEdit = (event) => {
    dispatch(setReservationObjectId(event.target.id));
    navigate("/reservationobject/edit");
  };

  const onclickAdd = () => {
    navigate("/reservationobject/add");
  };

  function onclickDelete(reservationObject) {
    confirmAlert({
      title: translate('Confirm to remove'),
      message: translate("remove_confirm", { object: `"${reservationObject.name}"` }),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            dispatch(setLoading(true))
            DeleteReservationObjectById({ token: token, id: reservationObject.id })
              .then(() => {
                GetReservationObjects({ token: token, admin_id: admin_id, location_id: location_id })
                  .then(response => {
                    dispatch(setReservationObjects(response.data))
                    dispatch(setLoading(false))
                  })
                  .catch((error) => {
                    HandleError({ error: error })
                    dispatch(setLoading(false))
                  })
              })
              .catch(error => {
                HandleError({ error: error })
                dispatch(setLoading(false))
              })
          }
        },
        {
          label: translate('No'),
        }
      ]
    });
  };

  let buttons = {
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.reservationobjects >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.reservationobjects >= 2) {
    buttons.edit = {
      tag: 'Edit',
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.del = {
      tag: 'Delete',
      value: 'del',
      onClick: onclickDelete
    }
  }

  return buttons
}

