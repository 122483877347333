import axios from "axios";

const GetForecastData = ({ latitude, longitude, current_weather = true, timeformat = 'unixtime', timezone = 'Europe/Amsterdam', past_days = 7, archive = false, selectedDate = undefined }) => {
  if (!archive) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: process.env.REACT_APP_WEATHER_FORECAST_BASE_URL,
        params: {
          latitude: latitude,
          longitude: longitude,
          current_weather: current_weather,
          timeformat: timeformat,
          timezone: timezone,
          past_days: past_days,
          daily: [
            'weathercode',
            'temperature_2m_min',
            'temperature_2m_max',
            'apparent_temperature_min',
            'apparent_temperature_max',
            'precipitation_sum',
            'precipitation_hours'
          ].join(',')
        },
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error('error', error);
          reject(error)
        });
    });
  }

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_WEATHER_ARCHIVE_BASE_URL,
      params: {
        latitude: latitude,
        longitude: longitude,
        start_date: selectedDate.format('YYYY-MM-DD'),
        end_date: selectedDate.format('YYYY-MM-DD'),
        timeformat: timeformat,
        timezone: timezone,
        daily: [
          'weathercode',
          'temperature_2m_min',
          'temperature_2m_max',
          'apparent_temperature_min',
          'apparent_temperature_max',
          'precipitation_sum',
          'precipitation_hours'
        ].join(',')
      }
    })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.error('error', error);
        reject(error)
      });
  });

};

export default GetForecastData