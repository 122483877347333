import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getResellerModalShow, getResellerPageSize, getResellerPagination, setReseller, setResellerId, setResellerModalShow, setResellerPageSize, setResellerPagination, setResellers } from "../../redux/dataSlices/kitchenmanagerSlice";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import useHandleError from "../../customhooks/useHandleError";
import DeleteReseller from "../../actablueAPI/kitchenmanager/DeleteReseller";
import GetResellers from "../../actablueAPI/kitchenmanager/GetResellers";

export default function ResellerButtons() {
  const modalShow = useSelector(getResellerModalShow);
  const pageSize = useSelector(getResellerPageSize);
  const scopes = useSelector(getScopes);
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const reseller_pagination = useSelector(getResellerPagination);
  const reseller_pagesize = useSelector(getResellerPageSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  function onViewClick(reseller) {
    dispatch(setReseller(reseller));
    dispatch(setResellerModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setResellerModalShow(false));
  };

  const onClickEdit = (event) => {
    dispatch(setResellerId(event.id));
    navigate("/kitchenmanager/reseller/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setResellerPagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setResellerPageSize(event.value))
  }

  const onClickAdd = () => {
    navigate("/kitchenmanager/reseller/add");
  };

  function onClickDelete(reseller) {
    confirmAlert({
      title: translate('Confirm to remove'),
      message: translate('remove_confirm', { object: `"${reseller?.id}"` }),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            dispatch(setLoading(true))
            // check token
            DeleteReseller({ token: token, id: reseller?.id })
              .then(() => {
                GetResellers({ token: token, admin_id: admin_id, location_id: location_id, page: reseller_pagination, size: reseller_pagesize })
                  .then(response => {
                    dispatch(setResellers(response.data))
                  })
                  .catch(error => {
                    HandleError({ error: error })
                    dispatch(setLoading(false))
                  })
              })
              .catch(error => {
                HandleError({ error: error })
              })
              .finally(() => {
                dispatch(setLoading(false))
              })
          }
        },
        {
          label: translate('No'),
        }
      ]
    });
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.resellers >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onClickAdd,
      className: 'add-button'
    }
  }
  if (scopes.resellers >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onClickEdit
    }
  }
  if (scopes.resellers >= 3) {
    buttons.delete = {
      tag: "Delete",
      value: 'delete',
      onClick: onClickDelete
    }
  }

  return buttons
}

