import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

export default function OrderlineTable(props) {
  const { t: translate } = useTranslation();

  let newOrderLines = []
  if (props.order.order_lines) {
    for(const orderline of Object.values(props.order.order_lines)) {
      newOrderLines.push(
        <tr key={orderline.id}>
          <td>{orderline.quantity}</td>
          <td>{orderline.product_name}</td>
          <td align="right">{Number(orderline.amount_inclusive_vat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        </tr>
      )
      if (orderline.attributes) {
        for (const attribute of Object.values(orderline.attributes)) {
          newOrderLines.push(
            <tr key={attribute.id}>
              <td>{attribute.quantity}</td>
              <td>{attribute.product_name}</td>
              <td align="right">{Number(attribute.amount_inclusive_vat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            </tr>
          )
        }
      }
    }
  }

  return (
    <Table hover width='100%'>
      <thead>
        <tr key='header'>
          <th width='20%'>#</th>
          <th width='60%'>{translate("Item")}</th>
          <th width='20%' className="text-end">{translate("Price")}</th>
        </tr>
      </thead>
      <tbody>
        {newOrderLines}
      </tbody>
    </Table>
  )
}