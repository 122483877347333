import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';
import { layoutChoices } from '../selectfieldchoices/narrowcastingselect.mjs';


function NarrowcastingPlaylistForm(props) {
  const { t: translate } = useTranslation();

  if (props.data?.hasOwnProperty('views1')) {
    if (!props.views1.selected) { props.views1.selected = [] }
    props.data.views1?.forEach(view => {
      props.views1.selected.push({ 'value': view.id, 'label': view.name })
    });
  }
  if (props.data?.hasOwnProperty('views2')) {
    if (!props.views2.selected) { props.views2.selected = [] }
    props.data.views2?.forEach(view => {
      props.views2.selected.push({ 'value': view.id, 'label': view.name })
    });
  }
  if (props.data?.hasOwnProperty('views3')) {
    if (!props.views3.selected) { props.views3.selected = [] }
    props.data.views3?.forEach(view => {
      props.views3.selected.push({ 'value': view.id, 'label': view.name })
    });
  }
  if (props.data?.hasOwnProperty('views4')) {
    if (!props.views4.selected) { props.views4.selected = [] }
    props.data.views4?.forEach(view => {
      props.views4.selected.push({ 'value': view.id, 'label': view.name })
    });
  }

  if (props.data?.hasOwnProperty('layout')) {
    props.layout.selected = { 'value': props.data.layout, 'label': layoutChoices.find(element => element.id === props.data.layout)?.name }
    if (props.data.layout === 'fullscreen') {
      props.fields.find(field => field.id === 'views1').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views2').type = 'hidden';
      props.fields.find(field => field.id === 'views3').type = 'hidden';
      props.fields.find(field => field.id === 'views4').type = 'hidden';
    }
    if (props.data.layout === 'vertical-split-screen' || props.data.layout === 'horizontal-split-screen') {
      props.fields.find(field => field.id === 'views1').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views2').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views3').type = 'hidden';
      props.fields.find(field => field.id === 'views4').type = 'hidden';
    }
    if (props.data.layout === 't-split-screen-left' || props.data.layout === 't-split-screen-right' || props.data.layout === 't-split-screen-top' || props.data.layout === 't-split-screen-bottom'){
      props.fields.find(field => field.id === 'views1').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views2').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views3').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views4').type = 'hidden';
    }
    if (props.data.layout === 'cross-split-screen') {
      props.fields.find(field => field.id === 'views1').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views2').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views3').type = 'sortable-multiselect';
      props.fields.find(field => field.id === 'views4').type = 'sortable-multiselect';
    }
  }

  // if (props?.data?.all_day) {
  //   props.fields.find(field => field.id === 'start_time').disabled = true;
  //   props.fields.find(field => field.id === 'end_time').disabled = true;
  // }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default NarrowcastingPlaylistForm;