import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function MultiSelectField(props) {
  const { t: translate } = useTranslation();
  const selected = props.hasOwnProperty('selected') ? Array.from(props.selected) : []

  const uniqueValues = {};
  const uniqueSelected = selected.filter(item => {
    if (!uniqueValues[item.value]) {
      uniqueValues[item.value] = true;
      return true;
    }
    return false;
  });

  const propOptions = props.hasOwnProperty('options') && props.options !== undefined ? props.options.hasOwnProperty('content') ? props.options.content : props.options : false

  let options = []
  if (propOptions) {
    // eslint-disable-next-line
    propOptions.map((option) => {
      options.push({ 'value': option.id, 'label': option.name || option.title || option.domain || option.id, 'name': props.name, 'prefix':props.prefix, 'id': props.id  })
    })
  }

  if (options) {
    return (
      <Select
        prefix={props.prefix}
        isMulti
        name="productpages"
        options={options}
        width={props.width}
        className={`basic-multi-select ${props.className}`}
        placeholder={props?.placeholder ? translate(props.placeholder) : translate('Select...')}
        classNamePrefix="select"
        onChange={props.onChange}
        value={uniqueSelected}
        isClearable={props.clearable}
        isSearchable={props.searchable}
        required={props.required ?? true}
        closeMenuOnSelect={false}
        menuPortalTarget={document.querySelector('body')}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 1056,
          }),
        }}
      />
    );
  }
}

export default MultiSelectField;