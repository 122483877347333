import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProductCategoriesAll, getHashProductCategoriesAll, setProductCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import { CategoryFormFields } from "./productcategoryFormFields"
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChange from "../../helpers/handleOnChange";
import PostCategory from "../../actablueAPI/products/PostCategory";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import GetAllPrinters from "../../actablueAPI/kitchenmanager/GetAllPrinters";
import { getHashPrintersAll, getHashScreensAll, getHashSortCategoriesAll, getPrintersAll, getScreensAll, getSortCategoriesAll, setPrintersAll, setScreensAll, setSortCategoriesAll } from "../../redux/dataSlices/kitchenmanagerSlice";
import GetAllCategories from "../../actablueAPI/kitchenmanager/GetAllCategories";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostProductCategory from "../../actablueAPI/kitchenmanager/PostProductCategory";
import ProductCategoryForm from "../../components/productcategoryForm";
import { validateFormObject } from "../../helpers/validateFormObject";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import GetAllScreens from "../../actablueAPI/kitchenmanager/GetAllScreens";
import useHandleError from "../../customhooks/useHandleError";
import PostImage from "../../actablueAPI/cdn/PostImage";

const CategoryAdd = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const productpageCategories = useSelector(getProductCategoriesAll)
  const hash_productpage_categories = useSelector(getHashProductCategoriesAll)
  const kmPrinters = useSelector(getPrintersAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const sortCategories = useSelector(getSortCategoriesAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const screens = useSelector(getScreensAll);
  const hash_screens = useSelector(getHashScreensAll);
  const [formFields, setFormFields] = useState(CategoryFormFields());
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const [category, setCategory] = useState({ administration_id: admin_id, location_id: location_id, is_visible: true, type: 'PRODUCTPAGE' })
  const navTitle = "Product category add"

  const onChange = (event) => {
    let newCategory = HandleOnChange({ event: event, object: category })
    setCategory(newCategory)
  }

  const onParentChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'parent', hash: hash_productpage_categories })
    setCategory(newCategory)
  };

  const onPrinterChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'printer', prefix: 'kitchenmanager' })
    setCategory(newCategory)
  }

  const onCategoryChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'category', prefix: 'kitchenmanager' })
    setCategory(newCategory)
  }

  const onScreensChange = (event) => {
    let newCategory = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: category, selectfield: 'screens', prefix: 'kitchenmanager' })
    if (newCategory.kitchenmanager.screens.length === 0) {
      delete newCategory.kitchenmanager.screens
    }
    setCategory(newCategory)
  }

  const onImageChange = (event) => {

    setSaveDisabled(true);
    const newFormFields = [...formFields];
    newFormFields.find((field) => field?.id === 'src').disabled = true
    newFormFields.find((field) => field?.id === 'new_image_src').disabled = true
    setFormFields(newFormFields);

    // check extension of file
    const extension = event.target?.files?.[0]?.name?.split('.')[1];
    const filesize = event.target?.files?.[0]?.size;
    const filesizeMb = filesize / 1024;
    const filesizeLimit = 1024 * 8; // 8 MB

    let isInvalid = false;
    switch (true) {
      case (filesizeMb > filesizeLimit):
        HandleError({
          error: "Image size exceeds the filesize limit (8MB)",
          showPopup: true,
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
        isInvalid = true;
        break;

      case (extension.toLowerCase() !== 'png'):
        HandleError({
          error: "Uploaded file must be .PNG",
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
        isInvalid = true;
        break;
      default:
        isInvalid = false;
        break;
    }

    if (isInvalid) {
      const newFormFields = [...formFields];
      newFormFields.find((field) => field?.id === 'src').disabled = false;
      newFormFields.find((field) => field?.id === 'new_image_src').disabled = false;
      setFormFields(newFormFields);
      setSaveDisabled(false);
      event.target.value = "" // Reset the file upload field
      return;
    }

    if (extension.toLowerCase() === 'png') {
      let newCategory = JSON.parse(JSON.stringify(category))
      newCategory.image = {
        src: "Uploading image please wait...",
      }
      setCategory(newCategory)
      PostImage({ token: token, admin_id: admin_id, location_id: location_id, file: event.target?.files?.[0] })
        .then(response => {
          let newCategory = JSON.parse(JSON.stringify(category))
          if (!newCategory.image) { newCategory.image = {} }
          newCategory.image = {
            name: `${encodeURIComponent(event.target?.files?.[0]?.name)}`,
            size: filesize,
            src: response?.data?.image_url,
            extension: extension
          }
          setCategory(newCategory)
        })
        .catch(error => {
          HandleError({ error: error })
          let newCategory = JSON.parse(JSON.stringify(category))
          newCategory.image = {
            src: null,
          }
          setCategory(newCategory)
        })
        .finally(() => {
          const newFormFields = [...formFields];
          newFormFields.find((field) => field?.id === 'src').disabled = false
          newFormFields.find((field) => field?.id === 'new_image_src').disabled = false
          setFormFields(newFormFields);
          setSaveDisabled(false);
          event.target.value = "" // Reset the file upload field
        })
    }
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = async () => {
    const [invalid] = validateFormObject({ object: category, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    PostCategory({ token: token, admin_id: admin_id, category: category })
      .then(async response => {
        let newCategory = JSON.parse(JSON.stringify(category))
        if (newCategory.hasOwnProperty('kitchenmanager') && Object.keys(newCategory?.kitchenmanager).length > 0) {
          if (scopes.sortcategories) {
            if ((newCategory?.kitchenmanager?.printer && newCategory?.kitchenmanager?.category) ||
              (newCategory?.kitchenmanager?.screens && newCategory?.kitchenmanager?.category) ||
              (newCategory?.kitchenmanager?.printer && newCategory?.kitchenmanager?.category && newCategory?.kitchenmanager?.screens)) {
              newCategory.kitchenmanager.product_category_id = response?.data?.id
              newCategory.kitchenmanager.admin_id = admin_id
              newCategory.kitchenmanager.location_id = location_id
              newCategory.kitchenmanager.name = newCategory?.title
              if (!newCategory?.kitchenmanager?.screens) newCategory.kitchenmanager.screens = [];
              await PostProductCategory({ token: token, data: newCategory?.kitchenmanager })
                .catch(error => {
                  console.error(error)
                });
            }
          }
        }
        navigate("/productcategories");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
      disabled: saveDisabled
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: formFields,
    field: {
      onChange: onChange
    },
    data: category,
    hash_printers: hash_printers,
    hash_sort_categories: hash_sort_categories,
    hash_screens: hash_screens,
    parent: {
      label: 'parent',
      placeholder: 'parent',
      hash: hash_productpage_categories,
      options: productpageCategories,
      selected: {},
      onChange: onParentChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    printer: {
      label: 'printer',
      options: kmPrinters,
      selected: [],
      onChange: onPrinterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    category: {
      label: 'category',
      options: sortCategories,
      selected: [],
      onChange: onCategoryChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    screens: {
      label: 'screens',
      options: screens,
      selected: [],
      onChange: onScreensChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    new_image_src: {
      onChange: onImageChange
    }
  };

  const addCard = {
    size: 12,
    title: navTitle,
    data: <ProductCategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setLoading(true))

    GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
      .then(data => {
        dispatch(setProductCategoriesAll(data));
      })

    if (scopes?.sortcategories) {
      GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setPrintersAll(response))
        })
        .catch(error => { HandleError({ error: error }) });

      GetAllCategories({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setSortCategoriesAll(response))
        })
        .catch(error => { HandleError({ error: error }) });

      GetAllScreens({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setScreensAll(response))
        })
        .catch(error => { HandleError({ error: error }) })
        .finally(() => {
          dispatch(setLoading(false))
        })
    } else {
      dispatch(setLoading(false))
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};
export default CategoryAdd;