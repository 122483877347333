import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button, Table } from 'react-bootstrap';

import { getRole } from '../redux/dataSlices/tokenSlice';

import { getHashPrintersAll, getHashScreensAll, getHashSortCategoriesAll, getScreen } from '../redux/dataSlices/kitchenmanagerSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';
import { ScreenFormFields } from '../portal/kitchenmanager/screenFormFields';
export default function KmScreensModal(props) {
  const object = useSelector(getScreen);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const hash_screens = useSelector(getHashScreensAll);
  const hash_devices = Object.assign({}, hash_printers, hash_screens);
  const role = useSelector(getRole)
  const screen_settings = ScreenFormFields().find(field => field.id === 'settings').fields;
  const order_sequence = screen_settings.find(field => field.id === 'order_sequence').fields;
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  let settings = []
  let next_device_sort_categories = []
  let order_sequences = []

  if (object) {
    if (object.settings) {
      for (const setting of screen_settings) {
        if (setting?.type !== 'object') {
          settings.push(
            <tr className='product-row'>
              <td width="50%">{translate(setting?.name)}</td>
              <td width="50%">{translate(hash_devices[object.settings[setting?.id]]?.name) || translate((object.settings[setting?.id]) ?? translate("Not set"))}</td>
            </tr>
          )
        }
      }
      for (const device_sort_category of object.settings.next_device_sort_groups) {
        next_device_sort_categories.push(
          <tr className='product-row'>
            <td width="50%">{hash_devices[device_sort_category?.device_id]?.name ?? "Not set"}</td>
            <td width="50%">{hash_sort_categories[device_sort_category?.sort_category]?.name ?? "Not set"}</td>
          </tr>
        )
      }
      for (const setting of order_sequence) {
        if (setting?.type !== 'object') {
          order_sequences.push(
            <tr className='product-row'>
              <td width="50%">{translate(setting?.name)}</td>
              <td width="50%">{translate(object.settings.order_sequence[setting?.id]) ?? "Not set"}</td>
            </tr>
          )
        }
      }
    }

    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Screen: {object?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="settings">{translate('Settings')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object?.name}</p>
                      <p>{translate('Type')}: {object?.type}</p>
                      <p>{translate('Active')}: {JSON.stringify(object?.active)}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[object.admin_id]?.name}</p>
                      <p>{translate('Location')}: {hash_locations_all[object.location_id]?.name}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="settings">
                <div className="container">
                  <div className="row">
                    <Table hover width='100%'>
                      <thead>
                        <tr className='product-row'>
                          <th>
                            {translate('Setting')}
                          </th>
                          <th>
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {settings}
                      </tbody>
                      <h4 className='mt-3 mx-2'>{translate('Next device')}</h4>
                      <thead>
                        <tr className='product-row'>
                          <th width='50%'>
                            {translate('Device')}
                          </th>
                          <th width='50%'>
                            {translate('Sort Category')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {next_device_sort_categories}
                      </tbody>
                      <h4 className='mt-3 mx-2'>{translate('Order sequence')}</h4>
                      <thead>
                        <tr className='product-row'>
                          <th width='50%'>
                            {translate('Setting')}
                          </th>
                          <th width='50%'>
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order_sequences}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>id: {object?.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>admin_id: {object?.admin_id}</p>
                    </div>
                    <div className="col">
                      <p>location_id: {object?.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}