import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getDevice, setDevice } from "../../redux/dataSlices/deviceSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import DeviceForm from "../../components/deviceForm";
import SmartPOSFormFields from "./smartposDeviceFormFields";
import VectronFormFields from "./vectronDeviceFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { deviceTypeChoices, eft_payment_provider, priceCheckerScannerTypes, selected_printer, selected_scanner } from "../../selectfieldchoices/deviceselect.mjs";
import PostImage from "../../actablueAPI/cdn/PostImage";
import PutDevicesDevice from "../../actablueAPI/devices/PutDevicesDevice";
import useHandleError from "../../customhooks/useHandleError";
import PricecheckerFormFields from "./pricecheckerDeviceFormFIelds";

const DeviceEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const device = useSelector(getDevice)
  const [formFields, setFormFields] = useState((() => {
    switch (true) {
      case device?.type === "VECTRON":
        return VectronFormFields();
      case device?.type === "PRICECHECKER":
      case device?.settings?.hasOwnProperty("scannerType"):
        return PricecheckerFormFields();
      case device?.type === "SMARTPOS":
      default:
        return SmartPOSFormFields();
    }
  })());
  const [saveDisabled, setSaveDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();

  const onChange = (event, id) => {
    let newDevice = HandleOnChange({ event: event, object: device })
    dispatch(setDevice(newDevice))
  }

  const onTypeChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'type' })
    dispatch(setDevice(newDevice))
  }

  const onLocationIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'location_id' })
    dispatch(setDevice(newDevice))
  }

  const onAdminIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'administration_id' })
    delete newDevice.location_id
    dispatch(setDevice(newDevice))
  }

  const onHandleOnChangeSingleSelectFieldWithValue = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device })
    dispatch(setDevice(newDevice))
  }

  const handleOnChangeSelectField = (event, selectFieldId) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: selectFieldId })
    dispatch(setDevice(newDevice))
  }

  const onImageChange = (event) => {
    let isInvalid = false;
    const imageUrlFieldId = (() => {
      switch (event?.target?.id) {
        case "new_receipt_logo":
          return "receipt_logo_url"
        case "new_display_logo":
          return "display_logo_url"
        default:
          return null
      }
    })();

    if (!imageUrlFieldId) {
      event.target.value = "" // Reset the file upload field
      return;
    }

    setSaveDisabled(true);
    const newFormFields = [...formFields];
    newFormFields.find((field) => field?.id === 'settings').fields.find((field) => field?.id === imageUrlFieldId).disabled = true
    newFormFields.find((field) => field?.id === 'settings').fields.find((field) => field?.id === event?.target?.id).disabled = true
    setFormFields(newFormFields);

    // check extension of file
    const extension = event.target?.files?.[0]?.name?.split('.')[1];
    const filesize = event.target?.files?.[0]?.size;
    const filesizeMb = filesize / 1024;
    const filesizeLimit = 1024 * 8; // 8 MB

    switch (true) {
      case (filesizeMb > filesizeLimit):
        HandleError({
          error: "Image size exceeds the filesize limit (8MB)",
          showPopup: true,
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
        isInvalid = true;
        break;

      case (extension.toLowerCase() !== 'png'):
        HandleError({
          error: "Uploaded file must be .PNG",
          showPopup: true,
          variant: 'warning',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        })
        isInvalid = true;
        break;
      default:
        isInvalid = false;
        break;
    }

    if (isInvalid) {
      const newFormFields = [...formFields];
      newFormFields.find((field) => field?.id === 'settings').fields.find((field) => field?.id === imageUrlFieldId).disabled = false
      newFormFields.find((field) => field?.id === 'settings').fields.find((field) => field?.id === event?.target?.id).disabled = false
      setFormFields(newFormFields);
      setSaveDisabled(false);
      event.target.value = "" // Reset the file upload field
      return;
    }

    if (extension.toLowerCase() === 'png') {
      let newDevice = JSON.parse(JSON.stringify(device))
      newDevice.settings[imageUrlFieldId] = "Uploading image please wait..."
      dispatch(setDevice(newDevice))
      PostImage({ token: token, admin_id: device?.administration_id, location_id: device?.location_id, file: event.target?.files?.[0] })
        .then(response => {
          let newDevice = JSON.parse(JSON.stringify(device))
          newDevice.settings[imageUrlFieldId] = response?.data?.image_url
          dispatch(setDevice(newDevice))
        })
        .catch(error => {
          HandleError({ error: error })
          let newDevice = JSON.parse(JSON.stringify(device))
          newDevice.settings[imageUrlFieldId] = null
          dispatch(setDevice(newDevice))
        })
        .finally(() => {
          const newFormFields = [...formFields];
          newFormFields.find((field) => field?.id === 'settings').fields.find((field) => field?.id === imageUrlFieldId).disabled = false
          newFormFields.find((field) => field?.id === 'settings').fields.find((field) => field?.id === event?.target?.id).disabled = false
          setFormFields(newFormFields);
          setSaveDisabled(false);
          event.target.value = "" // Reset the file upload field
        })
    }
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    let newDevice = JSON.parse(JSON.stringify(device))
    if (newDevice.settings && !newDevice.settings?.device_low_memory_threshold) {
      newDevice.settings.device_low_memory_threshold = ''
    }
    PutDevicesDevice({ token: token, admin_id: device?.administration_id, type: device?.type.toLowerCase(), data: newDevice })
      .then(() => {
        navigate("/devices");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit,
      disabled: saveDisabled
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: formFields,
    field: {
      onChange: onChange
    },
    data: device,
    type: {
      label: 'type',
      options: deviceTypeChoices,
      selected: { value: 'SMARTPOS', label: 'SMARTPOS' },
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === device?.administration_id) { return true } }),
      hash: hash_locations_all,
      // selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      // selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    selected_scanner: {
      label: 'selected_scanner',
      options: selected_scanner,
      // selected: {},
      onChange: onHandleOnChangeSingleSelectFieldWithValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    selected_printer: {
      label: 'selected_printer',
      options: selected_printer,
      // selected: {},
      onChange: onHandleOnChangeSingleSelectFieldWithValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    eft_payment_provider: {
      label: 'eft_payment_provider',
      options: eft_payment_provider,
      // selected: {},
      onChange: onHandleOnChangeSingleSelectFieldWithValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    new_receipt_logo: {
      label: 'new_receipt_logo',
      onChange: onImageChange,
    },
    new_display_logo: {
      label: 'new_display_logo',
      onChange: onImageChange,
    },
    scannerType: {
      label: 'scannerType',
      options: priceCheckerScannerTypes,
      // selected: {},
      onChange: handleOnChangeSelectField,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: "Device edit ".concat(device?.name),
    data: <DeviceForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Device Edit"))

    if (!formData?.data) navigate(-1)

    // eslint-disable-next-line
  }, []);

  if (formData?.data) {
    return (
      <>
        <BasicCard {...editCard} />
      </>
    )
  }
};
export default DeviceEdit;

