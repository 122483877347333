import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import ScheduleForm from "../../components/narrowcastingScheduleForm";
import { NarrowcastingScheduleFormFields } from "./narrowcastingScheduleFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSchedule, setSchedule, getPlaylistsAll, getHashPlaylistsAll, setPlaylistsAll } from "../../redux/dataSlices/narrowcastingSlice";
import PostSchedule from "../../actablueAPI/narrowcasting/PostSchedule";
import GetAllPlaylists from "../../actablueAPI/narrowcasting/GetAllPlaylists";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import { dayChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import useHandleError from "../../customhooks/useHandleError";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from '../../redux/dataSlices/loadingSlice';

const NarrowcastingScheduleEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const [localSchedule, setLocalSchedule] = useState(useSelector(getSchedule))
  const playlists_all = useSelector(getPlaylistsAll)
  const hash_playlists_all = useSelector(getHashPlaylistsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Schedule edit"
  const HandleError = useHandleError();
  const playlistNumber = useRef(null);
  const WeekdaysSorter = {'Monday': 0, 'Tuesday': 1, 'Wednesday': 2, 'Thursday': 3, 'Friday': 4, 'Saturday': 5, 'Sunday': 6}
  const transTimeInvalid = useRef([])
  const [invalidFields, setInvalidFields] = useState([])

  const endTimeConverter = (inputTime) => {
    let newTime;
    let splitTime = inputTime.split(':')
    splitTime[1] = parseInt(splitTime[1]);
    if (splitTime[1] === 0) {
      splitTime[0] = ('0'+(parseInt(splitTime[0]) - 1).toString()).slice(-2)
      splitTime[1] = '59'
      newTime = splitTime.join(':')
    } else {
      splitTime[1] = ('0'+(splitTime[1]-1).toString()).slice(-2)
      newTime = splitTime.join(':')
    }
    if (newTime === '-1:59') {
      newTime = '00:00'
    }
    newTime += ':00'
    return (
      newTime
    )
  }

  const onChange = (event) => {
    if (event.target.id === 'name') {
      let tempInvalid = invalidFields
      if (tempInvalid.find(invalid => invalid.field === 'name')) {
        tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === 'name'), 1)
      }
    }
    let newSchedule = HandleOnChange({ event: event, object: localSchedule })
    if (event.target.id.includes('transition_time')) {
      let transitionTimeIndex = parseInt(event.target.id.slice(-1))
      if (newSchedule[`transition_time${transitionTimeIndex}`] === '') {
        transTimeInvalid.current[transitionTimeIndex] = true
        if (newSchedule[`transition_time${transitionTimeIndex+1}`]) {
          transTimeInvalid.current[transitionTimeIndex+1] = false
        }
      } else {
        transTimeInvalid.current[transitionTimeIndex] = false
      }
      let timeValue = event.target.value
      if (newSchedule[`transition_time${transitionTimeIndex-1}`]) {
        let splitTime = timeValue.split(':')
        let splitPrevTime = newSchedule[`transition_time${transitionTimeIndex-1}`].split(':')
        if (parseInt(splitTime[0]) < parseInt(splitPrevTime[0])) {
          transTimeInvalid.current[transitionTimeIndex] = true
        }
        if (parseInt(splitTime[0]) === parseInt(splitPrevTime[0]) && parseInt(splitTime[1]) <= parseInt(splitPrevTime[1])) {
          transTimeInvalid.current[transitionTimeIndex] = true
        }
      }
      if (newSchedule[`transition_time${transitionTimeIndex+1}`]) {
        let splitTime = timeValue.split(':')
        let splitNextTime = newSchedule[`transition_time${transitionTimeIndex+1}`].split(':')
        if (parseInt(splitTime[0]) > parseInt(splitNextTime[0])) {
          transTimeInvalid.current[transitionTimeIndex+1] = true
        }
        if (parseInt(splitTime[0]) === parseInt(splitNextTime[0]) && parseInt(splitTime[1]) >= parseInt(splitNextTime[1])) {
          transTimeInvalid.current[transitionTimeIndex+1] = true
        } else if (parseInt(splitTime[0]) <= parseInt(splitNextTime[0]) && parseInt(splitTime[1]) <= parseInt(splitNextTime[1])) {
          transTimeInvalid.current[transitionTimeIndex+1] = false
        }
      }
      if (newSchedule.playlists[transitionTimeIndex-1]) {
        if (newSchedule[`transition_time${transitionTimeIndex}`] === '') {
          newSchedule.playlists[transitionTimeIndex-1].end_time = '23:59:00'
        } else {
          newSchedule.playlists[transitionTimeIndex-1].end_time = endTimeConverter(timeValue)
        }
      }
      if (newSchedule.playlists[transitionTimeIndex]) {
        if (newSchedule[`transition_time${transitionTimeIndex}`] === '') {
          newSchedule.playlists[transitionTimeIndex].start_time = ''
        } else {
          newSchedule.playlists[transitionTimeIndex].start_time = timeValue+':00'
        }
      }
    }
    setLocalSchedule(newSchedule)
  }

  const onHandleOnChangeSingleSelectFieldWithObject = (event) => {
    let newSchedule = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: localSchedule, selectfield: `playlist${event.id}`, hash:hash_playlists_all })
    newSchedule.playlists[event.id] = {...newSchedule[`playlist${event.id}`]}
    if (newSchedule[`transition_time${event.id}`]) {
      newSchedule.playlists[event.id].start_time = newSchedule[`transition_time${event.id}`]+':00'
    }
    if (newSchedule[`transition_time${event.id+1}`]) {
      newSchedule.playlists[event.id].end_time = endTimeConverter(newSchedule[`transition_time${event.id+1}`])
    }
    let tempInvalid = invalidFields
    if (tempInvalid.find(invalid => invalid.field === event.id)) {
      tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === event.id), 1)
    }
    setLocalSchedule(newSchedule)
  }

  const onDayChange = (event) => {
    let newSchedule = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: localSchedule, selectfield: 'day' })
    newSchedule.day.sort((a, b) => WeekdaysSorter[a] - WeekdaysSorter[b])
    let tempInvalid = invalidFields
    if (tempInvalid.find(invalid => invalid.field === 'day')) {
      tempInvalid.splice(tempInvalid.findIndex(invalid => invalid.field === 'day'), 1)
    }
    setLocalSchedule(newSchedule)
  }

  const onRemoveAdd = (newPlaylistCount) => {
    if (newPlaylistCount < playlistNumber.current) {
      let newSchedule = localSchedule
      delete newSchedule[`transition_time${newPlaylistCount+1}`]
      transTimeInvalid.current[newPlaylistCount+1] = false
      newSchedule.playlists = [...newSchedule.playlists]
      if (newSchedule.playlists.length > newPlaylistCount+1) {
        newSchedule.playlists.splice(-1)
      }
      if (newSchedule.playlists[newSchedule.playlists.length-1] !== undefined && newSchedule.playlists[newSchedule.playlists.length-1] !== null) {
        newSchedule.playlists[newSchedule.playlists.length-1] = {...newSchedule.playlists[newSchedule.playlists.length-1], end_time: '23:59:00'}
      }
      setLocalSchedule(newSchedule)
    }
    playlistNumber.current = newPlaylistCount
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let [invalid] = validateFormObject({ object: localSchedule, fields: formData?.fields, switchTab: true, dispatch: dispatch })
    if (invalid?.length > 0) {
      setInvalidFields(invalid);
      return;
    }

    if (transTimeInvalid.current.includes(true)) {
      return;
    } else {
      if (playlistNumber.current === null) {
        playlistNumber.current = localSchedule.playlists.length-1;
      }
      let newSchedule = {
        id: localSchedule.id, 
        admin_id: localSchedule.admin_id, 
        location_id: localSchedule.location_id, 
        name: localSchedule.name, 
        description: localSchedule.description, 
        day: localSchedule.day, 
        playlists: localSchedule.playlists
      }
      newSchedule.playlists = newSchedule.playlists.slice(0, playlistNumber.current+1)
      if (newSchedule.playlists.length > 0) {
        [...newSchedule.playlists].forEach((playlist, index) => {
          if(!playlist){
            invalid.push({tab: 'main', field: index, name: `playlist${index}`})
          }
        })
        if(invalid?.length > 0) {
          setInvalidFields(invalid)
          return;
        } else {
          let newPlaylists = newSchedule.playlists
          newPlaylists[0] = {...newPlaylists[0], start_time: '00:00:00'}
          newPlaylists[newSchedule.playlists.length-1] = {...newPlaylists[newSchedule.playlists.length-1], end_time: '23:59:00'}
          newSchedule.playlists = newPlaylists
        }
      }

      dispatch(setSchedule(newSchedule))
      dispatch(setLoading(true))

      PostSchedule({ token: token, admin_id: admin_id, schedule: newSchedule })
        .then(() => {
          navigate("/narrowcastingschedules");
        })
        .catch(error => {
          HandleError({ error: error })
        })
    }
  };

  const formData = {
    invalidFields: invalidFields,
    transTimeInvalid: transTimeInvalid.current,
    onRemoveAdd: onRemoveAdd,
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingScheduleFormFields(),
    field: {
      onChange: onChange
    },
    data: localSchedule,
    playlists: {
      label: 'playlists',
      options: playlists_all,
      onChange: onHandleOnChangeSingleSelectFieldWithObject,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    day: {
      label: 'day',
      options: dayChoices,
      onChange: onDayChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <ScheduleForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    GetAllPlaylists({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setPlaylistsAll(response))
      })
      .catch(error => {
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localSchedule.playlists) {
      if (localSchedule.playlists.length > 0) {
        playlistNumber.current = localSchedule.playlists.length-1
      }
      let newSchedule = {...localSchedule}
      newSchedule.playlists.forEach((playlist, index) => {
        if (index > 0) {
          if (!newSchedule[`transition_time${index}`]) {
            newSchedule[`transition_time${index}`] = playlist.start_time
          }
        }
      })
      setLocalSchedule(newSchedule)
    }
    // eslint-disable-next-line
  },[])

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingScheduleEdit;

