
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getRole } from '../../redux/dataSlices/tokenSlice';
import { useSelector } from "react-redux";


const JournalCategoryValidator = (object, field) => {
  if (object?.categories && object?.categories?.find(category => category?.type === 'JOURNAL')) return true
  return false
}

const ProductCategoryValidator = (object, field) => {
  if (object?.categories && object?.categories?.find(category => category?.type === 'PRODUCTPAGE')) return true
  return false
}

const KitchenManagerScreensValidator = (object, field) => {
  if (object?.kitchenmanager?.screens) return true
  if (!object?.kitchenmanager?.category && !object?.kitchenmanager?.screens && !object.kitchenmanager?.printer) return true
  if (object?.kitchenmanager?.category && !object?.kitchenmanager?.screens && object.kitchenmanager?.printer) return true
  if (object?.kitchenmanager?.category && object?.kitchenmanager?.screens) return true
  if (object?.kitchenmanager?.screens?.length === 0 || object?.kitchenmanager?.screens?.length > 0) return true
  return false
}

const KitchenManagerCategoryValidator = (object, field) => {
  if (object?.kitchenmanager?.category) return true
  if (!object?.kitchenmanager?.category && !object?.kitchenmanager?.printer && (!object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length === 0)) return true
  if (object?.kitchenmanager?.category && (object?.kitchenmanager?.printer || (object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length > 0))) return true
  return false
}

const KitchenManagerPrinterValidator = (object, field) => {
  if (object?.kitchenmanager?.printer) return true
  if (!object?.kitchenmanager?.category && !object?.kitchenmanager?.printer && (!object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length === 0)) return true
  if (object?.kitchenmanager?.category && !object?.kitchenmanager?.printer && (object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length > 0)) return true
  if (object?.kitchenmanager?.category && object?.kitchenmanager?.printer) return true
  return false
}

const PriceValidator = (object, field) => {
  if (object?.price_incl_vat >= 0) return true
  return false
}

const ProductSpecificationsPackagingValidator = (object, field) => {
  if (!object?.product_specifications?.packaging) return true
  if (object?.product_specifications?.packaging >= 0) return true
  return false
}

export const ProductFormFields = () => { 
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)

  const product_specifications = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'product_specifications' },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true, prefix: 'product_specifications' },
    { type: 'text', name: `ccv_id`, id: 'ccv_id', prefix: 'product_specifications', required: false },
    { type: 'hidden', name: `product`, id: 'product', prefix: 'product_specifications', required: false },
    { type: 'text', name: `brand`, id: 'brand', prefix: 'product_specifications', required: false },
    { type: 'text', name: `model`, id: 'model', prefix: 'product_specifications', required: false },
    { type: 'number', name: `packaging`, id: 'packaging', prefix: 'product_specifications', required: true, validator: ProductSpecificationsPackagingValidator },
    { type: 'text', name: `contents`, id: 'contents', prefix: 'product_specifications', required: false },
    { type: 'text', name: `weigth`, id: 'weigth', prefix: 'product_specifications', required: false },
    { type: 'text', name: `heigth`, id: 'heigth', prefix: 'product_specifications', required: false },
    { type: 'text', name: `length`, id: 'length', prefix: 'product_specifications', required: false },
    { type: 'text', name: `width`, id: 'width', prefix: 'product_specifications', required: false },
    { type: 'text', name: `batch_number`, id: 'batch_number', prefix: 'product_specifications', required: false },
    { type: 'text', name: `expires_on`, id: 'expires_on', prefix: 'product_specifications', required: false },
  ]

  const vectron = [
    // single option fields
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true, prefix: 'vectron' },
    { type: 'hidden', name: 'subscription_id', id: 'subscription_id', readonly: true, prefix: 'vectron' },
    { type: 'text', name: `external_id`, id: 'external_id', prefix: 'vectron', required: false },
    { type: 'hidden', name: `price_excl_vat`, id: 'price_excl_vat', prefix: 'vectron', required: false, step: '.01' },
    { type: 'number', name: `price_incl_vat`, id: 'price_incl_vat', prefix: 'vectron', required: false, step: '.01' }
  ]

  const ccv = [
    // single option fields
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true, prefix: 'ccv' },
    { type: 'hidden', name: 'subscription_id', id: 'subscription_id', readonly: true, prefix: 'ccv' },
    { type: 'hidden', name: 'optionvalue_id', id: 'optionvalue_id', readonly: true, prefix: 'ccv' },
    { type: 'text', name: `ccv_id`, id: 'ccv_id', prefix: 'ccv', required: false },
    { type: 'text', name: `product_nummer`, id: 'product_nummer', prefix: 'ccv', required: false },
    { type: 'hidden', name: `price_excl_vat`, id: 'price_excl_vat', prefix: 'ccv', required: false, step: '.01' },
    { type: 'number', name: `price_incl_vat`, id: 'price_incl_vat', prefix: 'ccv', required: false, step: '.01' },
    { type: 'checkbox', name: `visible`, id: 'visible', required: false },
  ]

  const lightspeed = [
    // single option fields
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true, prefix: 'lightspeed' },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true, prefix: 'lightspeed' },
    { type: 'hidden', name: 'subscription_id', id: 'subscription_id', readonly: true, prefix: 'lightspeed' },
    { type: 'text', name: `lightspeed_id`, id: 'lightspeed_id', prefix: 'lightspeed', required: false },
    { type: 'hidden', name: `price_excl_vat`, id: 'price_excl_vat', prefix: 'lightspeed', required: false, step: '.01' },
    { type: 'number', name: `price_incl_vat`, id: 'price_incl_vat', prefix: 'lightspeed', required: false, step: '.01' },
    { type: 'checkbox', name: 'active', id: 'active', prefix: 'lightspeed', required: false },
  ]

  const esl = [
    { type: 'hidden', name: 'product_id', id: 'product_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'product_name', id: 'product_name', readonly: true },
    { type: 'text', name: 'esl_barcode', id: 'esl_barcode', prefix: 'esl', required: false },
    { type: 'select', name: 'print_template_id', id: 'print_template_id', prefix: 'esl', formdatafield: 'print_template_id', required: false },
    { type: 'select', name: 'esl_template_id', id: 'esl_template_id', prefix: 'esl', formdatafield: 'esl_template_id', required: false },
    { type: 'select', name: 'code_type', id: 'code_type', prefix: 'esl', formdatafield: 'code_type', required: false },
  ]

  const sorted_product = [
    { type: 'hidden', name: 'product_id', id: 'product_id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: `Short name`, id: 'short_name', required: false },
    { type: 'select', name: 'category', id: 'category', prefix: 'kitchenmanager', formdatafield: 'category', required: true, validator: KitchenManagerCategoryValidator },
    { type: 'select', name: 'printer', id: 'printer', prefix: 'kitchenmanager', formdatafield: 'printer', required: true, validator: KitchenManagerPrinterValidator },
    { type: 'multiselect', name: 'Screens', id: 'screens', prefix: 'kitchenmanager', formdatafield: 'screens', required: true, validator: KitchenManagerScreensValidator },
    { type: 'hidden', name: 'has_multiple_groups', id: 'has_multiple_groups', readonly: true },
  ]

  const productFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: `name`, id: 'name', required: true },
    { type: 'text', name: `description`, id: 'description', required: false },
    { type: 'select', name: `type`, id: 'type', formdatafield: 'type', required: true },
    { type: 'text', name: `sku_code`, id: 'sku_code', required: true },
    { type: 'text', name: `plu_code`, id: 'plu_code', required: false },
    { type: 'hidden', name: `price_excl_vat`, id: 'price_excl_vat', required: false, step: '.01', validator: PriceValidator },
    { type: 'number', name: `price_incl_vat`, id: 'price_incl_vat', required: true, step: '.01', validator: PriceValidator },
    { type: 'number', name: `discount`, id: 'discount', required: false, step: '.01' },
    { type: 'number', name: `emballage`, id: 'emballage', required: false, step: '.01' },
    { type: 'hidden', name: `marge`, id: 'marge', required: false },
    { type: 'hidden', name: `ledger`, id: 'ledger', required: false },
    { type: 'text', name: `Image`, id: 'thumbnail', required: false },
    { type: 'file', name: `New Image`, id: 'new_thumbnail', accept: 'image/png', info: "Only PNG supported\n(8MB Max)" },
    { type: 'checkbox', name: 'active', id: 'active' },
    { type: 'checkbox', name: `ask_sn`, id: 'ask_sn' },
    { type: 'checkbox', name: `is_weighted`, id: 'is_weighted' },
    { type: 'checkbox', name: `input_required`, id: 'input_required' },

    // single option select fields  
    { type: 'select', name: `journalcategory`, id: 'journalcategory', formdatafield: 'journalcategories', required: true, validator: JournalCategoryValidator },
    { type: 'select', name: `vat`, id: 'vat', formdatafield: 'vats', required: true },

    // multi option select fields
    { type: 'multiselect', name: `productcategory`, id: 'productcategory', formdatafield: 'productcategories', required: true, validator: ProductCategoryValidator },
    // { type: 'multiselect', name: `attributes`, id: 'attributes', formdatafield: 'attributes', required: false },

    // multi option select fields + self create
    { type: 'multi+create', name: `eans`, id: 'eans', formdatafield: 'eans', required: false },
    { type: 'multi+create', name: `images`, id: 'images', formdatafield: 'images', required: false },

    // single option object fields
    { type: 'object', name: `product_specifications`, id: 'product_specifications', fields: product_specifications },

  ]

  if (scopes.schapkaartjes) {
    productFormFields.push({ type: 'object', name: `esl`, id: 'esl', fields: esl })
  }

  if (scopes.vectron) {
    productFormFields.push({ type: 'object', name: `vectron`, id: 'vectron', fields: vectron })
  }

  if (scopes.ccvshop) {
    productFormFields.push({ type: 'object', name: `ccv`, id: 'ccv', fields: ccv })
  }

  if (scopes.lightspeed) {
    productFormFields.push({ type: 'object', name: `lightspeed`, id: 'lightspeed', fields: lightspeed })
  }

  if (scopes.sortcategories) {
    productFormFields.push({ type: 'object', name: `kitchenmanager`, id: 'kitchenmanager', fields: sorted_product })
  }

  if (role === 'super' || role === 'admin') {
    productFormFields.push({ type: 'multiselect', name: `attributes`, id: 'attributes', formdatafield: 'attributes', required: false })
  }

  return productFormFields
}

export const ProductSupplierFormFields = () => {
  const product_specifications = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'product_specifications' },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true, prefix: 'product_specifications' },
    { type: 'text', name: `ccv_id`, id: 'ccv_id', prefix: 'product_specifications', required: false },
    { type: 'text', name: `brand`, id: 'brand', prefix: 'product_specifications', required: false },
    { type: 'text', name: `model`, id: 'model', prefix: 'product_specifications', required: false },
    { type: 'text', name: `packaging`, id: 'packaging', prefix: 'product_specifications', required: false },
    { type: 'text', name: `contents`, id: 'contents', prefix: 'product_specifications', required: false },
    { type: 'text', name: `weigth`, id: 'weigth', prefix: 'product_specifications', required: false },
    { type: 'text', name: `heigth`, id: 'heigth', prefix: 'product_specifications', required: false },
    { type: 'text', name: `length`, id: 'length', prefix: 'product_specifications', required: false },
    { type: 'text', name: `width`, id: 'width', prefix: 'product_specifications', required: false },
    { type: 'text', name: `batch_number`, id: 'batch_number', prefix: 'product_specifications', required: false },
    { type: 'text', name: `expires_on`, id: 'expires_on', prefix: 'product_specifications', required: false },
  ]

  const productSupplierFromFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'product_id', id: 'product_id', readonly: true },
    // { type: 'text', name: 'relation_id', id: 'relation_id', required: true, md: 6 },
    { type: 'select', name: 'Supplier', id: 'relation_id', formdatafield: 'relation_id', required: true, md: 12 },
    // { type: 'multi+create', name: `eans`, id: 'eans', formdatafield: 'eans', required: false, md: 6 },
    { type: 'text', name: 'Supplier article code', id: 'supplier_artikel_code', required: true, md: 6 },
    { type: 'number', name: 'Supplier price', id: 'supplier_price', required: true, step: '.01', md: 6 },

    // single option object fields
    { type: 'object', name: `Supplier Specifications`, id: 'product_supplier_specifications', fields: product_specifications },

  ]

  return productSupplierFromFields
}

export const DownloadShelflabelsFormFields = () => {
  const fields = [
    { type: 'select', name: 'template_id', id: 'template_id', formdatafield: 'template_id', md: 12 },
  ]

  return fields
}

export const ProductMultiEditFormFields = () => {
  const fields = [
    { type: 'select', name: `journalcategory`, id: 'journalcategory', formdatafield: 'journalcategories', md: 6 },
    { type: 'select', name: `vat`, id: 'vat', formdatafield: 'vats', md: 6 },
    { type: 'select', name: `active`, id: 'active', formdatafield: 'active', md: 4 },
    { type: 'select', name: `ask_sn`, id: 'ask_sn', formdatafield: 'ask_sn', md: 4 },
    { type: 'select', name: 'is_weighted', id: 'is_weighted', formdatafield: 'is_weighted', md: 4 },
  ]

  return fields
}