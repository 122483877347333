import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import TableButton from './tableButton';
import NarrowcastingPlaylistsButtons from '../portal/narrowcasting/narrowcastingPlaylistsButtons';
import NarrowcastingPlaylistsModal from './narrowcastingPlaylistsModal';

import './css/productTable.css';
import { layoutChoices } from '../selectfieldchoices/narrowcastingselect.mjs';
import PaginationAndSizeComponent from './paginationAndSize';

export default function NarrowcastingPlaylistsTable(props) {
  const playlists = props?.playlists?.items || []
  const pages = props?.playlists?.pages || 0
  const page = props?.playlists?.page || 0

  const buttons = NarrowcastingPlaylistsButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []
  if (playlists) {
    for (const playlist of Object.values(playlists)) {
      rows.push(
        <tr className='product-row' key={playlist.id}>
          <td>{playlist.name}</td>
          <td>{playlist.description}</td>
          <td>{translate(layoutChoices.find(element => element.id === playlist.layout)?.name)}</td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(playlist) }}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(playlist) }}>{translate(edit.tag)}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(playlist) }} id={playlist.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Description')}</th>
            <th>{translate('Layout')}</th>
            <th width='25%'>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <NarrowcastingPlaylistsModal modal={modalview.modal} />
    </>
  );
}