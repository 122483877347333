import GetAllStockPaginated from "../actablueAPI/warehouse/GetAllStockPaginated";
import HandleError from "./handleError";
import * as XLSX from 'xlsx/xlsx.mjs';
import { setTaskData } from "../redux/dataSlices/analyseSlice";
import { setProgressModalShow } from "../redux/dataSlices/analyseSlice";
import GetProductById from "../actablueAPI/products/GetProductByID";

export const StockExport = ({ token, admin_id, location_id, hashSupplierRelations, dispatch }) => {
  let current = 1
  let task_data = {
    current: current,
    total: 4,
    progress: current === 0 ? 0 : current / 3
  }

  dispatch(setTaskData(task_data))
  dispatch(setProgressModalShow(true))

  GetAllStockPaginated({ token: token, admin_id: admin_id, location_id: location_id })
    .then(async response => {
      current = current + 1
      task_data = {
        current: current,
        total: 4,
        progress: current === 0 ? 0 : current / response.total_elements
      }
      dispatch(setTaskData(task_data))

      const { exportLines, dummy } = await _createExportLines(response, dispatch, token, admin_id, hashSupplierRelations)

      current = current + 1
      task_data = {
        current: current,
        total: 4,
        progress: current === 0 ? 0 : current / response.total_elements
      }
      dispatch(setTaskData(task_data))

      // first object
      let firstObject = exportLines[0]
      let newFirstObject = {}
      for (const key of Object.keys(dummy)) {
        newFirstObject[key] = firstObject[key]
      }

      exportLines[0] = Object.assign({}, newFirstObject)

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(exportLines), 'sheet1')
      XLSX.writeFile(workbook, "export.xlsx", { compression: true });

      current = current + 1
      task_data = {
        current: current,
        total: 4,
        progress: current === 0 ? 0 : current / response.total_elements
      }
      dispatch(setTaskData(task_data))

    })
    .catch(error => {
      HandleError({ error: error })
    })
    .finally(() => {
      dispatch(setProgressModalShow(false))
    })
};

async function _createExportLines(stock, dispatch, token, admin_id, hashSupplierRelations) {
  let current = 1
  let dummy = {}
  let exportLines = []

  for (const stockProduct of stock) {
    let task_data = {
      current: current,
      total: stock.length,
      progress: current === 0 ? 0 : current / stock.length
    }
    dispatch(setTaskData(task_data))

    let newLine = {}
    newLine.id = stockProduct.product_id
    newLine.sku = stockProduct.product.sku_code
    newLine.name = stockProduct.product.name
    newLine.stock_total = stockProduct.quantity
    newLine.price_excl_vat = stockProduct.product.price_excl_vat
    newLine.price_incl_vat = stockProduct.product.price_incl_vat
    newLine.total_excl_vat = Number(stockProduct.quantity) * Number(stockProduct.product.price_excl_vat)
    newLine.total_incl_vat = Number(stockProduct.quantity) * Number(stockProduct.product.price_incl_vat)

    // add stocklocations
    let locationCounter = 1
    if (stockProduct.product.product_locations) {
      for (const stockLocation of stockProduct.product.product_locations) {
        _addStockLocation(newLine, stockLocation, locationCounter)
        locationCounter = locationCounter + 1
      }
    }

    // add all productpage
    let categories = []
    let vat;
    await GetProductById({ token: token, admin_id: admin_id, id: stockProduct.product_id })
      .then(response => {
        vat = response?.data?.vat
        categories = response?.data?.categories
      })

    let productpageCounter = 1
    if (categories) {
      for (const category of categories) {
        if (category.type === 'JOURNAL') {
          _addStockJournal(newLine, category)
        }
        if (category.type === 'PRODUCTPAGE') {
          _addStockProductpage(newLine, category, productpageCounter)
          productpageCounter = productpageCounter + 1
        }
      }
    }

    if (stockProduct?.product?.suppliers && stockProduct?.product?.suppliers.length > 0) {
      stockProduct?.product?.suppliers?.forEach((supplier) => {
        _addSupplier(newLine, stockProduct, supplier, hashSupplierRelations);
      })
    }
    
    if (vat) {
      _addVats(newLine, vat)
    }

    // create longest dummy
    if (Object.keys(newLine).length > Object.keys(dummy).length) {
      dummy = Object.assign({}, newLine)
    }
    exportLines.push(newLine)

    categories = []

    current = current + 1
  }

  return ({ exportLines: exportLines, dummy: dummy })
}

function _addStockLocation(baseLine, stockLocation, locationCounter) {
  baseLine[`WL ${locationCounter}`] = stockLocation.warehouse_name
  baseLine[`RL ${locationCounter}`] = stockLocation.rack_name
  baseLine[`Stock ${locationCounter}`] = stockLocation.quantity
}

function _addStockJournal(baseLine, category) {
  baseLine[`Journal`] = category.title
}

function _addStockProductpage(baseLine, category, productpageCounter) {
  baseLine[`Productpage ${productpageCounter}`] = category.title
}

function _addSupplier(baseLine, stockProduct, supplier, hashSupplierRelations) {
  baseLine[`${hashSupplierRelations?.[supplier?.relation_id]?.name} Price`] = supplier?.supplier_price
  baseLine[`${hashSupplierRelations?.[supplier?.relation_id]?.name} Total`] = (supplier?.supplier_price * stockProduct?.quantity)
}

function _addVats(baseLine, vat) {
  baseLine[`VAT`] = vat.value
}